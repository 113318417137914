import { serialize, LOG_ERROR } from '~/utilities'
import dayjs from 'dayjs'

export default {
  clearUserData() {
    this.card = null
    this.transactionHistory = null
    this.lastOperations = null
    this.filter = null
    this.historyExtIds = []
    this.partners = {}
  },
  setCard(data) {
    this.card = data
  },
  setTransactionHistory(data) {
    if (data && data.operations && data.operations.length) {
      this.transactionHistory = data.operations
    } else this.transactionHistory = []

    if (data && data.hasOwnProperty('total')) {
      this.filter.total = data.total
    } else {
      this.filter.total = 0
    }

    if (data && data.partner_ext_ids && Array.isArray(data.partner_ext_ids))
      this.historyExtIds = data.partner_ext_ids
    else this.historyExtIds = []
  },

  setPartnersByExtId(data) {
    for (const p of data) {
      this.partners[p.attributes.ext_id] = p.attributes
    }
  },

  async getPartnersDetails(ids = []) {
    try {
      if (!ids || !ids.length) return

      // оставить только те id которых нет
      ids = ids.filter(id => !(id in this.partners))
      if (!ids || !ids.length) return

      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
          this.$nuxt.$config.CUSTOMER_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CUSTOMER_BASE_URL
      }
      const start = Date.now()
      const resp = await this.$nuxt.$axios.post(`/helpers/partners`, { ids })
      if (resp && resp.data && resp.data.data) {
        this.setPartnersByExtId(resp.data.data)
      }
    } catch (e) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'GET_PARTNERS_DETAILS', e, {
        ids,
      })
    }
  },
  async getMemberTransactionHistory(data) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_LOYALTY_API_URL ||
          this.$nuxt.$config.LOYALTY_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.LOYALTY_API_URL
      }
      // this.$nuxt.$axios.setHeader('X-User-Agent', "web");
      // this.$nuxt.$axios.setHeader('X-App-Version', "1.0");

      const searchStr = data ? '?' + serialize(data) : ''
      const resp = await this.$nuxt.$axios.get(
        `accounts/transactions${searchStr}`,
      )

      if (resp && resp.data && resp.data.operations) {
        if (resp.data.partner_ext_ids && resp.data.partner_ext_ids.length) {
          await this.getPartnersDetails(resp.data.partner_ext_ids)
        }
        this.setTransactionHistory(resp.data)
        return resp.data.operations.filter(
          item => item.op_status_id === 3 || item.op_status_id === 6,
        )
      }
      return []
    } catch (e) {
      LOG_ERROR(
        this?.$rollbar,
        this?.$config,
        'GET_MEMBER_TRANSACTION_HISTORY',
        e,
        { data },
      )
    }
  },
  async getCard() {
    this.$nuxt.$axios.defaults.baseURL = process.server
      ? this.$nuxt.$config.NODE_LOYALTY_API_URL ||
        this.$nuxt.$config.LOYALTY_API_URL
      : this.$nuxt.$config.LOYALTY_API_URL
    try {
      const response = await this.$nuxt.$axios.get('accounts/details')
      this.setCard(response.data)
      return response
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'getCart', error)
      return error
    }
  },

  setLastOperations(data) {
    if (data && Array.isArray(data)) {
      this.lastOperations = data.slice(0, 6)
    } else this.lastOperations = []
  },

  async getLastOperations() {
    try {
      const n = dayjs()
      const to = n.format('YYYY-MM-DD')
      const from = n.subtract(90, 'days').format('YYYY-MM-DD')

      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_LOYALTY_API_URL ||
          this.$nuxt.$config.LOYALTY_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.LOYALTY_API_URL
      }
      const resp = await this.$nuxt.$axios.get(
        `accounts/transactions?from=${from}&to=${to}&limit=36`,
      )

      if (
        resp &&
        resp.data &&
        resp.data.operations &&
        resp.data.operations.length
      ) {
        let filteredData = resp.data.operations
        if (filteredData.length > 6) {
          filteredData = filteredData.slice(0, 6)
        }

        const extIds = filteredData.map(o => o.partner_ext_id)

        await this.getPartnersDetails(extIds)
        await this.setLastOperations(filteredData)

        return resp.data.operations
      }
      return []
    } catch (e) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'GET_LAST_OPERATIONS', e)
    }
  },

  setFilter(filter) {
    this.filter = {
      ...this.filter,
      ...filter,
    }
  },
}
