
export default {
  name: 'MPModal',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 910,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    scrollPos: 0,
  }),
  watch: {
    visible(value) {
      if (!process.client) return
      this.show(value)
    },
  },
  mounted() {
    document.addEventListener('keydown', e => {
      if (this.visible && e.keyCode === 27) {
        this.close()
      }
    })
    document.addEventListener('scroll', () => {
      if (!this.visible) this.scrollPos = window.scrollY
    })
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
      this.$emit('closed')
    },
    handle(e) {
      e.stopPropagation()
    },
    show(visible) {
      if (visible) {
        document.body.classList.add('overflow-hidden')
        document.body.classList.add('MPModal-Open')
        document.body.addEventListener('touchmove', this.handle)
      } else {
        document.body.classList.remove('overflow-hidden')
        document.body.classList.remove('MPModal-Open')
        document.body.removeEventListener('touchmove', this.handle)
        window.scrollTo(0, this.scrollPos)
      }
    },
  },
}
