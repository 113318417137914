import { useGrosseryStore } from '@/stores/grossery'
import { useUiStore } from '@/stores/ui'
import { useCheckoutCredit } from '@/stores/checkout_credit'
import {
  LOG_ERROR,
  rtbAnalytics,
  serialize,
  // setDataLayerPurchaseLocal,
  setOptionsOrderPackage,
} from '@/utilities'
import { useCustomer } from '@/stores/customer'
const orderPackageOriginal = {
  manualAddress: false,
  submitted: false,
  payment_method: null,
  token_id: null,
  shipping: {
    geocodedAddress: {},
    method: null,
    point_id: null,
    pudo_point_id: null,
    // Manual address
    // city: null,
    // street: null,
    // apartment: null,
    // Manual address
    latitude: null,
    longitude: null,
    address: {
      city: null,
      city_id: 1,
      street: null,
      building: null,
      apartment: null,
      note: null,
      precision: '',
      fromMap: false,
      approvedAddress: false,
      isDragged: false,
      house: null,
    },
    recipient: {
      name: null,
      surname: null,
      phone_number: null,
      email: null,
      note: null,
      fin_code: null,
    },
  },
  shipping_cost: null,
  total_amount: null,
  note: null,
  employee_code: null,
  clarification_call_needed: true,
  orders: [],
  promocode: null,
  premium_promocode: null,
  service_fees: [],
  state: null,
}

export default {
  async resetOrderPackage() {
    this.orderPackage = JSON.parse(JSON.stringify(orderPackageOriginal))
  },
  async CREATE_ORDER_PACKAGE({ mode = 'DEFAULT' }) {
    const GrosseryStore = useGrosseryStore()
    const customerStore = useCustomer()
    const uiStore = useUiStore()
    let response_message = ''
    try {
      this.order_packages = null
      this.orderRecipientForUpdate = null
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      const user_cart_id =
        this.$nuxt.$auth.$storage.getUniversal('user_cart_id')
      this.userCartId = user_cart_id
      const cart_id = this.$nuxt.$auth.$storage.getUniversal('cart_id')
      this.cartId = cart_id
      const isExistingUser =
        this.$nuxt.$auth.$storage.getCookie('isExistingUser')
      GrosseryStore.getTypeGrosseryCartMode()
      const urlPackages = customerStore.isLoggedIn
        ? `/orders-packages`
        : '/anonymous/orders-packages'
      if (user_cart_id || cart_id) {
        if (isExistingUser && user_cart_id) {
          const options = {
            cart_id: cart_id || user_cart_id,
            checkout_mode: mode,
            is_test: false,
            checkout_order_mode: GrosseryStore.typeGrosseryCartMode,
          }
          if (uiStore.fromBB) {
            options.origin = 'BIRBANK'
          }
          if (mode === 'CART_LOAN') {
            // options['loan_product'] = 'KB_LOAN_V2'
            options.loan_process_version = 7
          }

          const { data } = await this.$nuxt.$axios
            .post(urlPackages, options)
            .catch(error => {
              if (error?.response?.status === 409) {
                this.warning_modal_loan_application_created = true
                if (typeof dataLayer !== 'undefined') {
                  dataLayer.push({
                    event: 'scr_you_have_application',
                  })
                }
                response_message = 'warning_modal_loan_application_created'
              } else {
                response_message = 'Error'
              }
            })
          this.order_packages = data?.data

          this.order_packages.orders = data?.data?.orders?.map(order => ({
            ...order,
            max_installment_months: order?.max_installment_months || null,
            installment_months: null,
            shipping: {
              date: null,
              time_frame: null,
            },
            status: 'CREATED',
          }))
          if (mode === 'CART_LOAN') {
            const loan_application = JSON.parse(
              JSON.stringify(this.order_packages?.loan_application),
            )
            const CheckoutCredit = useCheckoutCredit()
            if (CheckoutCredit.checkFirstCredit) {
              loan_application.fin_code = this.order_packages?.loan_application
                ?.fin_code
                ? this.order_packages?.loan_application?.fin_code
                : ''
            }
            loan_application.valid = false
            loan_application.loan_fee = null
            loan_application.credit_bureau_consent = true
            loan_application.imei_code = false
            loan_application.phone_number =
              loan_application.phone_number &&
              loan_application.phone_number.charAt(0) !== '+'
                ? `+${loan_application.phone_number}`
                : loan_application.phone_number
            CheckoutCredit.set_loan_application = {
              ...CheckoutCredit.set_loan_application,
              ...loan_application,
            }
          }
          this.orderPackage.subtotal_amount = data?.data?.subtotal_amount
          this.orderPackage.total_amount = data?.data?.total_amount

          this.orderPackage.orders = data?.data?.orders?.map(order => ({
            ...order,
            shipping: {
              date: null,
              time_frame: null,
            },
            status: 'CREATED',
          }))

          this.$nuxt.$auth.$storage.setUniversal(
            'order_package_id',
            data?.data?.id,
          )
          if (cart_id) {
            response_message = 'set_warning'
            this.$nuxt.$auth.$storage.setUniversal('cart_id', null)
          }
        } else {
          const options = {
            cart_id,
            checkout_mode: mode,
            checkout_order_mode: GrosseryStore.typeGrosseryCartMode,
          }
          if (uiStore.fromBB) {
            options.origin = 'BIRBANK'
          }
          if (mode === 'CART_LOAN') {
            options.loan_process_version = 7
          }
          options.is_test = false
          const { data } = await this.$nuxt.$axios
            .post(urlPackages, options)
            .catch(error => {
              if (error?.response?.status === 409) {
                this.warning_modal_loan_application_created = true
                response_message = 'warning_modal_loan_application_created'
              } else {
                response_message = 'Error'
              }
            })
          this.$nuxt.$auth.$storage.setUniversal(
            'order_package_id',
            data?.data?.id,
          )
          this.orderPackage.shipping_cost = data?.data?.shipping_cost
          this.orderPackage.total_amount = data?.data?.total_amount
          this.order_packages = data?.data
          this.order_packages.orders = data?.data?.orders?.map(order => ({
            ...order,
            shipping: {
              date: null,
              time_frame: null,
            },
            status: 'CREATED',
          }))

          this.orderPackage.orders = data?.data?.orders?.map(order => ({
            ...order,
            shipping: {
              date: null,
              time_frame: null,
            },
            status: 'CREATED',
          }))
        }
        if (mode === 'CART_LOAN') {
          this.orderPackage.payment_method = 'LOAN_ON_DELIVERY'
        }
        return response_message
      } else {
        response_message = 'Cart id is not defined'
      }
      return response_message
    } catch (e) {
      console.log('Error CREATE_ORDER_PACKAGE___2', e)
      return response_message
    }
  },
  async GET_ORDER_FILL_PREFERENCES() {
    try {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      const res = await this.$nuxt.$axios
        .get(`/order-fill-preferences`)
        .catch(e => {
          console.log(e)
        })
      this.order_fill_preferences = res?.data?.data
      if (
        this.order_fill_preferences?.address?.latitude &&
        this.order_fill_preferences?.address?.longitude
      ) {
        this.ignore_error_address_fill_preferences = true
      }
    } catch (e) {
      console.log('Error GET_ORDER_FILL_PREFERENCES', e)
    }
  },
  async GET_PAYMENTS_METHODS() {
    const customerStore = useCustomer()
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      const url = customerStore.isLoggedIn
        ? `/order-packages/${this.orderPackageId}/payment/methods`
        : `/anonymous/order-packages/${this.orderPackageId}/payment/methods`
      const res = await this.$nuxt.$axios.get(url)
      const icons = {
        CASH_ON_DELIVERY: 'i-purse',
        CARD_ON_DELIVERY: 'i-courier',
        CARD_ECOM_KB: 'i-card',
        BB_CARD_ECOM_KB: 'i-birbank !text-[#FF0039]',
      }

      this.payment_methods = res?.data?.data
        .map(item => {
          return {
            ...item,
            icon: icons?.[item?.code] || '',
          }
        })
        .filter(method => {
          return (
            !method?.is_hidden &&
            [
              'CASH_ON_DELIVERY',
              'CARD_ON_DELIVERY',
              'CARD_ECOM_KB',
              'BB_CARD_ECOM_KB',
            ].includes(method?.code)
          )
        })
      const code_payment_method = this.payment_methods.map(
        method => method.code,
      )
      if (
        this.order_fill_preferences?.payment_method &&
        code_payment_method.includes(
          this.order_fill_preferences?.payment_method,
        )
      ) {
        this.orderPackage.payment_method =
          this.order_fill_preferences?.payment_method
      } else {
        const isDefault = this.payment_methods.find(item => item?.is_default)
        if (isDefault) {
          this.orderPackage.payment_method = isDefault?.code
        } else {
          this.orderPackage.payment_method = this.payment_methods?.[0]?.code
        }
      }
    } catch (e) {
      console.log('GET_PAYMENTS_METHODS', e)
    }
  },
  async GET_DELIVERY_METHODS(coord = null) {
    const customerStore = useCustomer()
    let url = customerStore.isLoggedIn
      ? `/order-packages/${this.orderPackageId}/delivery/methods`
      : `/anonymous/order-packages/${this.orderPackageId}/delivery/methods`
    const options = new URLSearchParams()
    if (coord) {
      options.append('latitude', coord.latitude)
      options.append('longitude', coord.longitude)
    } else {
      if (this.orderPackage.shipping.latitude) {
        options.append('latitude', this.orderPackage.shipping.latitude)
      }
      if (this.orderPackage.shipping.longitude) {
        options.append('longitude', this.orderPackage.shipping.longitude)
      }
    }

    if (this.orderPackage.payment_method) {
      options.append('payment_method', this.orderPackage.payment_method)
    }
    url += `${options.toString() ? '?' + options.toString() : ''}`
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      const res = await this.$nuxt.$axios.get(url)
      const data = res?.data.data
      const icons = {
        COURIER_STANDARD: 'i-courier',
        COURIER_EXPRESS: 'i-courier',
        PUDO: 'i-myself',
        PUDO_AZERPOCT: 'i-azerpoct',
      }
      const shippingMethod =
        this.orderPackage.shipping.method ?? 'COURIER_STANDARD'
      const defLat = data?.find(
        method => method.code === shippingMethod,
      )?.default_latitude
      const defLong = data?.find(
        method => method.code === shippingMethod,
      )?.default_longitude

      if (
        (!this.orderPackage.shipping.latitude &&
          !this.orderPackage.shipping.longitude) ||
        this.orderPackage.shipping.latitude ===
          data?.find(method => method.code === 'COURIER_STANDARD')
            ?.default_latitude ||
        this.orderPackage.shipping.latitude ===
          data?.find(method => method.code === 'COURIER_EXPRESS')
            ?.default_latitude
      ) {
        this.orderPackage.shipping.latitude = defLat ?? this.default_latitude
        this.orderPackage.shipping.longitude = defLong ?? this.default_longitude
      }
      this.default_latitude = defLat
      this.default_longitude = defLong
      this.delivery_methods = data?.map(item => {
        return {
          ...item,
          icon: icons?.[item?.code] || '',
        }
      })
    } catch (e) {
      console.log('GET_DELIVERY_METHODS', e)
      return 'error'
    }
  },
  async getDeliveryTimeFrames() {
    const customerStore = useCustomer()
    this.$nuxt.$axios.defaults.baseURL =
      this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
    const options = new URLSearchParams()
    let urlDeliveryTimeFrames = `/order-packages/${this.orderPackageId}/delivery/time-frames`
    if (!customerStore.isLoggedIn) {
      urlDeliveryTimeFrames = `/anonymous/order-packages/${this.orderPackageId}/delivery/time-frames`
    }
    if (this.orderPackage.shipping.latitude) {
      options.append('latitude', this.orderPackage.shipping.latitude)
    }
    if (this.orderPackage.shipping.longitude) {
      options.append('longitude', this.orderPackage.shipping.longitude)
    }
    if (this.orderPackage.shipping.method) {
      options.append('delivery_method', this.orderPackage.shipping.method)
    }
    if (this.orderPackage.payment_method) {
      options.append('payment_method', this.orderPackage.payment_method)
    }
    if (this.orderPackage.shipping.pudo_point_id) {
      options.append('point_id', this.orderPackage.shipping.pudo_point_id)
    }
    urlDeliveryTimeFrames += `${
      options.toString() ? '?' + options.toString() : ''
    }`

    const {
      data: { data = [] },
    } = await this.$nuxt.$axios.get(`${urlDeliveryTimeFrames}`).catch(error => {
      console.log('ERROR:getDeliveryTimeFrames', error)
      return { data: { data: [] } }
    })
    // this.delivery_time_frames = data
    this.delivery_time_frames_pudo = data
    const grouped = {}
    for (const key in data) {
      const dateGrouped = {}
      data[key].forEach(item => {
        if (dateGrouped[item.date]) {
          dateGrouped[item.date].push(item)
        } else {
          dateGrouped[item.date] = [item]
        }
      })
      grouped[key] = dateGrouped
    }
    this.delivery_time_frames = grouped

    return grouped
  },
  async setShippingAddressStreet(onMap = false) {
    const {
      street = null,
      district = null,
      locality = null,
      precision = null,
      house = null,
      building = null,
      kind = null,
      apartment = null,
      name = null,
      pos = [this.default_latitude, this.default_longitude],
    } = this.orderPackage.shipping.geocodedAddress
    if (street) {
      this.orderPackage.shipping.address.street = street
    } else if (onMap) {
      this.orderPackage.shipping.address.street = `${name || ''}`
      this.orderPackage.shipping.address.apartment = ''
    } else {
      this.orderPackage.shipping.address.street = district || ''
    }
    this.orderPackage.shipping.address.city = locality
    this.orderPackage.shipping.address.precision = precision?.toLowerCase()
    this.orderPackage.shipping.address.house = house
    this.orderPackage.shipping.address.building = building
    this.orderPackage.shipping.address.apartment = apartment
    this.orderPackage.shipping.latitude = pos?.[0]
    this.orderPackage.shipping.address.kind = kind
    this.orderPackage.shipping.longitude = pos?.[1]
  },
  setInstallmentForOrder(months, id) {
    const order = this.orderPackage?.orders.find(order => order.id === id)
    order.installment_months = months
  },

  async GET_PUDO_CITIES() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
    }
    const options = {
      azerpoct: false,
    }
    if (this.isCBOrder()) {
      options.is_cross_border_available = true
    }
    try {
      const response = await this.$nuxt.$axios.get(
        `/pudo-cities?${serialize(options)}`,
      )
      this.cities_pudo = response.data.data
    } catch (e) {
      console.log('Error GET_PUDO_CITIES', e)
    }
  },
  async GET_PUDOS_FOR_ORDER({
    order_id,
    city_id,
    type = 'PUDO',
    types = {},
    latitude,
    longitude,
  }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      }
      const options = new URLSearchParams()
      options.append('city_id', city_id)
      if (type === 'PUDO_AZERPOCT') {
        options.append('azerpoct', true)
        options.append('state', 'ACTIVE')
        // options.append('type[]', 'AZERPOCT_BRANCH')
        if (latitude && longitude) {
          options.delete('city_id')
          options.append('latitude', latitude)
          options.append('longitude', longitude)
        }
      }
      if (type === 'PUDO') {
        options.append('azerpoct', 'false')
        if (types.is_fitting_available) {
          options.append('is_fitting_available', 'true')
        }
        if (latitude && longitude) {
          options.delete('city_id')
          options.append('latitude', latitude)
          options.append('longitude', longitude)
        }
        if (this.isCBOrder()) {
          options.append('is_cross_border_available', 'true')
        }
      }
      const {
        data: { data: pudos },
      } = await this.$nuxt.$axios.get(`/pudo-points?${options.toString()}`)
      if (type === 'PUDO_AZERPOCT') {
        this.pudos_azerpoct = pudos
      } else if (city_id) {
        this.pudos_for_order = pudos
      } else {
        this.pudos_for_order_map = pudos
      }
    } catch (e) {
      console.log('Error GET_PUDOS_FOR_ORDER', e)
      LOG_ERROR(this.$nuxt?.$rollbar, this?.$config, 'GET_PUDOS_FOR_ORDER', e, {
        order_id,
        city_id,
        type,
      })
    }
  },

  async GET_PUDOS({ city_id = 1, type }) {
    try {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL

      const options = new URLSearchParams()
      options.append('city_id', city_id)
      if (type === 'PUDO_AZERPOCT') {
        options.append('azerpoct', true)
        options.append('state', 'ACTIVE')
        // options.append('type[]', 'AZERPOCT_BRANCH')
      }
      const {
        data: { data: pudos },
      } = await this.$nuxt.$axios.get(`/pudo-points?${options.toString()}`)

      const set_pudos = pudos.map(pudo => ({
        id: pudo.id,
        latitude: pudo.latitude,
        longitude: pudo.longitude,
        name: pudo.name,
        address: pudo.address,
        friendly_schedule: pudo.friendly_schedule,
        schedule: pudo.schedule,
        type: pudo.type,
      }))
      if (type === 'PUDO_AZERPOCT') {
        this.pudos_azerpoct = set_pudos
      } else {
        this.pudos = set_pudos
      }
    } catch (e) {
      console.log('ERROR:GET_PUDOS', e)
    }
  },
  async updateOrders() {
    const customerStore = useCustomer()
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
    }
    const orderPackageId = this.order_packages.id
    const patchOrderUrl = customerStore.isLoggedIn
      ? `/orders-packages/${orderPackageId}`
      : `/anonymous/orders-packages/${orderPackageId}`

    // if (this.orderPackage.payment_method === 'CARD_ECOM_KB_INSTALLMENT') {
    //   this.orderPackage.payment_method = 'CARD_ECOM_KB'
    // }
    // this.orderPackage.state = 'CREATED'
    const options = setOptionsOrderPackage(this.orderPackage)
    const {
      data: { data },
    } = await this.$nuxt.$axios.patch(patchOrderUrl, options).catch(e => {
      console.log('Error UpdateOrders action', e)
      return { data: e.response }
    })
    this.order_packages?.orders.forEach(order => {
      // Use product ID as offer ID
      const offerIds = order.line_items
        .map(item => `${item.product_id}@${this.$nuxt.i18n.locale}`)
        .join(',')

      rtbAnalytics(this.$nuxt.i18n.locale, {
        eventType: 'conversion',
        conversionClass: 'order',
        conversionSubClass: 'purchase',
        conversionId: order.number, // Use order.number as conversionId
        offerIds,
        conversionValue: order.total_amount,
        conversionCurrency: 'AZN',
      })
    })
    // setDataLayerPurchaseLocal(data?.orders)
    return data
  },
  async getPaymentURLForOrder(order) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL = (
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
        ).replace('/v1', '/v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      }
      return await this.$nuxt.$axios.post(`/payments/checkout`, order)
    } catch (e) {
      console.log('getPaymentURLForOrder', e)
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'getPaymentURLForOrder',
        e,
        {
          order,
        },
      )
      throw e
    }
  },
  async createOrderPackageCustomer({
    mode = 'DEFAULT',
    orderState = 'CREATED',
  }) {
    try {
      const customerStore = useCustomer()
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const order_package_id =
        this.$nuxt.$auth.$storage.getCookie('order_package_id')
      const ext_id = customerStore.userProfile?.attributes?.ext_id
      const isExistingUser =
        this.$nuxt.$auth.$storage.getCookie('isExistingUser')
      const urlPackages = `/anonymous/orders-packages/${order_package_id}/customer`
      if (order_package_id) {
        if (isExistingUser && order_package_id) {
          const { data } = await this.$nuxt.$axios.put(urlPackages, {
            customer_id: ext_id,
          })
          this.$nuxt.$auth.$storage.setUniversal(
            'order_package_id',
            data?.data?.id,
          )
          return data
        } else {
          const { data } = await this.$nuxt.$axios.put(urlPackages, {
            customer_id: ext_id,
          })
          this.$nuxt.$auth.$storage.setUniversal(
            'order_package_id',
            data?.data?.id,
          )
          return data
        }
      }
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'Error createOrderPackageCustomer',
        e,
        {
          mode,
          orderState,
        },
      )
    }
  },
  async CHECK_ORDERS_PACKAGES() {
    const customerStore = useCustomer()
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
    }
    const options = {}
    if (this.orderPackage?.shipping?.method)
      options.delivery_method = this.orderPackage?.shipping?.method
    if (this.orderPackage?.payment_method)
      options.payment_method =
        this.orderPackage?.payment_method === 'CARD_ECOM_KB_INSTALLMENT'
          ? 'CARD_ECOM_KB'
          : this.orderPackage?.payment_method
    if (this.orderPackage?.shipping?.latitude)
      options.latitude = this.orderPackage?.shipping?.latitude
    if (this.orderPackage?.shipping?.longitude)
      options.longitude = this.orderPackage?.shipping?.longitude
    if (this.orderPackage?.shipping?.pudo_point_id)
      options.point_id = this.orderPackage?.shipping?.pudo_point_id
    if (this.orderPackage?.promocode)
      options.promocode = this.orderPackage?.promocode
    if (this.orderPackage?.premium_promocode)
      options.premium_promocode = this.orderPackage?.premium_promocode

    const urlPackages = customerStore.isLoggedIn
      ? `/orders-packages/${this.order_packages?.id}`
      : `/anonymous/orders-packages/${this.order_packages?.id}`
    const {
      data: { data = {} },
    } = await this.$nuxt.$axios
      .get(`${urlPackages}?${serialize(options)}`)
      .catch(error => {
        LOG_ERROR(
          this?.$rollbar,
          this.$nuxt?.$config,
          'CHECK_ORDERS_PACKAGES',
          error,
          data,
        )
      })
    this.orderPackage.shipping_cost = data?.shipping_cost
    this.orderPackage.total_amount = data?.total_amount
    this.orderPackage.service_fees = data?.service_fees
    this.orderPackage.orders.forEach(order => {
      const current_order = data?.orders?.find(
        find_order => find_order.id === order.id,
      )
      order.service_fees = current_order?.service_fees
      // order.max_installment_months = current_order?.max_installment_months
      order.shipping_cost = current_order?.shipping_cost
      if (current_order) order.total_amount = current_order?.total_amount
    })
  },
  async GET_INSTALLMENTS() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL.replace('/v1', '/v2') ||
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      }
      const res = await this.$nuxt.$axios.get(
        `/order-packages/${this.orderPackageId}/installments`,
      )
      this.installments = res.data.data
      return res
    } catch (e) {
      console.log('GET_INSTALLMENTS', e)
    }
  },

  // async MERGE_CARTS_ANON_USER({ mode = 'DEFAULT' }) {
  //   console.log('MERGE_CARTS_ANON_USER')
  //   const cart_id = this.$nuxt.$auth.$storage.getCookie('cart_id')
  //   this.cartId = cart_id
  //   const uiStore = useUiStore()
  //   if (process.server) {
  //     this.$nuxt.$axios.defaults.baseURL =
  //       this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
  //   } else {
  //     this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
  //   }
  //   if (cart_id) {
  //     const options = {
  //       cart_id,
  //       checkout_mode: mode,
  //     }
  //     if (uiStore.fromBB) {
  //       options.origin = 'BIRBANK'
  //     }
  //     if (mode === 'CART_LOAN') {
  //       options.is_test = false
  //       // options.loan_product = 'KB_LOAN_V2'
  //       options.loan_process_version = 7
  //     }
  //     try {
  //       const { data } = await this.$nuxt.$axios.post(
  //         `/orders-packages`,
  //         options,
  //       )
  //       this.$nuxt.$auth.$storage.setUniversal('cart_id', null)
  //       this.order_packages = data?.data
  //       this.$nuxt.$auth.$storage.setUniversal(
  //         'order_package_id',
  //         data?.data?.id,
  //       )
  //     } catch (e) {
  //       LOG_ERROR(
  //         this.$nuxt?.$rollbar,
  //         this.$nuxt?.$config,
  //         'MERGE_CARTS_ANON_USER111',
  //         e,
  //         options,
  //       )
  //     }
  //   }
  // },
  isOrderDeliveryCalculated(value) {
    const deliveryMethod = this.delivery_methods?.find(
      method => method.code === value,
    )
    const deliveryFee = deliveryMethod?.fee?.find(
      fee => fee.code === 'DELIVERY_FEE',
    )

    return Boolean(
      deliveryFee?.calculation_status === 'CALCULATED' &&
        deliveryFee?.code === 'DELIVERY_FEE',
    )

    // return Boolean(
    //   this.delivery_methods?.find(
    //     method =>
    //       method.code === value &&
    //       method?.fee[0]?.calculation_status === 'CALCULATED' &&
    //       method?.fee[0]?.code === 'DELIVERY_FEE',
    //   ),
    // )
  },
  async setGroseryExpressDeliveryIfAvailable() {
    const ordersHasGrosery = this.orderPackage.orders.find(
      order => order.partner.id === this.grossery_merchant_uid,
    )
    const order_has_express = this.delivery_methods?.find(
      method =>
        method.code === 'COURIER_EXPRESS' &&
        method?.fee[0]?.calculation_status === 'CALCULATED' &&
        method?.fee[0]?.code === 'DELIVERY_FEE',
    )
    if (ordersHasGrosery && order_has_express) {
      this.orderPackage.shipping.method = 'COURIER_EXPRESS'
    }
  },
  async setPreferredShippingMethod() {
    const order_fill_preferences = this.order_fill_preferences
    const shipping_method = order_fill_preferences?.shipping_method
    const calculated_delivery = this.isOrderDeliveryCalculated(shipping_method)

    if (
      shipping_method &&
      (shipping_method === 'COURIER_STANDARD' ||
        shipping_method === 'COURIER_EXPRESS')
    ) {
      if (calculated_delivery) {
        this.orderPackage.shipping.method = shipping_method
      } else {
        if (
          shipping_method === 'COURIER_STANDARD' &&
          this.isOrderDeliveryCalculated('COURIER_EXPRESS')
        ) {
          this.orderPackage.shipping.method = 'COURIER_EXPRESS'
        } else if (
          shipping_method === 'COURIER_EXPRESS' &&
          this.isOrderDeliveryCalculated('COURIER_STANDARD')
        ) {
          this.orderPackage.shipping.method = 'COURIER_STANDARD'
        } else {
          this.orderPackage.shipping.method = 'COURIER_STANDARD'
          this.orderPackage.shipping.address =
            orderPackageOriginal.shipping.address
          this.orderPackage.shipping.latitude =
            orderPackageOriginal.shipping.latitude
          this.orderPackage.shipping.longitude =
            orderPackageOriginal.shipping.longitude
          await this.GET_DELIVERY_METHODS()
        }
      }
    }
  },
  async setOrdersAddress({
    preferred = false,
    disableAzPost = true,
    from,
  } = {}) {
    // TODO need fot find start function setOrdersAddress
    // if(process.env.NODE_ENV === 'development'){
    //   console.log('setOrdersAddress_from:', from)
    // }
    if (this.orderPackage.shipping.address.precision !== 'incorrect') {
      await this.GET_DELIVERY_METHODS()
      if (preferred) {
        await this.setPreferredShippingMethod()
      }
    }
    try {
      const delivery_method = this.orderPackage.shipping.method
      if (disableAzPost || delivery_method !== 'PUDO_AZERPOCT') {
        await this.getDeliveryTimeFrames()
      }
      await this.CHECK_ORDERS_PACKAGES()
      if (delivery_method === 'PUDO' || delivery_method === 'PUDO_AZERPOCT') {
        this.orderPackage.orders.forEach(order => {
          const current_time_frame =
            this.delivery_time_frames_pudo?.[order.id]?.[0]
          order.shipping.date = current_time_frame?.date
          order.shipping.time_frame = current_time_frame?.code
        })
      }
      if (
        delivery_method === 'PUDO_AZERPOCT' ||
        (delivery_method === 'COURIER_EXPRESS' &&
          this.orderPackage.shipping?.latitude &&
          this.orderPackage.shipping?.longitude)
      ) {
        this.orderPackage.orders.forEach(order => {
          order.shipping = {
            time_frame: this.delivery_time_frames_pudo?.[order?.id]?.[0]?.code,
            date: this.delivery_time_frames_pudo?.[order?.id]?.[0]?.date,
          }
        })
      }
      if (delivery_method === 'COURIER_STANDARD') {
        this.orderPackage.orders.forEach(order => {
          order.shipping = {
            time_frame: null,
            date: this.delivery_time_frames_pudo?.[order?.id]?.[0]?.date,
          }
        })
      }
      // if (this.orderPackage.payment_method === 'LOAN_ON_DELIVERY') {
      const checkoutCredit = useCheckoutCredit()
      await checkoutCredit.GET_CREDIT_ANNUITY(this.amountForPayment())
      // }
    } catch (e) {
      console.log(e)
    }
  },
  async setAddressInput() {
    if (
      !(
        this.orderPackage?.shipping.address?.building ||
        this.orderPackage?.shipping.address?.city ||
        this.orderPackage?.shipping.city?.street
      )
    ) {
      this.addressInput = ''
    }

    const method = this.delivery_methods?.find(
      method => method.code === this.orderPackage.shipping.method,
    )
    const fee = method?.fee?.find(fee => fee.code === 'DELIVERY_FEE')

    if (fee && fee?.code === 'DELIVERY_FEE') {
      this.addressInput = [
        this.orderPackage?.shipping.address?.city,
        this.orderPackage?.shipping.address?.street,
        this.orderPackage?.shipping.address?.building,
      ]
        .filter(Boolean)
        .join(', ')
    }
  },
  async GET_PAYMENT_METHODS_ORDER_PACKAGE({ orderPackageId, deliveryMethod }) {
    const customerStore = useCustomer()
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CART_API_URL.replace('/v1', '/v2') ||
        this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
    }
    try {
      const url = customerStore.isLoggedIn
        ? `/order-packages/${orderPackageId}/payment/methods?delivery_method=${deliveryMethod}`
        : `/anonymous/order-packages/${orderPackageId}/payment/methods?delivery_method=${deliveryMethod}`
      const {
        data: { data = [] },
      } = await this.$nuxt.$axios.get(url)
      return data
    } catch (e) {
      console.log('Error: GET_PAYMENT_METHODS_ORDER_PACKAGE', e)
    }
  },
  async getDefaultSettings() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
    }
    const {
      data: { data },
    } = await this.$nuxt.$axios.get(`/default-settings/`)
    this.defaultSettings = data
    const checkoutCredit = useCheckoutCredit()
    checkoutCredit.minAmountBuyCredit = this.defaultSettings.find(
      item => item.code === 'MIN_CREDIT_AMOUNT',
    )?.value?.number

    return data
  },
  async getCitiesForCurerStandard() {
    this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
    const response = await this.$nuxt.$axios.get(`/courier-standard-cities`)
    this.listCitiesCurerStandard = response?.data?.data || []
    this.currentCityCurerStandard = this.listCitiesCurerStandard.find(
      city => city.id === 1,
    )
    this.currentCityCurerStandardID = this.listCitiesCurerStandard.find(
      city => {
        return city.id === 1
      },
    )?.id
    return response
  },
}
