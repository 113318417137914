import {
  LOG_ERROR,
  rtbAnalytics,
  serialize,
  setDataLayerPurchaseLocal,
  setNewOrdersPackagesOptions,
} from '@/utilities'
import { useCustomer } from '@/stores/customer'
import Vue from 'vue'
import { useCheckoutCredit } from '@/stores/checkout_credit'
import { useEventDataLayer } from '@/stores/event_data_layer'

export default {
  setDeliveryInfo(orders) {
    this.deliveryInfo = []
    this.deliveryInfo = orders.map(order => {
      return {
        orderId: order.id,
        delivery: JSON.parse(JSON.stringify(order.delivery)), // Deep copy
        deliveryPrice: JSON.parse(JSON.stringify(order.service_fees)), // Deep copy
      }
    })
  },

  async getOrderPackages() {
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')

      const res = await this.$nuxt.$axios.get(
        `/orders-packages/${this.orderPackage?.id}`,
      )
      this.orderPackage.id = res?.data?.data?.id
      this.orderPackage.orders = res?.data?.data?.orders
      this.orderPackage.total_amount = res?.data?.data?.total_amount
      this.firstOrderId = this.orderPackage?.orders[0]?.id
      await this.setDeliveryInfo(this.orderPackage.orders)
    } catch (e) {
      console.log('Error getOrderPackages_v3', e)
    }
  },
  async getOrderPackagesThankYouPage() {
    try {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL

      const res = await this.$nuxt.$axios.get(
        `/orders-packages/${this.orderPackage?.id}`,
      )
      this.orderPackage.id = res?.data?.data?.id
      this.orderPackage.loan_application_id =
        res?.data?.data?.loan_application?.id
      this.orderPackage.orders = res?.data?.data?.orders
      return res
    } catch (e) {
      console.log('Error getOrderPackagesThankYouPage', e)
    }
  },
  async postOrdersPackages(checkGrocery = false) {
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')
      const customerStore = useCustomer()
      const urlPackages = checkGrocery
        ? 'orders-packages/grocery'
        : '/orders-packages'
      const res = await this.$nuxt.$axios
        .post(urlPackages, {
          latitude: Number(customerStore.customerCoordinates?.lat) || 40.597782, // взять координат города из профиля пользователя если нет центр баку
          longitude:
            Number(customerStore.customerCoordinates?.lng) || 49.674576, // взять координат города из профиля пользователя если нет центр баку
          is_test: false,
        })
        .catch(e => {
          console.log(e)
        })

      if (res) {
        this.orderPackage.id = res?.data?.data?.id
        this.orderPackage.orders = res?.data?.data?.orders
        this.orderPackage.total_amount = res?.data?.data?.total_amount

        const hasMissingAddress = this.orderPackage.orders.some(order => {
          return !order.delivery?.address && !order.delivery?.pudo_point
        })

        // if (hasMissingAddress) {
        //   this.continueButtonDisabled = true
        // }
      }
      this.firstOrderId = this.orderPackage?.orders[0]?.id
      await this.setDeliveryInfo(this.orderPackage.orders)
      return res?.data
    } catch (e) {
      console.log('Error postOrdersPackages', e)
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'postOrdersPackages',
        e,
      )
      throw e
    }
  },
  async checkDeliveryMethodAvailability(
    methodInfo,
    addressId,
    addressLine,
    selectedOrderId,
  ) {
    try {
      const matchingOrderIds = this.deliveryMethods
        .filter(orderObj =>
          orderObj.orderDeliveryMethods.some(
            method => method.code === methodInfo.code,
          ),
        )
        .map(orderObj => orderObj.orderId)

      this.applyToMultipleOrders = {
        orderIds: matchingOrderIds,
        selectedOrderId,
        multipleOrders: matchingOrderIds.length > 1,
        selectedMethod: methodInfo.code,
        icon: methodInfo.icon,
        selectedAddressId: addressId,
        addressLine,
        label: methodInfo.label,
      }
    } catch (e) {}
  },

  async patchOrderPackage({ isMultiple = false, cityChange = false }) {
    try {
      const customerStore = useCustomer()
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')
      const {
        selectedOrderId = null,
        selectedMethod = null,
        selectedAddressId = null,
        orderIds = [],
      } = this.applyToMultipleOrders || {}

      const options = {
        latitude: Number(customerStore.customerCoordinates?.lat) || 40.409264,
        longitude: Number(customerStore.customerCoordinates?.lng) || 49.867092,
      }

      if (!isMultiple && !cityChange) {
        options.order_id = selectedOrderId
      }

      const isPudoMethod = ['PUDO', 'PUDO_AZERPOCT'].includes(selectedMethod)
      const addressKey = isPudoMethod ? 'pudo_id' : 'address_id'

      if (!cityChange) {
        options.method_code = selectedMethod
        options[addressKey] = selectedAddressId
      }

      const res = await this.$nuxt.$axios
        .patch(
          `/orders-packages/${this.orderPackage.id}/delivery/methods`,
          options,
        )
        .catch(e => {
          console.log(e)
        })
      if (cityChange) {
        await this.resetOrderPackage()
      }
      if (selectedMethod === 'COURIER_STANDARD' && isMultiple && !cityChange) {
        this.selectedDateTimeFrame = {}
        for (const orderId of orderIds) {
          await this.getDeliveryTimeFrames(orderId)
        }
      } else if (
        selectedMethod === 'COURIER_STANDARD' &&
        !isMultiple &&
        !cityChange
      ) {
        delete this.selectedDateTimeFrame[selectedOrderId]
        await this.getDeliveryTimeFrames(selectedOrderId)
      }
      await this.setDeliveryInfo(res?.data?.data?.orders)
      this.orderPackage.id = res?.data?.data?.id
      this.orderPackage.orders = res
        ? res?.data?.data?.orders
        : this.orderPackage.orders
      this.orderPackage.total_amount = res?.data?.data?.total_amount
      await this.updateDeliveryMethods(res?.data?.data)
    } catch (e) {}
  },

  updateDeliveryMethods(newOrderPackage) {
    newOrderPackage.orders.forEach(order => {
      // Find the delivery methods for the given order ID in the new order package
      const orderMethods = this.deliveryMethods.find(
        methodObj => methodObj.orderId === order.id,
      )

      if (orderMethods) {
        // Update the active property based on the selected delivery method in the new order package
        orderMethods.orderDeliveryMethods.forEach(method => {
          method.active =
            method.code === order.delivery.method.code &&
            (!!order?.delivery?.address || !!order?.delivery?.pudo_point)
        })
      }
    })
  },
  async getDeliveryMethods(package_id) {
    const customerStore = useCustomer()
    try {
      const lat = Number(customerStore.customerCoordinates?.lat) || 40.409264
      const lng = Number(customerStore.customerCoordinates?.lng) || 49.867092
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')
      const res = await this.$nuxt.$axios
        .get(
          `order-packages/${package_id}/delivery/methods?latitude=${lat}&longitude=${lng}`,
        )
        .catch(e => {
          console.log(e)
        })

      this.deliveryMethods = this.orderPackage?.orders?.map(order => {
        // Find the matching object in the res.data.data array by order_id
        const orderData = res?.data?.data?.find(
          data => data.order_id === order.id,
        )

        // If matching order data is found, map the methods to include the 'active' property
        const methods =
          orderData?.methods?.map(method => ({
            ...method,
            active:
              method.code === order?.delivery?.method?.code &&
              (!!order?.delivery?.address || !!order?.delivery?.pudo_point),
          })) || []

        return {
          orderId: order.id,
          orderDeliveryMethods: methods,
        }
      })
    } catch (e) {
      console.log('Error getDeliveryMethods', e)
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getDeliveryMethods',
        e,
      )
    }
  },
  setDeliveryTimeFrame(
    selectedTimeFrameStartTime,
    selectedTimeFrameDate,
    orderId,
  ) {
    // Find the full timeframe object matching the selected code and date
    const selectedTimeFrame = Object.values(this.deliveryTimeFrames)
      .flat()
      .find(
        timeframe =>
          timeframe.start_time === selectedTimeFrameStartTime &&
          timeframe.date === selectedTimeFrameDate,
      )

    if (!selectedTimeFrame) {
      return
    }

    // Find all orders that have this matching timeframe
    const matchingTimeFrames = Object.keys(this.deliveryTimeFrames).filter(id =>
      this.deliveryTimeFrames[id].some(
        timeframe =>
          timeframe.start_time === selectedTimeFrameStartTime &&
          timeframe.date === selectedTimeFrameDate,
      ),
    )
    const isFirstTime = Object.keys(this.selectedDateTimeFrame).length === 0

    if (isFirstTime) {
      // First time: set the time frame for all matching orders

      ;(matchingTimeFrames.length ? matchingTimeFrames : [orderId]).forEach(
        id => {
          Vue.set(this.selectedDateTimeFrame, id, selectedTimeFrame)
        },
      )
    } else {
      // Subsequent changes: set the time frame only for the current order
      Vue.set(this.selectedDateTimeFrame, orderId, selectedTimeFrame)
    }
  },

  async putDeliveryTimeFrames(orderIds) {
    try {
      // Set the base URL for the API
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')

      const options = orderIds.reduce((acc, orderId) => {
        if (this.selectedDateTimeFrame[orderId]) {
          const { code, date } = this.selectedDateTimeFrame[orderId]
          acc.push({
            order_id: orderId,
            time_frame_code: code,
            date,
          })
        }
        return acc
      }, [])

      // Make the API request if there are valid timeframes to submit
      if (Object.keys(options).length > 0) {
        const response = await this.$nuxt.$axios.put(
          `/orders-packages/${this.orderPackage.id}/delivery/time-frames`,
          options,
        )
        return response.data
      } else {
        console.warn('No valid timeframes found for the provided order IDs.')
      }
    } catch (error) {
      console.log('error', error.response)

      console.error('Failed to update delivery timeframes:', error)

      throw error
    }
  },
  async getDeliveryTimeFrames(order_id) {
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')
      const res = await this.$nuxt.$axios
        .get(`orders/${order_id}/delivery/time-frames`)
        .catch(e => {
          console.log(e)
        })

      if (res) {
        this.deliveryTimeFrames[order_id] = null
        this.deliveryTimeFrames[order_id] = res.data?.data
        this.getNewTimeFramesForceUpdate = !this.getNewTimeFramesForceUpdate
      }
    } catch (e) {
      console.log('Error getDeliveryTimeFrames', e)
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getDeliveryTimeFrames',
        e,
      )
    }
  },
  async resetOrderPackage() {
    this.orderPackage = {
      credit_allowed: false,
      id: null,
      orders: [],
      payment_method: null,
      state: null,
      submitted: false,
      shipping_cost: 0,
      subtotal_amount: 0,
      token_id: 0,
      total_amount: 0,
      promocode_type: '', // discount, cashback
      promocode: '',
      premium_promocode: '',
      total_promocode_cashback: 0,
      total_promocode_discount: 0,
    }
    this.userSavedAddresses = []
    this.userSavedAddressesExpress = []
    this.deliveryTimeFrames = []
    this.savedPudoPointsAzerpost = []
    this.savedPudoPoints = []
    this.selectedDateTimeFrame = {}
    this.deliveryInfo = []
  },
  async checkDeliveryToNewCity() {
    const customerStore = useCustomer()
    const lat = Number(customerStore.customerCoordinates?.lat) || 40.409264
    const lng = Number(customerStore.customerCoordinates?.lng) || 49.867092
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')

      const res = await this.$nuxt.$axios.get(
        `/order-packages/${this.orderPackage?.id}/delivery/available?latitude=${lat}&longitude=${lng}`,
      )
      return res.data?.data
    } catch (e) {
      console.log('Error checkDeliveryToNewCity', e)
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'checkDeliveryToNewCity',
        e,
      )
    }
  },
  async checkDeliveryMethods(order_id, method_code, pos) {
    const latitude = pos?.[0]
    const longitude = pos?.[1]
    this.$nuxt.$axios.defaults.baseURL =
      this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')
    const response = await this.$nuxt.$axios
      .get(
        `/orders/${order_id}/delivery/methods/${method_code}?latitude=${latitude}&longitude=${longitude}`,
      )
      .catch(e => {
        LOG_ERROR(
          this?.$nuxt.$rollbar,
          this?.$nuxt.$config,
          'checkDeliveryMethods',
          e,
        )
        return null
      })

    return response?.data || {}
  },
  async postDeliveryAddress() {
    this.$nuxt.$axios.defaults.baseURL =
      this.$nuxt.$config.CART_API_URL.replace('v1', 'v2')
    const list_kind = [
      'house',
      'metro',
      'railway_station',
      'station',
      'airport',
      'entrance',
    ]

    const hasKind = this.geocodedAddress?.all_address?.some(item =>
      list_kind.includes(item.kind),
    )
    const hasPrecision = this.geocodedAddress?.precision === 'other'
    const addrres = this.geocodedAddress
    const locality = addrres?.locality ? addrres.locality : ''
    const street = addrres?.street
      ? addrres.street
      : addrres?.district?.[0]
      ? addrres.district[0]
      : ''

    const addressLine1 = [locality, street, addrres.house]
      .filter(Boolean)
      .join(', ')
    const options = {
      address_line_1: addressLine1,
      address_line_2: this.geocodedAddress?.apartment,
      latitude: Number(this.geocodedAddress?.pos[0]),
      longitude: Number(this.geocodedAddress?.pos[1]),
      is_precise:
        this.geocodedAddress.precision === 'exact' || (hasKind && hasPrecision),
    }

    const res = await this.$nuxt.$axios
      .post(`/customer/shipping-addresses`, options)
      .catch(e => {
        LOG_ERROR(
          this?.$nuxt.$rollbar,
          this?.$nuxt.$config,
          'postDeliveryAddress',
          e,
        )
      })
    this.geocodedAddress = {
      all_address: [],
      house: '',
      locality: '',
      name: '',
      pos: [],
      precision: '',
      street: '',
      canSaveAddress: false,
    }
    return res.data?.data
  },
  async getCustomerPudoPoints(method, orderId) {
    const customerStore = useCustomer()
    const lat = Number(customerStore.customerCoordinates?.lat) || 40.409264
    const lng = Number(customerStore.customerCoordinates?.lng) || 49.867092
    const options = {
      latitude: lat,
      longitude: lng,
      delivery_method: method,
      order_package_id: this.orderPackage?.id,
    }
    const res = this.isCBOrder(orderId)

    if (this.isCBOrder(orderId)) {
      options.is_cross_border_available = true
    }
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v2')

      const res = await this.$nuxt.$axios.get(
        `/pudo-points/customer-selected?${serialize(options)}`,
      )
      if (method === 'PUDO') {
        this.savedPudoPoints = res.data?.data
      } else if (method === 'PUDO_AZERPOCT') {
        this.savedPudoPointsAzerpost = res.data?.data
      }
    } catch (e) {
      console.log('Error getCustomerPudoPoints', e)
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getCustomerPudoPoints',
        e,
      )
    }
  },

  async getPudoPointsByCity(method, latitude, longitude, fitting = false) {
    try {
      const isAzerPost = method === 'PUDO_AZERPOCT'
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v2')

      const options = {
        latitude,
        longitude,
        azerpoct: isAzerPost,
      }
      if (fitting) {
        options.is_fitting_available = true
      }
      serialize(options)
      const res = await this.$nuxt.$axios.get(
        `/pudo-points?${serialize(options)}`,
      )
      if (method === 'PUDO_AZERPOCT') {
        this.azerpoctPointsForCity = res.data?.data
      } else if (method === 'PUDO') {
        this.pudoPointsForCity = res.data?.data
      }
      return res.data?.data
    } catch (e) {
      console.log('Error getPudoPointsByCity', e)
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getPudoPointsByCity',
        e,
      )
    }
  },

  async addNewPudoPoint(pudoId) {
    console.log('addNewPudoPoint')
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v2')

      await this.$nuxt.$axios.post(`/pudo-points/customer-selected`, {
        pudo_point_id: pudoId,
        order_package_id: this.orderPackage?.id,
      })
    } catch (e) {
      console.log('Error addNewPudoPoint', e)
      LOG_ERROR(this?.$nuxt.$rollbar, this?.$nuxt.$config, 'addNewPudoPoint', e)
    }
  },
  async getSavedDeliveryAddresses(method, isGrocery) {
    const customerStore = useCustomer()
    const lat = Number(customerStore.customerCoordinates?.lat) || 40.409264
    const lng = Number(customerStore.customerCoordinates?.lng) || 49.867092
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v2')
      const options = {
        latitude: lat,
        longitude: lng,
        delivery_method: method,
      }
      if (isGrocery) {
        options.is_grocery = true
      }
      const res = await this.$nuxt.$axios.get(
        `/customer/shipping-addresses?${serialize(options)}`,
      )
      if (method === 'COURIER_STANDARD') {
        this.userSavedAddresses = res?.data?.data
      } else if (method === 'COURIER_EXPRESS') {
        this.userSavedAddressesExpress = res?.data?.data
      }
    } catch (e) {
      console.log('Error getSavedDeliveryAddresses', e)
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getSavedDeliveryAddresses',
        e,
      )
    }
  },

  async setAddressInput() {
    const addrres = this.geocodedAddress
    const locality = addrres?.locality ? `${addrres?.locality},` : ''
    const street = addrres?.street
      ? addrres?.street
      : addrres?.district?.[0]
      ? `${addrres?.district?.[0]},`
      : ''
    this.addressInput = `${locality} ${street} ${addrres?.house}`
  },
  resetGeocodedAddress() {
    this.geocodedAddress = {
      all_address: [],
      house: '',
      locality: '',
      name: '',
      pos: [],
      precision: '',
      street: '',
      canSaveAddress: false,
    }
  },
  // Payment methods
  async getPaymentMethods() {
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')
      const url = `/order-packages/${this.orderPackage.id}/payment/methods`
      const {
        data: { data = [] },
      } = await this.$nuxt.$axios.get(url)
      this.paymentMethods = data
      const isCardEcomAvailable = this.paymentMethods.find(method => {
        return method.code === 'CARD_ECOM_KB'
      })
      const isLoanMethodAvailable = this.paymentMethods.find(method => {
        return method.code === 'LOAN_ON_DELIVERY'
      })
      if (isCardEcomAvailable) {
        await this.getPaymentCards()
      }
      if (isLoanMethodAvailable) {
        const creditCheckout = useCheckoutCredit()
        await creditCheckout.CHECK_USER_CREDIT_BALANCE()
      }
    } catch (e) {
      console.log('Error getPaymentMethods', e)
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getPaymentMethods',
        e,
      )
    }
  },
  async checkOrdersPackages(options) {
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v2')
      const params = new URLSearchParams()
      if (options?.payment_method) {
        params.append('payment_method', options?.payment_method)
      }
      if (options?.promocode) {
        params.append('promocode', options?.promocode)
      }
      if (options?.premium_promocode) {
        params.append('premium_promocode', options?.premium_promocode)
      }

      const {
        data: { data = {} },
      } = await this.$nuxt.$axios.get(
        `/order-packages/${this.orderPackage.id}/preview${
          params.size ? `?${params.toString()}` : ``
        }`,
      )
      this.order_packages = data
      if (!options) {
        this.orderPackage.shipping = data?.shipping
      }
      this.orderPackage.shipping_cost = data?.shipping_cost
      this.orderPackage.subtotal_amount = data?.subtotal_amount
      this.orderPackage.total_amount = data?.total_amount
      this.orderPackage.service_fees = data?.service_fees
      this.orderPackage.payment_method = data?.orders[0]?.payment_method?.code
      if (!this.orderPackage.orders.length) {
        this.orderPackage.orders = data?.orders
      } else {
        this.orderPackage.orders.forEach(order => {
          const currentOrder = data?.orders?.find(
            order_item => order_item.id === order.id,
          )
          order.service_fees = currentOrder?.service_fees
          order.subtotal_amount = currentOrder?.subtotal_amount
          order.shipping_cost = currentOrder?.shipping_cost
          order.shipping = currentOrder?.shipping
          order.total_amount = currentOrder?.total_amount
          order.promocode_discount = currentOrder?.promocode_discount
          order.payment = currentOrder?.payment_method
          order.payment_method = currentOrder?.payment_method
          order.max_installment_months = currentOrder?.max_installment_months
        })
      }
      this.orderPackage.total_promocode_discount =
        data?.total_promocode_discount || 0
      this.orderPackage.total_promocode_cashback =
        data?.total_promocode_cashback || 0
      return data
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'checkOrdersPackages',
        e,
      )
      return e
    }
  },
  async checkCreditAllowed() {
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')
      return await this.$nuxt.$axios.get(`/orders-packages/credit_allow`)
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'checkCreditAllowed',
        e,
      )
    }
  },
  async getLoanApplicationData() {
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v5')
      const {
        data: { data },
      } = await this.$nuxt.$axios.get('/loan-applications/customers/first')

      this.loanApplication.first_name = data?.first_name
      this.loanApplication.last_name = data?.last_name
      this.loanApplication.phone_number = data?.phone_number
      this.loanApplication.fin_code = data?.fin_code
      this.loanApplication.additional_phone_number1 =
        data?.additional_phone_number1
      this.loanApplication.additional_phone_number2 =
        data?.additional_phone_number2
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getLoanApplicationData',
        e,
      )
    }
  },
  async getPaymentCards() {
    try {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      const {
        data: { data },
      } = await this.$nuxt.$axios.get('payments/cards')
      this.paymentCards = [
        {
          masked_pan: 'new_card',
          token_id: 0,
        },
        ...data,
      ]
    } catch (e) {
      LOG_ERROR(this?.$nuxt.$rollbar, this?.$nuxt.$config, 'getPaymentCards', e)
    }
  },
  async createOrdersPackages(checkGrocery) {
    const useCustomerStore = useCustomer()
    this.$nuxt.$axios.defaults.baseURL =
      this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')
    const options = {
      latitude:
        Number(useCustomerStore?.customerCoordinates?.lat) || 49.83403654651088,
      longitude:
        Number(useCustomerStore?.customerCoordinates?.lng) || 40.39245493228211,
      is_test: false,
    }
    const urlPackages =
      checkGrocery === 'GROCERY'
        ? 'orders-packages/grocery'
        : '/orders-packages'
    const response = await this.$nuxt.$axios
      .post(urlPackages, options)
      .catch(error => {
        LOG_ERROR(
          this?.$nuxt.$rollbar,
          this?.$nuxt.$config,
          'getPaymentCards',
          error,
        )
      })
  },
  async updateOrders() {
    const eventDataLayer = useEventDataLayer()
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')
      const orderPackageId = this.order_packages?.id
      const options = setNewOrdersPackagesOptions(
        this.orderPackage,
        this.loanApplication,
      )
      const {
        data: { data },
      } = await this.$nuxt.$axios.patch(
        `/orders-packages/${orderPackageId}`,
        options,
      )
      // const ids = this.order_packages?.orders.map(item => item.id)
      // rtbAnalytics(this.$nuxt.i18n.locale, {
      //   eventType: 'conversion',
      //   conversionClass: 'order',
      //   conversionSubClass: 'purchase',
      //   conversionId: this.order_packages?.id,
      //   offerIds: ids,
      //   conversionValue: this.order_packages.total_amount,
      //   conversionCurrency: 'AZN',
      // })
      eventDataLayer.setDataLayerPurchase()
      return data
    } catch (e) {
      console.log('Error updateOrders', e)
      eventDataLayer.errorEventPurchaseButton = e?.response?.data?.description
      return { data: e?.response }
    }
  },
  async getPaymentURLForOrder(order) {
    this.$nuxt.$axios.defaults.baseURL =
      this.$nuxt.$config.CART_API_URL.replace('v1', 'v2')
    return await this.$nuxt.$axios.post('/payments/checkout', order)
  },
  setDefaultLoanTerm(item = {}) {
    this.loanApplication.commission_amount = item?.commission_amount
    this.loanApplication.down_payment_amount = item?.down_payment_amount
    this.loanApplication.loan_term = item?.loan_term
    this.loanApplication.monthly_commission_rate = item?.monthly_commission_rate
    this.loanApplication.monthly_payment_amount = item?.monthly_payment_amount
    this.loanApplication.old_monthly_commission_rate =
      item?.old_monthly_commission_rate
    this.loanApplication.product_type = item?.product_type || null
    this.loanApplication.transaction_amount = item?.transaction_amount
  },
  async getLoanTransactionDetails() {
    try {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.BROKER_BASE_URL
      const orderTotalAmount =
        this.orderPackage.total_amount + this.orderPackage.shipping_cost
      const creditCheckout = useCheckoutCredit()
      const isFirstLoan = creditCheckout.checkFirstCredit
      const url = isFirstLoan
        ? `/api/v3/products?product_code=KB_LOAN_V7&amount=${orderTotalAmount}`
        : `/api/v3/customer/transaction/details?amount=${orderTotalAmount}`
      const response = await this.$nuxt.$axios.get(url)
      if (isFirstLoan) {
        this.loanTransactionDetails = response?.data?.annuity
          .map(item => {
            return {
              commission_amount: item?.commissions,
              down_payment_amount: 0,
              loan_term: item?.term,
              monthly_commission_rate: item?.rate_per_month,
              monthly_payment_amount: item?.amount_per_month,
              old_monthly_commission_rate: item?.old_rate,
              transaction_amount: item?.credit_amount,
            }
          })
          .sort((a, b) => b.loan_term - a.loan_term)
      } else {
        this.loanApplication.track_id = response?.data?.track_id
        this.loanTransactionDetails = response?.data?.offers?.sort(
          (a, b) => b.loan_term - a.loan_term,
        )
      }
      this.setDefaultLoanTerm(this.loanTransactionDetails[0])
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getLoanTransactionDetails',
        e,
      )
    }
  },
}
