
import Vue from 'vue'
import { useUiStore } from '@/stores/ui'
export default {
  name: 'LayoutCompanies',
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  data() {
    return {
      map: null,
      trottling: null,
    }
  },
  head() {
    const hasPage = !!this.$route?.query?.page
    const canonical = {
      hid: 'canonical',
      rel: 'canonical',
      href: this.$config.BASE_URL + this.$route.path,
    }
    return {
      title: this.$t('site_title'),
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        this.$i18n.locale === 'en'
          ? {
              name: 'robots',
              content: 'noindex, nofollow',
            }
          : '',
        {
          hid: 'description',
          name: 'description',
          content: this.$t('site_description'),
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: this.$t('site_title'),
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('site_title'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('site_description'),
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: 'https://umico.az/umico-og-image.jpg',
        },
      ],
      link: [!hasPage ? canonical : {}],
    }
  },

  computed: {
    showMap() {
      return !(this.uiStore.isRootCatalogShow && this.uiStore.isMobile)
    },
  },

  watch: {
    showMap(val) {
      if (!this.map && val) {
        this.createMap()
      }
    },
  },
  mounted() {
    if (this.showMap) this.createMap()
  },
  methods: {
    createMap() {
      if (!this.map) {
        this.map = Vue.component('Map', () =>
          import('@/components/Map/Map.vue'),
        )
      }
    },
  },
}
