
import { mapWritableState } from 'pinia'
import { useDcmStore } from '~/stores/dcm'
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'LayoutCheckout',
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    return {
      marketStore,
      uiStore,
    }
  },
  head() {
    const hasPage = !!this.$route?.query?.page
    const canonical = {
      hid: 'canonical',
      rel: 'canonical',
      href: this.$config.BASE_URL + this.$route.path,
    }
    return {
      title: this.$t('site_title'),
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        this.$i18n.locale === 'en'
          ? {
              name: 'robots',
              content: 'noindex, nofollow',
            }
          : '',
        {
          hid: 'description',
          name: 'description',
          content: this.$t('site_description'),
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: this.$t('site_title'),
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('site_title'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('site_description'),
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: 'https://umico.az/umico-og-image.jpg',
        },
      ],
      link: [!hasPage ? canonical : {}],
    }
  },
  computed: {
    ...mapWritableState(useDcmStore, ['content_checkout']),
    isHiddenAdv() {
      return this.isContentAdv ? this.content_checkout[0]?.is_hidden : false
    },
    isContentAdv() {
      return !!this.content_checkout
    },
    itemsAdv() {
      return this.isContentAdv ? this.content_checkout[0]?.items : []
    },
    isSingleOrder() {
      return this.marketStore.orderPackages?.orders.length === 1
    },
    orders() {
      const _orders = Object.values(
        JSON.parse(JSON.stringify(this.marketStore.ordersForUpdate)),
      ).map(item =>
        item.payment?.method === 'CARD_ECOM_KB' &&
        item.payment?.installment ===
          `INSTALLMENT_${item.payment?.max_installment_months}_MONTH`
          ? 'TAXIT'
          : item.payment?.method,
      )
      return [...new Set(_orders)]
    },
    isMPPromoInfoBlock() {
      let show = true
      if (this.isSingleOrder) {
        if (this.containsOnly(['CARD_ECOM_KB'], this.orders)) {
          show = false
        }
        if (this.containsOnly(['CASH_ON_DELIVERY'], this.orders)) {
          show = false
        }
      } else {
        if (
          this.containsOnly(['CARD_ECOM_KB'], this.orders) &&
          this.orders.length === 1
        ) {
          show = false
        }
        if (
          this.containsOnly(['CASH_ON_DELIVERY'], this.orders) &&
          this.orders.length === 1
        ) {
          show = false
        }
      }
      return show
    },
    promoText() {
      let text = ''
      if (this.isSingleOrder) {
        if (this.containsOnly(['TAXIT'], this.orders)) {
          text = ''
        }
        if (this.containsOnly(['CARD_ON_DELIVERY'], this.orders)) {
          text = this.$t('market.available_card_on_delivery')
        }
      } else {
        if (
          this.containsOnly(['TAXIT'], this.orders) &&
          this.orders.length === 1
        ) {
          text = ''
        }
        if (
          this.containsOnly(['CARD_ON_DELIVERY'], this.orders) &&
          this.orders.length === 1
        ) {
          text = this.$t('market.available_card_on_delivery')
        }
      }
      return text
    },
    routeName() {
      const match = this.$route.name.split('__')
      return match?.[0] || ''
    },
  },
  async mounted() {
    if (!this.content_checkout) {
      const dcmStore = useDcmStore()
      await dcmStore.getContentCheckout()
    }
    const content_summary = this.$refs?.CheckoutPageContentCheckoutSummary
    if (window.innerHeight - 80 < content_summary?.clientHeight) {
      content_summary.style.position = 'static'
    }
  },
  methods: {
    containsOnly(arr1, arr2) {
      return !arr2?.some(elem => !arr1?.includes(elem))
    },
  },
}
