
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'
export default {
  name: 'CallCenter',
  setup() {
    const uiStore = useUiStore()
    const { isCallCenterDropdownOpen: isOpen, resolution } =
      storeToRefs(uiStore)
    return { uiStore, isOpen, resolution }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$emit('open')
        document.addEventListener(
          'click',
          () => {
            this.isOpen = false
          },
          { once: true },
        )
      } else this.$emit('close')
    },
  },
  methods: {
    onClickButton() {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'support_915_click',
        })
      }
      if (!this.isOpen) {
        const isMobile =
          this.resolution === 'sm' ||
          this.resolution === 'md' ||
          this.resolution === 'lg'
        if (!isMobile) {
          this.uiStore.clearFadeMain()
        } else {
          this.uiStore.clearBurgerMenu()
        }

        setTimeout(() => (this.isOpen = true), 30)
      }
    },
  },
}
