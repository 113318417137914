import { useCheckoutStore } from '@/stores/checkout'

export default {
  checkFirstCredit: state => {
    // /* eslint-disable */
    // return false
    return (
      state?.credit_user_balance &&
      Object.keys(state?.credit_user_balance)?.length === 0 &&
      Object.getPrototypeOf(state?.credit_user_balance) === Object.prototype
    )
  },
  isCreditCheckout: () => {
    return route => {
      return route?.query?.mode === 'CART_LOAN'
    }
  },
  minCreditLimit: state => {
    return () => {
      const checkoutStore = useCheckoutStore()
      const minLimit = checkoutStore.defaultSettings?.find(
        item => item.code === 'MIN_CREDIT_LIMIT',
      )
      return (
        minLimit?.value?.number > state.credit_user_balance?.avail_limit_max
      )
    }
  },
}
