import { defineStore } from 'pinia'
import actions from './actions'
import getters from './getters'
import state from './state'
export const useCheckoutStoreV3 = defineStore('checkoutV3', {
  id: 'checkoutV3',
  actions,
  getters,
  state,
})
