
export default {
  name: 'CRModal',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 910,
    },
    disableClose: {
      type: Boolean,
      default: false,
    },
    hasSimilars: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible(val) {
      if (!process.client) return
      const handle = e => {
        e.stopPropagation()
      }
      if (val) {
        document.body.classList.add('overflow-hidden')
        document.body.classList.add('CRModal-Open')
        document.body.addEventListener('touchmove', handle)
      } else {
        document.body.classList.remove('overflow-hidden')
        document.body.classList.remove('CRModal-Open')
        document.body.removeEventListener('touchmove', handle)
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.keydownHandler)
  },
  destroyed() {
    document.removeEventListener('keydown', this.keydownHandler)
  },
  methods: {
    keydownHandler(e) {
      if (this.visible && e.keyCode === 27) {
        this.close()
      }
    },
    close() {
      this.$emit('closed')
    },
  },
}
