
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'
export default {
  name: 'ProductCategories',
  setup() {
    const uiStore = useUiStore()
    const { isProductsMenuOpen: isOpen, resolution } = storeToRefs(uiStore)
    return { uiStore, isOpen, resolution }
  },
  computed: {
    isSearchVisible() {
      return this.uiStore.isSearchVisible || !this.isSearchButtonVisible
    },
    isSearchButtonVisible() {
      return (
        this.resolution === 'xs' ||
        this.resolution === 'sm' ||
        this.resolution === 'md'
      )
    },
    isMobile() {
      return (
        this.resolution === 'sm' ||
        this.resolution === 'md' ||
        this.resolution === 'lg'
      )
    },
  },
  methods: {
    onClickButton() {
      if ((!this.isOpen && !this.isMobile) || this.uiStore.isHamburgerOpen) {
        this.uiStore.clearFadeMain()
      }
      this.isOpen = !this.isOpen
    },
  },
}
