
import { mapGetters } from 'vuex'
import { mapActions, storeToRefs } from 'pinia'
import { useUiStore } from '@/stores/ui'
import { useLoyaltyStore } from '~/stores/loyalty'
import { useSearchHistoryStore } from '~/stores/search_history'
import { useCustomer } from '~/stores/customer'
import { useDcmStore } from '@/stores/dcm'
import {
  WHITELISTED_PATHS,
  WHITELISTED_PATHS_ANDROID,
  WHITELISTED_PATHS_IOS,
} from '@/constants'

export default {
  name: 'AppHeader',
  props: {
    boxed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uiStore = useUiStore()
    const searchHistoryStore = useSearchHistoryStore()
    const dcmStore = useDcmStore()
    const { resolution, quickSearchExpanded } = storeToRefs(uiStore)
    return {
      searchHistoryStore,
      uiStore,
      dcmStore,
      resolution,
      quickSearchExpanded,
    }
  },
  async fetch() {
    if (this.$device.isAndroid || this.$device.isIos) {
      this.uiStore.showBannerBonusBank = this.pathMatchesWhitelist()
    } else {
      this.uiStore.showBannerBonusBank = false
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      loggedInUser: 'loggedInUser',
    }),

    isSearchVisible() {
      return this.uiStore.isSearchVisible || !this.isSearchButtonVisible
    },
    isSearchButtonVisible() {
      return (
        this.resolution === 'xs' ||
        this.resolution === 'sm' ||
        this.resolution === 'md'
      )
    },
  },

  async mounted() {
    if (!this.loggedInUser && this.isAuthenticated) {
      await this.getUser()
      await this.getCard()
    }
    if (!this.dcmStore.headerB2bForm) {
      await this.dcmStore.getHeaderB2bForm()
    }
  },
  methods: {
    ...mapActions(useCustomer, ['getUser']),
    ...mapActions(useLoyaltyStore, ['getCard']),
    pathMatchesWhitelist() {
      const url = this.$route.fullPath || ''

      if (
        url.includes('fromBB') ||
        url.includes('bbpay_user_id') ||
        this.uiStore?.fromBB
      ) {
        return false
      }
      const WHITELISTED_PATHS = this.$device.isIos
        ? WHITELISTED_PATHS_IOS
        : this.$device.isAndroid
        ? WHITELISTED_PATHS_ANDROID
        : []
      return WHITELISTED_PATHS.some(path => {
        const escapedPath = path.replace(/[.+?^${}()|[\]\\]/g, '\\$&')

        const pathRegex = new RegExp(`^${escapedPath.replace(/\*/g, '.*')}$`)
        return pathRegex.test(this.$route.path)
      })
    },
  },
}
