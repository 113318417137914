import { useUiStore } from '~/stores/ui'
import { storeToRefs } from 'pinia'
import { useContext } from '@nuxtjs/composition-api'

export default {
  __name: 'BirKartPopup',
  setup(__props) {

const { app, $auth } = useContext()
const links = {
  az: 'https://birkart.umico.az',
  en: 'https://birkart.umico.az/en',
  ru: 'https://birkart.umico.az/ru',
}
const uiStore = useUiStore()
const { isBirkartPopupVisible } = storeToRefs(uiStore)

const close = () => {
  $auth.$storage.setUniversal('isBirkartPopupVisible', false)
  uiStore.isBirkartPopupVisible = false
}

const openLink = () => {
  $auth.$storage.setUniversal('isBirkartPopupVisible', false)
  uiStore.isBirkartPopupVisible = false
  window.open(links[app.i18n.locale], '_blank')
}

return { __sfc: true,app, $auth, links, uiStore, isBirkartPopupVisible, close, openLink }
}

}