
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'
export default {
  name: 'LayoutMarket',
  setup() {
    const uiStore = useUiStore()
    const { resolution } = storeToRefs(uiStore)
    return { uiStore, resolution }
  },
  head() {
    const hasPage = !!this.$route?.query?.page
    const canonical = {
      hid: 'canonical',
      rel: 'canonical',
      href: this.$config.BASE_URL + this.$route.path,
    }
    return {
      title: this.$t('site_title'),
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        this.$i18n.locale === 'en'
          ? {
              name: 'robots',
              content: 'noindex, nofollow',
            }
          : '',
        {
          hid: 'description',
          name: 'description',
          content: this.$t('site_description'),
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: this.$t('site_title'),
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('site_title'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('site_description'),
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: 'https://umico.az/umico-og-image.jpg',
        },
      ],
      link: [!hasPage ? canonical : {}],
    }
  },
  computed: {
    isSearchVisible() {
      return this.uiStore.isSearchVisible || !this.isSearchButtonVisible
    },
    isSearchButtonVisible() {
      return (
        this.resolution === 'xs' ||
        this.resolution === 'sm' ||
        this.resolution === 'md'
      )
    },
  },
}
