
import { mapGetters } from 'vuex'
import { mapWritableState } from 'pinia'
import { useCustomer } from '~/stores/customer'
import { useMarketStore } from '~/stores/market'

let getCartTimeout
export default {
  name: 'CartLink',
  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    return {
      marketStore,
      customerStore,
    }
  },
  data() {
    return {
      start_watch_update_cart: false,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    ...mapWritableState(useCustomer, ['userProfile']),
    cartCount() {
      return this.marketStore.cartCount
    },
  },
  async mounted() {
    getCartTimeout = setTimeout(async () => {
      if (!this.$route.path.includes('/cart')) {
        // this Actions is in cart index page
        await this.marketStore.getCart()
      }
      this.start_watch_update_cart = true
    }, 1500)
  },
  beforeDestroy() {
    clearTimeout(getCartTimeout)
  },
}
