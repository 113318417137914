import { render, staticRenderFns } from "./pages-layout-static.vue?vue&type=template&id=676250a1&scoped=true"
import script from "./pages-layout-static.vue?vue&type=script&lang=js"
export * from "./pages-layout-static.vue?vue&type=script&lang=js"
import style0 from "./pages-layout-static.vue?vue&type=style&index=0&id=676250a1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "676250a1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderAppHeader: require('/usr/src/app/components/Header/AppHeader.vue').default,PagesNav: require('/usr/src/app/components/Pages/Nav.vue').default,LandingFooterBlock: require('/usr/src/app/components/Landing/FooterBlock.vue').default,CommonMainOverlay: require('/usr/src/app/components/Common/MainOverlay.vue').default,UserAgreements: require('/usr/src/app/components/UserAgreements/UserAgreements.vue').default,UIBirKartPopup: require('/usr/src/app/components/UI/BirKartPopup.vue').default,UIDebugBtn: require('/usr/src/app/components/UI/DebugBtn.vue').default})
