const orderPackageOriginal = {
  manualAddress: false,
  submitted: false,
  payment_method: null,
  token_id: null,
  shipping: {
    geocodedAddress: {},
    method: null,
    point_id: null,
    pudo_point_id: null,
    // Manual address
    // city: null,
    // street: null,
    // apartment: null,
    // Manual address
    latitude: null,
    longitude: null,
    address: {
      city: null,
      city_id: 1,
      street: null,
      building: null,
      apartment: null,
      note: null,
      precision: '',
      fromMap: false,
      approvedAddress: false,
      isDragged: false,
      house: null,
    },
    recipient: {
      name: null,
      surname: null,
      phone_number: null,
      email: null,
      note: null,
      fin_code: null,
    },
  },
  shipping_cost: null,
  total_amount: null,
  note: null,
  employee_code: null,
  clarification_call_needed: true,
  orders: [],
  promocode: null,
  premium_promocode: null,
  service_fees: [],
  state: null,
}
export default () => ({
  showDeliveryMethodDropdown: false,
  showPaymentMethodDropdown: false,
  orderPackage: orderPackageOriginal,
  profile_addresses: [],
  order_packages: null,
  orderRecipientForUpdate: null,
  delivery_price: null,
  order_fill_preferences: {
    payment_method: null,
    shipping_method: null,
  },
  ignore_error_address_fill_preferences: false,
  payment_methods: null,
  default_latitude: 40.37857,
  default_longitude: 49.853688,
  delivery_methods: null,
  delivery_time_frames: null,
  delivery_time_frames_pudo: null,
  deliveryToCoordinates: false,
  installments: {},
  pudos: [],
  cities_pudo: [],
  pudos_for_order: [],
  pudos_azerpoct: [],
  pudos_for_order_map: [],
  startOnShowMap: false,
  fetchCheckout: false,
  warning_merge_anonymous_cart: false,
  warning_modal_loan_application_created: false,
  warning_modal_loan_application_created_line: false,
  addressInput: '',
  dateTimeSelectCounter: 0,
  type_test_checkout_delivery_payment: 'select', // 'select' or 'list'
  cartId: null,
  userCartId: null,
  returnOldPaymentMethod: '',
  defaultSettings: null,
  auth_delivery: null,
  oldDeliveryMethod: null,
  listCitiesCurerStandard: [],
  currentCityCurerStandard: {},
  currentCityCurerStandardID: 1,
})
