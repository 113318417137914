
import vClickOutside from 'v-click-outside'
import { mapGetters, mapMutations } from 'vuex'
import { mapWritableState } from 'pinia'
import { useCatalog } from '@/stores/catalog'
import { useSearchHistoryStore } from '@/stores/search_history'
export default {
  name: 'QuickSearchBy',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const searchHistoryStore = useSearchHistoryStore()
    return {
      searchHistoryStore,
    }
  },
  data() {
    return {
      showDropdown: false,
      types: [
        {
          name: 'products',
        },
        {
          name: 'companies',
        },
      ],
    }
  },
  computed: {
    ...mapWritableState(useCatalog, ['quickSearchBy']),
    selectedTypeText() {
      const text = this.types.find(
        item => item.name === this.quickSearchBy,
      )?.name
      return this.$t(text)
    },
    selectedTypeName() {
      return this.types.find(item => item.name === this.quickSearchBy)?.name
    },
  },
  methods: {
    setSearchType({ name }) {
      this.quickSearchBy = name
      // this.$emit('update:searchBy', name)
      // this.$emit('change', name)
      this.showDropdown = false
    },
    onClickOutside() {
      this.showDropdown = false
    },
    onClick() {
      this.showDropdown = !this.showDropdown
      this.searchHistoryStore.showSearchHistoryBlock = false
    },
  },
}
