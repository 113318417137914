export default {
  selectedDeliveryMethod: state => orderId => {
    const orderMethods = state.deliveryMethods.find(
      methodObj => methodObj.orderId === orderId,
    )
    return (
      orderMethods?.orderDeliveryMethods.find(method => method.active) ?? null
    )
  },
  isCBOrder: state => orderId => {
    return Boolean(
      state.orderPackage?.orders?.find(
        order => order.id === orderId && order.type === 'CB_ORDER',
      ),
    )
  },
  getSelectedTimeFrameByOrderId: state => orderId => {
    return state.selectedDateTimeFrame[orderId] || null
  },
  selectedPaymentMethod: state => {
    return state.orderPackage?.payment_method
  },
  ecommUrl: () => {
    return (
      lang = 'az',
      orderPackageId,
      order_id,
      nextOrderId = null,
      from = null,
    ) => {
      const locale = lang === 'az' ? '' : `${lang}/`
      const queries = new URLSearchParams()
      queries.append('order_id', order_id)
      queries.append('orderPackageId', orderPackageId)
      if (nextOrderId) {
        queries.append('nextOrderId', nextOrderId)
      }
      if (from) {
        queries.append('from', from)
      }
      return {
        approve: `${
          window.location.origin
        }/${locale}payment/approve?${queries.toString()}`,
        decline: `${
          window.location.origin
        }/${locale}payment/decline?${queries.toString()}`,
        cancel: `${
          window.location.origin
        }/${locale}payment/cancel?${queries.toString()}`,
      }
    }
  },
}
