import {
  getLastTypePromoCod,
  getLastTypesPromoCod,
  serialize,
  LOG_ERROR,
} from '@/utilities'
import { useCheckoutStore } from '@/stores/checkout'
import { useCustomer } from '@/stores/customer'
export default {
  async GET_CUSTOMER_PACKAGE() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_SUBSCRIPTION_SERVICE ||
        this.$nuxt.$config.SUBSCRIPTION_SERVICE
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.SUBSCRIPTION_SERVICE
    }
    try {
      const {
        data: { data = {} },
      } = await this.$nuxt.$axios.get('/v1/customer_package')
      if (Array.isArray(data)) {
        this.subscribe_premium_promo_cod = data?.find(subscribe => {
          return (
            subscribe?.subscription?.status === 'active' ||
            subscribe?.subscription?.status === 'active_no_renewal'
          )
        })
        const plan = data?.find(item => item.id === 1)
        this.premiumSubscription.active = [
          'active',
          'active_no_renewal',
        ].includes(plan?.subscription?.status)
        this.premiumSubscription.finished_at = plan?.subscription?.finished_at
      }
      // return data
    } catch (e) {
      console.log('Error GET_CUSTOMER_PACKAGE', e)
      return {}
    }
  },

  async GET_USER_PERSONAL_PROMO_CODES() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL = (
        this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
      ).replace('/v1', '/v2')
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
    }
    const {
      data: { data = [] },
    } = await this.$nuxt.$axios.get('/promocodes-personal/user')

    this.personal_promo_code_compliment = getLastTypePromoCod(
      data,
      'COMPLIMENT',
    )
    this.personal_promo_code_subscription = getLastTypePromoCod(
      data,
      'SUBSCRIPTION',
    )

    this.personal_promo_codes =
      getLastTypesPromoCod(data, ['COMMON', 'FIRST_PURCHASE']) || []
  },
  async TOGGLE_SWITCH(options) {
    this[options.switch] = options.value
  },
  CLEANSE_PROMO_CODS() {
    this.personal_promo_code_compliment = {}
    this.personal_promo_code_subscription = {}
    this.subscribe_premium_promo_cod = {}
    this.personal_promo_codes = []
    this.has_subscription_promo_code = false
    this.has_compliment_promo_cod = false
    this.has_personal_promo_code = false
    this.cashback_promo_code_amount10 = 0
  },
  async CHECK_IS_FIRST_PURCHASE_PROMO_COD(id) {
    try {
      this.is_first_purchase_promo_code = false
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: { data = {} },
      } = await this.$nuxt.$axios
        .get(`/orders-packages/${id}/first-purchase-promocode/check`)
        .catch(error => {
          console.log('Error - CHECK_IS_FIRST_PURCHASE_PROMO_COD', error)
          this.is_first_purchase_promo_code = false
        })
      this.is_first_purchase_promo_code =
        data?.is_first_purchase_promocode_available
    } catch (error) {
      LOG_ERROR(
        this?.$rollbar,
        this.$nuxt?.$config,
        'CHECK_IS_FIRST_PURCHASE_PROMO_COD',
        error,
      )
    }
  },

  async GET_FIRST_PURCHASE_PROMO_COD(id) {
    const {
      data: { data = {} },
    } = await this.$nuxt.$axios
      .post(`/orders-packages/${id}/first-purchase-promocode`)
      .catch(error => {
        console.log('Error - GET_FIRST_PURCHASE_PROMO_COD', error)
        this.first_purchase_promo_code = {}
      })
    this.first_purchase_promo_code = data
  },
  async CHECK_PROMO_CODE(data) {
    const customerStore = useCustomer()
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const order_id = data.id
      const options = data
      delete options.id
      const orderPackageId = order_id
      const urlPackages = !customerStore.isLoggedIn
        ? `/anonymous/orders-packages/${orderPackageId}`
        : `/orders-packages/${orderPackageId}`
      let result
      try {
        result = await this.$nuxt.$axios.get(
          `${urlPackages}?${serialize(options)}`,
        )
        const CheckoutStore = useCheckoutStore()
        if (data.promocode)
          CheckoutStore.orderPackage.promocode = data.promocode
        if (data.premium_promocode)
          CheckoutStore.orderPackage.premium_promocode = data.premium_promocode
      } catch (error) {
        result = { data: error?.response }
      }
      return result
    } catch (error) {
      LOG_ERROR(
        this?.$rollbar,
        this.$nuxt?.$config,
        'CHECK_PROMO_CODE',
        error,
        data,
      )
      return { data: error?.response }
    }
  },
  async GET_ORDERS_PACKAGES_BY_ID(orderPackageId) {
    const customerStore = useCustomer()
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const urlPackages = !customerStore.isLoggedIn
        ? `/anonymous/orders-packages/${orderPackageId}`
        : `/orders-packages/${orderPackageId}`
      return await this.$nuxt.$axios.get(urlPackages)
    } catch (e) {
      LOG_ERROR(
        this?.$rollbar,
        this.$nuxt?.$config,
        'GET_ORDERS_PACKAGES_BY_ID',
        e,
        {
          orderPackageId,
        },
      )
    }
  },

  async getSubscriptionBenefits() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_SUBSCRIPTION_SERVICE ||
        this.$nuxt.$config.SUBSCRIPTION_SERVICE
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.SUBSCRIPTION_SERVICE
    }
    try {
      const {
        data: { data = {} },
      } = await this.$nuxt.$axios.get('/v1/benefit')
      this.premiumSubscriptionBenefits = data
    } catch (e) {
      console.log()
    }
  },

  SET_DATA_LAYER_HAS_SUBSCRIPTIOON_PROMO_CODE(value) {
    this.has_subscription_promo_code_data_layer = value
  },
  SET_DATA_LAYER_HAS_PERSONAL_PROMO_CODE_SUBSCRIPTION(value) {
    this.personal_promo_code_subscription_data_layer = value
  },
  SET_DATA_LAYER_PERSONAL_PROMO_CODE(value) {
    this.personal_promo_code_data_layer = value
  },
}
