
import seoMixin from '@/mixins/seo'
import { useUiStore } from '@/stores/ui'
export default {
  name: 'LayoutNonLogged',
  mixins: [seoMixin],
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  head() {
    const hasPage = !!this.$route?.query?.page
    const canonical = {
      hid: 'canonical',
      rel: 'canonical',
      href: this.$config.BASE_URL + this.$route.path,
    }
    return {
      title: this.$t('site_title'),
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('site_description'),
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: this.$t('site_title'),
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('site_title'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('site_description'),
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: 'https://umico.az/umico-og-image.jpg',
        },
      ],
      link: [
        {
          rel: 'alternate',
          href: this.$config.BASE_URL + this.$route.fullPath,
          hreflang: this.$i18n?.locale || '',
        },
        {
          rel: 'alternate',
          href: this.getAlternateHref(),
          hreflang: this.getHrefLang(),
        },
        !hasPage ? canonical : {},
      ],
    }
  },
}
