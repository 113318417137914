import { useCustomer } from '@/stores/customer'

export default {
  isAuthenticated() {
    const customerStore = useCustomer()
    return customerStore.isLoggedIn
  },
  loggedInUser(state) {
    return state.auth.user
  },
}
