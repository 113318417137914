
import { mapWritableState, storeToRefs } from 'pinia'
import { useUiStore } from '@/stores/ui'
import { useSearchHistoryStore } from '~/stores/search_history'
export default {
  name: 'MainOverlay',
  setup() {
    const searchHistoryStore = useSearchHistoryStore()
    const uiStore = useUiStore()
    const { fadeMain } = storeToRefs(uiStore)
    return {
      searchHistoryStore,
      uiStore,
      fadeMain,
    }
  },
  computed: {
    ...mapWritableState(useSearchHistoryStore, ['showSearchHistoryBlock']),
  },
  watch: {
    fadeMain(val) {
      if (!process.client) return
      const handle = e => {
        // e.preventDefault();
        e.stopPropagation()
      }
      if (val) {
        document.body.classList.add('overflow-hidden')
        document.body.addEventListener('touchmove', handle)
      } else {
        document.body.classList.remove('overflow-hidden')
        document.body.removeEventListener('touchmove', handle)
      }
    },
  },
  beforeDestroy() {
    if (!process.client) return
    document.body.classList.remove('overflow-hidden')
  },
  methods: {
    onClick() {
      this.uiStore.clearFadeMain()
      this.searchHistoryStore.showSearchHistoryBlock = false
    },
  },
}
