import { defineStore } from 'pinia'
import actions from './actions'
import getters from './getters'
import state from './state'

export const useEventDataLayer = defineStore({
  id: 'event_data_layer',
  actions,
  getters,
  state,
})
