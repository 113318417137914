import { defineStore } from 'pinia'
import actions from './actions'
import getters from './getters'
import state from './state'

export const useCheckoutCredit = defineStore({
  id: 'checkout_credit',
  actions,
  getters,
  state,
})
