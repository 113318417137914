
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'HeaderUser',
  props: {
    noTransition: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      loggedInUser: 'loggedInUser',
    }),
  },
}
