
import { mapGetters } from 'vuex'
import { useUiStore } from '@/stores/ui'
import { useDcmStore } from '@/stores/dcm'
import { storeToRefs } from 'pinia'

export default {
  name: 'HamburgerDropdown',
  setup() {
    const uiStore = useUiStore()
    const dcmStore = useDcmStore()
    const { headerB2bForm } = storeToRefs(dcmStore)
    return {
      uiStore,
      headerB2bForm,
    }
  },
  data() {
    return {
      geoVisibility: false,
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      loggedInUser: 'loggedInUser',
    }),
    loggedIn() {
      return this.isAuthenticated && this.loggedInUser
    },
    sellOnUmicoMarket() {
      return `https://business.umico.az${
        this.$i18n.locale === 'az' ? '' : '/ru'
      }`
    },
  },
}
