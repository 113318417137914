export default () => ({
  hoveredMenuCategory: null,
  loadedMenuCategory: null,
  azerpoctPudoCities: [],
  faqPudosList: [],
  pudoListLoading: false,
  merchantName: null,
  rateSuccessDialogProduct: null,
  rateArgumentsFilter: { min: null, max: null },
  preOrderPorduct: {
    name: '',
  },
  availablePopupData: null,
  address: null,
  availablePopupProduct: null,
  bulkPurchasePopupData: null,
  showPagination: 20,
  showPreOrderConfirmPopup: false,
  showMobileCartProduct: {},
  profileAddresses: [],
  orderFillPreferences: {},
  productsBestsellers: [],
  currentCategoryName: null,
  productTagsLink: null,
  listRatingCartOffersStock: {},
  saleDayProductsAll: [],
  minOrderAmount: null,
  repeatOrderInfo: null,
  order: null,
  orders: [],
  ordersPagination: null,
  popularCategoriesList: [],
  paymentCards: [],
  productDiscountQuantity: 1,
  productDiscountPrice: null,
  productQtyDiscountRule: null,
  productQtyDiscountRuleItems: [],
  tag: {},
  brand: {},
  partnersMerchantOfficeSchedule: null,
  megaDiscountProducts: [],
  newItemsPopularMainPage: [],
  saleDayProducts: [],
  saleDayProductsMeta: [],
  seoTags: [],
  merchantPaymentMethods: [],
  listOffersPaymentMethods: {},
  last_pudo: null,
  pudoCityId: null,
  pudo_cities: [],
  pudos_all: null,
  listOffersReviews: [],
  companyReviews: null,
  companyRating: {},
  mainTopSeoTags: [],
  productSeeAlso: [],
  stockOffersProducts: [],
  productsById: [],
  personalRecommendations: [],
  personalRecommendationsProducts: [],
  alternativeProductsCart: [],
  alternativeProductsFavorites: [],
  favoriteProducts: [],
  anonFavoriteList: [],
  anonDataLayerFavoriteList: {},
  favoriteUUIDS: [],
  favorites: {},
  favoritesCount: null,
  categories: [],
  selfCategory: {},
  listProductCategories: [],
  breadcrumbs: [],
  seoFaqData: null,
  categoryShortcuts: [],
  variantCodeProducts: [],
  cartSellProductsIds: [],
  cartOffersProducts: [],
  cartSellProducts: [],
  productCategories: {},
  landingProductCategories: {},
  productCategoriesBreadcrumbs: [],
  truncated: true,
  productFilters: {},
  productFiltersCategory: '',
  moreProductOptions: {},
  moreProductPage: 0,
  loadingProducts: false,
  lastCheckedFilter: null,
  filters: {},
  localFilters: {},
  resetLocalFilters: false,
  products: [],
  pagination: {
    page: 1,
    per_page: 16,
    total_entries: 0,
    total_pages: 0,
  },
  popularProducts: [],
  product_other_offers: null,
  sizeChart: {},
  sizeChartAvailable: false,
  updatePaymentMethods: false,
  product: null,
  cartUUIDS: [],
  listImgZoomSlider: { listSlider: [] },
  cart: null,
  cartCount: 0,
  oldAnonymousCart: null,
  redirectCreditFromSingleId: false,
  customerForOrder: null,
  showOrdersCustomerForOrder: true,
  orderPackages: null,
  lockButtonQuantity: false,
  warningModalLoanApplicationCreated: false, // not used
  ordersForUpdate: {
    profile: {
      delivery: {
        address: {
          city_id: 1,
          city: '',
          street: '',
          building: '',
          apartment: '',
          precision: '',
          address_lat: '',
          address_lng: '',
          pudo: '',
          pudo_lat: '',
          pudo_lng: '',
          fromMap: false,
          isDragged: false,
        },
      },
    },
  },
  productsSortBy: 'id desc',
  addedProduct: {},
  orderRecipientForUpdate: {
    name: '',
    surname: '',
    phone_number: '',
    email: '',
    note: '',
    imei_code: '',
  },
  deliveryPrice: {},
  loanApplication: null,
  orderDeliveryTariffs: {},
  orderDeliveryTariffsAzerpoct: {},
  orderOtp: null,
  orderTrackingStates: [],
  orderCancelReasons: [],
  compareCount: null,
  compareUuids: [],
  compare: [],
  assessment: {},
  viewedProducts: [],
  blockScrollFavoritesProducts: false,
  sourceSelectItem: null,
  tabOffers: null,
  productVideoId: null,
  currentIndexProduct: null,
  // default_category_mega_discounts
  defaultCategoryMegaDiscounts: 1,
  minInstallmentAmount: 0,
  customerOrdersCount: 0,
  productTTLContent: {
    id: null,
    data: null,
  },
  result_query: null,
  PopularNowProducts: [],
  anonymous_old_cart_amount: null,
})
