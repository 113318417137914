export default {
  approveUrl() {
    return (orderPackageId, order_id, nextOrderId = null, lang, from) => {
      const locale = lang === 'az' ? '' : `${lang}/`
      const queries = new URLSearchParams('')
      queries.append('order_id', order_id)
      queries.append('orderPackageId', orderPackageId)
      if (nextOrderId) {
        queries.append('nextOrderId', nextOrderId)
      }
      if (from) {
        queries.append('from', from)
      }
      return `${
        window.location.origin
      }/${locale}payment/approve?${queries.toString()}`
    }
  },
  isCBOrder() {
    return () => {
      return Boolean(
        this.order_packages?.orders?.find(order => order.type === 'CB_ORDER'),
      )
    }
  },
  declineUrl() {
    return (orderPackageId, order_id, nextOrderId, lang, from) => {
      const locale = lang === 'az' ? '' : `${lang}/`
      const queries = new URLSearchParams('')
      queries.append('order_id', order_id)
      queries.append('orderPackageId', orderPackageId)
      if (nextOrderId) {
        queries.append('nextOrderId', nextOrderId)
      }
      if (from) {
        queries.append('from', from)
      }
      return `${
        window.location.origin
      }/${locale}payment/decline?${queries.toString()}`
    }
  },
  cancelUrl() {
    return (orderPackageId, order_id, nextOrderId, lang, from) => {
      const locale = lang === 'az' ? '' : `${lang}/`
      const queries = new URLSearchParams('')
      queries.append('order_id', order_id)
      queries.append('orderPackageId', orderPackageId)
      if (nextOrderId) {
        queries.append('nextOrderId', nextOrderId)
      }
      if (from) {
        queries.append('from', from)
      }
      return `${
        window.location.origin
      }/${locale}payment/cancel?${queries.toString()}`
    }
  },
  orders: state => state.orderPackage?.orders || [],
  orderPackageId: state => state.order_packages?.id,
  isDeliveryMethod(state) {
    return method => state.orderPackage.shipping.method === method
  },
  orderDeliveryTimeFrameDates(state) {
    return orderId => state.delivery_time_frames?.[orderId] || []
  },
  setDateForOrder(state) {
    return (date, id) => {
      const order = state.orderPackage?.orders.find(order => order.id === id)
      order.shipping.date = date
      order.shipping.time_frame = null
    }
  },
  setTimeForOrder(state) {
    return (code, start_time, id) => {
      // const order = state.orderPackage?.orders.find(order => order.id === id)
      // order.shipping.time_frame = code

      const order = state.orderPackage?.orders.find(order => order.id === id)
      const date = order.shipping.date
      const ordersHaveTimeFrame = Object.keys(
        state.delivery_time_frames,
      ).filter(key => {
        return (
          Object.keys(state.delivery_time_frames[key]).includes(date) &&
          state.delivery_time_frames[key][date].some(
            item => item.start_time === start_time,
          )
        )
      })
      if (state.dateTimeSelectCounter === 0 && ordersHaveTimeFrame.length > 1) {
        const targetOrders = state.orderPackage?.orders.filter(order => {
          return ordersHaveTimeFrame.includes(order.id)
        })
        targetOrders.forEach(order => {
          const timeFrames = state.delivery_time_frames[order.id]?.[date] || []
          const timeFrame = timeFrames.find(
            item => item.date === date && item.start_time === start_time,
          )
          order.shipping.date = date
          order.shipping.time_frame = timeFrame ? timeFrame.code : null
        })
        state.dateTimeSelectCounter += 1
      } else {
        const order = state.orderPackage?.orders.find(order => order.id === id)
        order.shipping.time_frame = code
        state.dateTimeSelectCounter += 1
      }
    }
  },
  getCostDelivery(state) {
    return () => {
      if (state.orderPackage.payment_method === 'LOAN_ON_DELIVERY') {
        const get_fee_credit = state?.orderPackage?.service_fees.find(
          method => method?.code === 'LOAN_SVC_FEE',
        )
        const get_fee_delivery = state?.orderPackage?.service_fees.find(
          method => method?.code === 'DELIVERY_FEE',
        )
        const creditAmount =
          get_fee_credit?.calculation_status === 'CALCULATED' &&
          get_fee_credit?.amount > 0
            ? get_fee_credit?.amount
            : 0
        const deliveryAmount =
          get_fee_delivery?.calculation_status === 'CALCULATED' &&
          get_fee_delivery?.amount > 0
            ? get_fee_delivery?.amount
            : 0
        return creditAmount + deliveryAmount
      } else {
        const current_delivery = state?.orderPackage?.service_fees?.find(
          method => {
            return method?.code === state.orderPackage?.shipping.method
          },
        )
        if (!current_delivery?.fee) return 0
        const get_fee = current_delivery?.fee?.find(item => {
          return item.code === 'DELIVERY_FEE'
        })
        return get_fee?.calculation_status === 'CALCULATED' &&
          get_fee?.amount > 0
          ? get_fee?.amount
          : 0
      }
    }
  },
  getPaymentMethodExceptions(state) {
    return () => {
      return (
        state.delivery_methods?.find(
          method => method.code === state.orderPackage.shipping.method,
        )?.payment_method_exceptions || []
      )
    }
  },
  unavailableMethodsDelivery(state) {
    const { orderPackage } = state
    return () => {
      const currentDeliveryMethod =
        (state.delivery_methods &&
          state.delivery_methods.find(
            method => method.code === orderPackage?.shipping?.method,
          )) ||
        {}
      const ordersPaymentMethod =
        orderPackage?.payment_method === 'LOAN_ON_DELIVERY'
          ? 'LOAN_SVC_FEE'
          : 'DELIVERY_FEE'
      const currentPaymentMethod = currentDeliveryMethod?.fee?.find(
        payment_method => payment_method.code === ordersPaymentMethod,
      )

      return currentPaymentMethod?.calculation_status === 'UNAVAILABLE'
    }
  },

  checkCalculateMethodsDeliver(state) {
    const { orderPackage } = state
    return () => {
      const currentDeliveryMethod =
        (state.delivery_methods &&
          state.delivery_methods.find(
            method => method.code === orderPackage?.shipping?.method,
          )) ||
        {}
      const ordersPaymentMethod =
        orderPackage?.payment_method === 'LOAN_ON_DELIVERY'
          ? 'LOAN_SVC_FEE'
          : 'DELIVERY_FEE'
      const currentPaymentMethod = currentDeliveryMethod?.fee?.find(
        payment_method => payment_method.code === ordersPaymentMethod,
      )

      return currentPaymentMethod?.calculation_status !== 'CALCULATED'
    }
  },

  amountForPayment(state) {
    return () => {
      let totalAmount = 0
      state.orderPackage?.orders?.forEach(order => {
        totalAmount += order.total_amount
      })
      totalAmount += state.getCostDelivery()

      return Number(totalAmount).toFixed(2) || 0
    }
  },
}
