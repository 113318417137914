
import { useCatalog } from '@/stores/catalog'

export default {
  name: 'CategoriesMenu',
  setup() {
    const catalogStore = useCatalog()

    return { catalogStore }
  },
}
