
import { useUiStore } from '@/stores/ui'

export default {
  name: 'FooterBlock',
  props: {
    hideBottom: {
      type: Boolean,
      default: false,
    },
    boxed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  methods: {
    scroll(el) {
      if (!(this.$router.currentRoute.path === '/')) {
        try {
          this.$router.push({
            path: this.localePath('index'),
            hash: el.replace('#', ''),
          })
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') throw error
        }
      } else {
        document.getElementById(el).scrollIntoView()
      }
    },
  },
}
