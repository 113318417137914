export default function ({ app }) {
  const exponeaScript = document.createElement('script')
  exponeaScript.type = 'text/javascript'
  exponeaScript.async = true
  exponeaScript.innerHTML = `
    !(function (e, n, t, i, r, o) {
  function s(e) {
    if (typeof e != 'number') return e
    var n = new Date()
    return new Date(n.getTime() + 1e3 * e)
  }
  var a = 4e3
  var c = 'xnpe_async_hide'
  function p(e) {
    return e.reduce(
      function (e, n) {
        return (
          (e[n] = function () {
            e._.push([n.toString(), arguments])
          }),
          e
        )
      },
      { _: [] },
    )
  }
  function m(e, n, t) {
    var i = t.createElement(n)
    i.src = e
    var r = t.getElementsByTagName(n)[0]
    return r.parentNode.insertBefore(i, r), i
  }
  function u(e) {
    return Object.prototype.toString.call(e) === '[object Date]'
  }
  ;(o.target = o.target || 'https://api.exponea.com'),
    (o.file_path = o.file_path || o.target + '/js/exponea.min.js'),
    (r[n] = p([
      'anonymize',
      'initialize',
      'identify',
      'getSegments',
      'update',
      'track',
      'trackLink',
      'trackEnhancedEcommerce',
      'getHtml',
      'showHtml',
      'showBanner',
      'showWebLayer',
      'ping',
      'getAbTest',
      'loadDependency',
      'getRecommendation',
      'reloadWebLayers',
      '_preInitialize',
      '_initializeConfig',
    ])),
    (r[n].notifications = p([
      'isAvailable',
      'isSubscribed',
      'subscribe',
      'unsubscribe',
    ])),
    (r[n].segments = p(['subscribe'])),
    (r[n].snippetVersion = 'v2.7.0'),
    (function (e, n, t) {
      ;(e[n]['_' + t] = {}),
        (e[n]['_' + t].nowFn = Date.now),
        (e[n]['_' + t].snippetStartTime = e[n]['_' + t].nowFn())
    })(r, n, 'performance'),
    (function (e, n, t, i, r, o) {
      e[r] = {
        sdk: e[i],
        sdkObjectName: i,
        skipExperiments: !!t.new_experiments,
        sign: t.token + '/' + (o.exec(n.cookie) || ['', 'new'])[1],
        path: t.target,
      }
    })(r, e, o, n, i, RegExp('__exponea_etc__' + '=([\\\\w-]+)')),
    (function (e, n, t) {
      m(e.file_path, n, t)
    })(o, t, e),
    (function (e, n, t, i, r, o, p) {
      if (e.new_experiments) {
        !0 === e.new_experiments && (e.new_experiments = {})
        var l
        var f = e.new_experiments.hide_class || c
        var _ = e.new_experiments.timeout || a
        var g = encodeURIComponent(o.location.href.split('#')[0])
        e.cookies &&
          e.cookies.expires &&
          (typeof e.cookies.expires == 'number' || u(e.cookies.expires)
            ? (l = s(e.cookies.expires))
            : e.cookies.expires.tracking &&
              (typeof e.cookies.expires.tracking == 'number' ||
                u(e.cookies.expires.tracking)) &&
              (l = s(e.cookies.expires.tracking))),
          l && l < new Date() && (l = void 0)
        var d =
          e.target +
          '/webxp/' +
          n +
          '/' +
          o[t].sign +
          '/modifications.min.js?http-referer=' +
          g +
          '&timeout=' +
          _ +
          'ms' +
          (l ? '&cookie-expires=' + Math.floor(l.getTime() / 1e3) : '')
        e.new_experiments.mode === 'sync' &&
        o.localStorage.getItem('__exponea__sync_modifications__')
          ? (function (e, n, t, i, r) {
              ;(t[r][n] = '<' + n + ' src="' + e + '"></' + n + '>'),
                i.writeln(t[r][n]),
                i.writeln(
                  '<' +
                    n +
                    '>!' +
                    r +
                    '.init && document.writeln(' +
                    r +
                    '.' +
                    n +
                    '.replace("/' +
                    n +
                    '/", "/' +
                    n +
                    '-async/").replace("><", " async><"))</' +
                    n +
                    '>',
                )
            })(d, n, o, p, t)
          : (function (e, n, t, i, r, o, s, a) {
              o.documentElement.classList.add(e)
              var c = m(t, i, o)
              function p() {
                r[a].init ||
                  m(t.replace('/' + i + '/', '/' + i + '-async/'), i, o)
              }
              function u() {
                o.documentElement.classList.remove(e)
              }
              ;(c.onload = p),
                (c.onerror = p),
                r.setTimeout(u, n),
                (r[s]._revealPage = u)
            })(f, _, d, n, o, p, r, t)
      }
    })(o, t, i, 0, n, r, e),
    (function (e, n, t) {
      var i
      e[n]._initializeConfig(t),
        ((i = t.experimental) === null || void 0 === i
          ? void 0
          : i.non_personalized_weblayers) && e[n]._preInitialize(t),
        (e[n].start = function (i) {
          i &&
            Object.keys(i).forEach(function (e) {
              return (t[e] = i[e])
            }),
            e[n].initialize(t)
        })
    })(r, n, o)
})(document, 'exponea', 'script', 'webxpClient', window, {
      target: 'https://api.eu1.exponea.com',
      token: '${app.$config.BLOOMREACH_TOKEN}',
      experimental: {
        non_personalized_weblayers: true,
      },
       push: {
        safari: {
            websitePushID: '${app.$config.BLOOMREACH_WEBSITE_PUSHID}',
        },
    },
      track: {
        visits: false,
        google_analytics: false,
      },
})
exponea.start({
  ping: {
    activity: true
  },
  cookies: {
      cross_subdomain: false
      }
})
  `

  // Append the script to the head of the document
  document.head.appendChild(exponeaScript)

  app.head.__dangerouslyDisableSanitizers =
    app.head.__dangerouslyDisableSanitizers || []
  app.head.__dangerouslyDisableSanitizers.push('script')
}
