
export default {
  name: 'Arrow',
  props: {
    degree: {
      type: [Number, String],
      default: 0,
    },
    isLoyalty: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 10,
    },
    color: {
      type: String,
      default: '#A5A8B1',
    },
  },
  computed: {
    rotate() {
      return `rotate(${this.degree}deg)`
    },
  },
}
