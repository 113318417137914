import { useCatalog } from '@/stores/catalog'
import { useUiStore } from '@/stores/ui'

export default function ({ store, from }) {
  const uiStore = useUiStore()
  const catalogStore = useCatalog()
  catalogStore.toggleProfileDropdown(false)
  uiStore.isHamburgerOpen = false
  // store.commit('ui/CLEAR_FADE_MAIN', false)
  uiStore.clearFadeMain()
  setTimeout(() => {
    uiStore.isProductsMenuOpen = false
  }, 10)
  if (process.client) {
    uiStore.prevRoute = from
    // store.commit('ui/SET_PREV_ROUTE', from)
  }
  // TODO: Reset action for all
}
