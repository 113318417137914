
import { CATEGORY_ICON } from '@/constants'
import { slugify } from '@/utilities'
export default {
  name: 'CategoriesItem',
  props: {
    category: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    url() {
      return {
        name: 'category-slug',
        params: { slug: `${this.category.id}-${slugify(this.name)}` },
      }
    },
    src() {
      const id = (this.category && this.category.id) || ''
      const src = CATEGORY_ICON[id] || ''
      return src ? `/images/categories/${src}.svg` : ''
    },
    name() {
      return (
        (this.category &&
          this.category.attributes &&
          this.category.attributes.name) ||
        ''
      )
    },
  },
}
