
export default {
  name: 'SlideUpDownBlock',
  props: {
    isLoyalty: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
    mobileWidth: false,
  }),
  mounted() {
    if (!this.isLoyalty) {
      this.mobileWidth = window.innerWidth < 992
      window.addEventListener('resize', () => {
        this.mobileWidth = window.innerWidth < 992
      })
    } else {
      this.mobileWidth = true
    }
  },
}
