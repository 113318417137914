export default {
  head() {
    return {
      link: [
        {
          rel: 'alternate',
          href: this.$config.BASE_URL + this.$route.fullPath,
          hreflang: this.$i18n?.locale || '',
        },
        {
          rel: 'alternate',
          href: this.getAlternateHref(),
          hreflang: this.getHrefLang(),
        },
      ],
    }
  },
  methods: {
    getHrefLang() {
      return this.$i18n?.locale === 'az' ? 'ru' : 'az'
    },
    getAlternateHref() {
      const lang = this.$i18n?.locale || 'az'
      const alternateLang = this.$i18n?.locale === 'az' ? 'ru' : 'az'
      const fullPath = this.$route.fullPath != '/' ? this.$route.fullPath : ''
      let newPath = ''
      if (alternateLang === 'az' && fullPath.includes('/' + lang)) {
        newPath = fullPath.replace('/' + lang, '')
      } else if (alternateLang != 'az' && fullPath.includes('/' + lang)) {
        newPath = fullPath.replace('/' + lang, '/' + alternateLang)
      } else if (alternateLang != 'az' && !fullPath.includes('/' + lang)) {
        newPath = '/' + alternateLang + fullPath
      } else newPath + '/' + alternateLang

      return this.$config.BASE_URL + newPath
    },
  },
}
