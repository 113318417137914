const orderPackageOriginal = {
  manualAddress: false,
  submitted: false,
  payment_method: null,
  token_id: null,
  shipping: {
    geocodedAddress: {},
    method: null,
    point_id: null,
    pudo_point_id: null,
    // Manual address
    // city: null,
    // street: null,
    // apartment: null,
    // Manual address
    latitude: null,
    longitude: null,
    address: {
      city: null,
      city_id: 1,
      street: null,
      building: null,
      apartment: null,
      note: null,
      precision: '',
      fromMap: false,
      approvedAddress: false,
      isDragged: false,
      house: null,
    },
    recipient: {
      name: null,
      surname: null,
      phone_number: null,
      email: null,
      note: null,
      fin_code: null,
    },
  },
  shipping_cost: null,
  total_amount: null,
  note: null,
  employee_code: null,
  clarification_call_needed: true,
  orders: [],
  promocode: null,
  premium_promocode: null,
  service_fees: [],
  state: null,
  disclaimerDeliverySelectedPoint: false,
}
export default () => ({
  buttonNavigation: false,
  orderPackageId: null,
  deliveryMethods: [],
  oldDeliveryMethod: {},
  applyToMultipleOrders: {},
  deliveryTimeFrames: {},
  getNewTimeFramesForceUpdate: false,
  userSavedAddresses: [],
  userSavedAddressesExpress: [],
  savedPudoPointsAzerpost: [],
  continueButtonDisabled: false,
  savedPudoPoints: [],
  resetAddressSelection: false,
  pudoPointsForCity: [],
  azerpoctPointsForCity: [],
  selectedDateTimeFrame: {},
  deliveryOrderErrors: [
    {
      order: {
        addressSelected: true,
        orderId: '',
        timeFrameSelected: true,
      },
    },
  ],
  fieldInvalidDataLayer: {
    name: '',
    surname: '',
    phone_number: '',
    address: '',
    delivery_time: '',
    delivery_method: '',
    fin_code: '',
    job_place: '',
    trusted_number_1: '',
    trusted_number_2: '',
    personal_data_agree: '',
  },
  deliveryInfo: [],
  firstOrderId: null,
  order_packages: {},

  orderPackage: {
    credit_allowed: false,
    id: null,
    orders: [
      {
        seller: {
          id: '',
        },
        id: '',
        delivery: {
          method: '',
          code: '',
          name: '',
        },
      },
    ],
    payment_method: null,
    state: null,
    submitted: false,
    shipping: {
      method: {
        code: '',
        description: '',
        name: '',
      },
    },
    shipping_cost: 0,
    subtotal_amount: 0,
    token_id: 0,
    total_amount: 0,
    promocode_type: '', // discount, cashback
    promocode: '',
    premium_promocode: '',
    total_promocode_cashback: 0,
    total_promocode_discount: 0,
  },
  // checkout address
  addressInput: '',
  startManualAddress: false,
  deliveryToCoordinates: false,
  manualAddress: {
    street: '',
    city: '',
  },
  startOnShowMap: false,
  geocodedAddress: {
    all_address: [],
    house: '',
    locality: '',
    apartment: '',
    name: '',
    pos: [],
    precision: '',
    street: '',
    // canSaveAddress: false,
  },
  canSaveAddress: false,
  default_latitude: 40.37857,
  default_longitude: 49.853688,
  orderAddress: {
    apartment: '',
  },
  paymentMethods: null,
  notAllowedCombinationPopup: {
    visible: false,
    type: null, // promocode, premium
  },
  loanNotAvailablePopupVisible: false,
  checkoutPageLoading: false,
  incorrectFinCodePopupVisible: false,
  minSumForLoanPopupVisible: false,
  timeFrameNotAvailable: false,
  paymentCards: [],
  loanApplication: {
    first_name: null,
    last_name: null,
    phone_number: null,
    fin_code: null,
    additional_phone_number1: null,
    additional_phone_number2: null,
    commission_amount: null,
    down_payment_amount: null,
    loan_term: null,
    monthly_commission_rate: null,
    old_monthly_commission_rate: null,
    monthly_payment_amount: null,
    product_type: null,
    transaction_amount: null,
    track_id: null,
    credit_bureau_consent: true,
  },
  loanTransactionDetails: [],
  validations: null,
  applyAddressAllOrders: true,
  recipientDetailsFormActive: false,
})
