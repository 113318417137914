
export default {
  name: 'UiSuccessRateBadge',
  props: {
    badgeClass: {
      type: String,
      default: '',
    },
    partnerRating: {
      type: Number,
      default: null,
    },
  },
  computed: {
    levelColor() {
      let background = ''
      if (this.partnerRating < 50) background = 'bg-[#F81A5D]'
      if (this.partnerRating >= 50 && this.partnerRating < 65) {
        background = 'bg-[#F81A5D]'
      }
      if (this.partnerRating >= 65 && this.partnerRating < 85) {
        background = 'bg-[#FFAB40]'
      }
      if (this.partnerRating >= 85) {
        background = 'bg-[#16C67A]'
      }
      return background
    },
  },
}
