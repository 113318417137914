import { defineStore } from 'pinia'
import actions from './actions'
import getters from './getters'
import state from './state'
export const useMarketStore = defineStore({
  id: 'market',
  getters,
  actions,
  state,
})
