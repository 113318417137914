
import { DEBUG_KEY } from '@/constants'
export default {
  name: 'DebugBtn',
  data() {
    return {
      isDebug: false,
    }
  },
  mounted() {
    this.getShowDebug()
  },
  methods: {
    getShowDebug() {
      this.isDebug = this.$cookies.get('debug' + DEBUG_KEY) == true
    },
    closeDebug() {
      this.$cookies.set('debug' + DEBUG_KEY, null)
      location.reload()
    },
  },
}
