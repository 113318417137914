import { useCustomer } from '@/stores/customer'

export default function ({ app, store, redirect }) {
  // If the user is not authenticated

  const customerStore = useCustomer()
  if (!customerStore.isLoggedIn) {
    return redirect(app.localePath('auth-authentication'))
  }
}
