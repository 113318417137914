// import CONFIG from '@/config'
import { getCityInfo, LOG_ERROR } from '~/utilities'
import { useCustomer } from '~/stores/customer'
import { useMarketStore } from '@/stores/market'
import { useUiStore } from '@/stores/ui'

const cookieparser = process.server ? require('cookieparser') : undefined
export default {
  async nuxtServerInit({ commit, getters }, { req, $axios, store, app }) {
    const url = new URLSearchParams(req?._parsedOriginalUrl?.query)
    const customerStore = useCustomer()
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    if (url?.has('fromBB')) {
      app.$auth.$storage.setUniversal('fromBB', true)
      uiStore.fromBB = true
    }

    if (process.server) {
    }

    $axios.setHeader('Content-Language', app.i18n.locale)

    if (req.headers.cookie) {
      cookieparser.maxLength = 10000
      const parsed = cookieparser.parse(req.headers.cookie)

      if (parsed && parsed?.['auth.fromBB']) {
        uiStore.fromBB = true
      }

      if (url?.has('fromBB') && url?.has('bbpay_user_id')) {
        this.$cookies.set('bbpay_user_id', url.get('bbpay_user_id'), {
          path: '/',
          maxAge: 2147483647,
        })
      }
      if (url?.has('fromBB') && url?.has('bbpay_device_id')) {
        this.$cookies.set('bbpay_device_id', url.get('bbpay_device_id'), {
          path: '/',
          maxAge: 2147483647,
        })
      }
      // TODO remove after 01_11_2024 clear all cookie
      this.$auth.$storage.setCookie('refresh_token', null, true)
      this.$auth.$storage.setCookie('nextPage', null, true)
      this.$auth.$storage.setCookie('nextPageParams', null, true)
      this.$auth.$storage.setCookie('nextPageQuery', null, true)
      this.$auth.$storage.setCookie('X-Trace-ID', null, true)
      this.$auth.$storage.setCookie('creditLineBanner', null, true)
      this.$auth.$storage.setCookie('customer_id', null, true)
      this.$auth.$storage.setCookie('fullYearsCookie', null, true)
      this.$auth.$storage.setCookie('geo', null, true)
      this.$auth.$storage.setCookie('loggedIn', null, true)
      this.$auth.$storage.setCookie('showPopupBeta', null, true)
      this.$auth.$storage.setCookie('typeGrosseryCartMode', null, true)
      this.$auth.$storage.setCookie('favorite_list_data', null, true)
      this.$auth.$storage.setCookie('favorite_list', null, true)

      if (parsed['auth.access_token']) {
        try {
          if (process.client) {
            const { data } = await customerStore.getUser()
            const cityInfo =
              data?.attributes?.addresses?.data?.[0]?.attributes?.city?.data
            if (cityInfo) {
              const city = getCityInfo({ cityInfo, app })
              this.$auth.$storage.setLocalStorage('geo', city, true)
            }
          }
        } catch (e) {
          console.log('Error: customerStore.getUser, ', e)
        }
        customerStore.isLoggedIn = !!parsed['auth.access_token']
        // this.$auth.$storage.setLocalStorage('creditLineBanner', true)
        // this.$auth.$storage.setUniversal(
        //   'loggedIn',
        //   Boolean(parsed['auth.loggedIn']),
        //   false,
        // )
        this.$auth.$storage.setCookie(
          'access_token',
          parsed['auth.access_token'],
          false,
        )
        this.$auth.$storage.setLocalStorage(
          'refresh_token',
          parsed['auth.refresh_token'],
          false,
        )
        this.$auth.$storage.setUniversal(
          'user_ext_id',
          parsed['auth.user_ext_id'],
          false,
        )
      } else {
        const city = getCityInfo({ app })
        // this.$auth.$storage.setLocalStorage('creditLineBanner', false)

        customerStore.isLoggedIn = false
        // this.$auth.$storage.setUniversal('loggedIn', null, true)
        this.$auth.$storage.setUniversal('user', null, true)
        this.$auth.$storage.setUniversal('user_ext_id', null, true)
        this.$auth.$storage.setCookie('access_token', null, true)
        this.$auth.$storage.setUniversal('refresh_token', null, true)
        customerStore.setGeo(city)
        this.$auth.$storage.setLocalStorage('geo', city, true)
      }

      // Set Geo location
      // if (parsed['auth.geo']) {
      //   console.log("auth geo", JSON.parse(parsed['auth.geo']))
      //   customerStore.setGeo(JSON.parse(parsed['auth.geo']))
      // } else {
      //   customerStore.setGeo({
      //     cityName: app.i18n.messages[app.i18n.locale].baku,
      //     countryId: 1,
      //     cityId: 1,
      //     selectedCoordinate: '40.372508,49.842474',
      //   })
      // }

      if (parsed['auth.isMobileAppsOverlayVisible']) {
        uiStore.isMobileAppsOverlayVisible =
          parsed['auth.isMobileAppsOverlayVisible']
      } else {
        uiStore.isMobileAppsOverlayVisible = true
      }
    }
  },

  async GET_PAGE({ rootState }, page) {
    this.$axios.defaults.baseURL = null
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`${page}`)
        .then(response => {
          resolve(response)
        })
        .catch(e => {
          LOG_ERROR(this?.$rollbar, this?.$config, 'GET_PAGE', e, { page })
          reject(e)
        })
    })
  },
  async FETCH_ACCESS_TOKEN() {
    if (process.server) {
      this.$axios.defaults.baseURL =
        this.$config.NODE_CUSTOMER_BASE_URL || this.$config.CUSTOMER_BASE_URL
    } else {
      this.$axios.defaults.baseURL = this.$config.CUSTOMER_BASE_URL
    }
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/v2/clients/account/refresh-token`, {
          refresh_token: this.$auth.$storage.getLocalStorage('refresh_token'),
        })
        .then(response => {
          this.$auth.$storage.setCookie(
            'access_token',
            response.data.access_token,
            false,
          )
          this.$auth.$storage.setLocalStorage(
            'refresh_token',
            response.data.refresh_token,
            false,
          )
          resolve(response.data.access_token)
        })
        .catch(error => {
          LOG_ERROR(this?.$rollbar, this?.$config, 'FETCH_ACCESS_TOKEN', error)
          reject(error)
        })
    })
  },
}
