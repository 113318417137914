
import { useDcmStore } from '@/stores/dcm'
import { storeToRefs } from 'pinia'

export default {
  name: 'TopHeader',
  props: {
    boxed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const dcmStore = useDcmStore()
    const { headerB2bForm } = storeToRefs(dcmStore)
    return { headerB2bForm }
  },
  computed: {
    sellOnUmicoMarket() {
      return `https://business.umico.az${
        this.$i18n.locale === 'az' ? '' : '/ru'
      }`
    },
  },
}
