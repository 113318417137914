
import { useCustomer } from '@/stores/customer'
export default {
  name: 'CompareLink',
  setup() {
    const customerStore = useCustomer()
    return {
      customerStore,
    }
  },
}
