
// TODO сделать оверлей нормально. Перенести из AppHeader Listener-click сюда

import { useCatalog } from '@/stores/catalog'
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'

export default {
  name: 'HeaderCategories',
  setup() {
    const catalogStore = useCatalog()
    const uiStore = useUiStore()
    const { isCatalogMenuOpen: isOpen, resolution } = storeToRefs(uiStore)
    return { catalogStore, isOpen, uiStore, resolution }
  },
  methods: {
    onClickButton() {
      const isMobile =
        this.resolution === 'sm' ||
        this.resolution === 'md' ||
        this.resolution === 'lg'
      if (!this.catalogStore.rootCategories.length) {
        this.catalogStore
          .getRootCategories()
          .then(response => {
            if (!this.isOpen) {
              if (!isMobile) {
                this.uiStore.clearFadeMain()
              } else {
                this.uiStore.clearBurgerMenu()
              }
            }
            this.isOpen = !this.isOpen
          })
          .catch(() => {})
      } else {
        if (!this.isOpen) {
          if (!isMobile) {
            this.uiStore.clearFadeMain()
          } else {
            this.uiStore.clearBurgerMenu()
          }
        }
        this.isOpen = !this.isOpen
      }
    },
  },
}
