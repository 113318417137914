export default () => ({
  accountOrderCredit: null,
  // accountOrderCredit: {},
  userProfile: null,
  countries: null,
  isBot: null,
  cities: null,
  subscriptionMarketingMailing: false,
  userCoordinate: '',
  geo: {
    cityName: '',
    countryId: 1,
    cityId: 1,
    selectedCoordinate: '40.372508,49.842474',
  },
  openPopupGuestCheckoutAuth: false,
  subscriptionNewsletter: {},
  openPopupGuestCheckoutAuthNecessary: false,
  creditUserBalance: null,
  customerCoordinates: {},
  customerSelectedCity: {
    id: null,
  },
  isLoggedIn: false,
})
