import { defineStore } from 'pinia'
import actions from './actions'
import state from './state'
import getters from './getters'

export const useCustomer = defineStore({
  id: 'сustomer',
  state,
  actions,
  getters,
})
