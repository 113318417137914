import Vue from 'vue'
import Message from 'element-ui/lib/message'
export default () => {
  Vue.prototype.$message = Message
  Vue.component('ElTable', () =>
    import(/* webpackChunkName: 'ui-table' */ 'element-ui/lib/table'),
  )
  Vue.component('ElTableColumn', () =>
    import(
      /* webpackChunkName: 'ui-table-column' */ 'element-ui/lib/table-column'
    ),
  )
  Vue.component('ElPopover', () =>
    import(/* webpackChunkName: 'ui-popover' */ 'element-ui/lib/popover'),
  )
  Vue.component('ElSlider', () =>
    import(/* webpackChunkName: 'ui-slider' */ 'element-ui/lib/slider'),
  )
  Vue.component('ElCollapse', () =>
    import(/* webpackChunkName: 'ui-collapse' */ 'element-ui/lib/collapse'),
  )
  Vue.component('ElCollapseItem', () =>
    import(/* webpackChunkName: 'ui-ci' */ 'element-ui/lib/collapse-item'),
  )
  Vue.component('ElImage', () =>
    import(/* webpackChunkName: 'ui-image' */ 'element-ui/lib/image'),
  )
  Vue.component('ElSkeleton', () =>
    import(/* webpackChunkName: 'ui-skeleton' */ 'element-ui/lib/skeleton'),
  )
  Vue.component('ElSkeletonItem', () =>
    import(
      /* webpackChunkName: 'ui-skeleton-item' */ 'element-ui/lib/skeleton-item'
    ),
  )
  Vue.component('ElSwitch', () =>
    import(/* webpackChunkName: 'ui-switch' */ 'element-ui/lib/switch'),
  )
}
