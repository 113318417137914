export default () => ({
  globalFilters: {},
  rootCategories: [],
  categories: {},
  selectedCategoryId: '',
  isProfileMenuShow: false,
  hoveredPosId: 0,
  selectedPosId: 0,
  marketingNames: [],
  mapPoses: {},
  mostPopularPartners: [],
  catalogSortBy: 'popular',
  rootSortBy: 'popular',
  allPartners: [],
  seo_keywords: [],
  seo_keywords_marketing_name: [],
  marketing_names: [],
  diffCashbacks: [],
  cashName: [],
  partner: {},
  quickSearchBy: 'products',
  list_partner_reviews: {},
  list_attribute_products: {},
})
