
import { mapGetters, mapActions as mapActionsVuex } from 'vuex'
import { mapActions } from 'pinia'
import { useLoyaltyStore } from '~/stores/loyalty'
import { useCustomer } from '~/stores/customer'

export default {
  data() {
    return {
      loaded: false,
    }
  },
  head() {
    const hasPage = !!this.$route?.query?.page
    const canonical = {
      hid: 'canonical',
      rel: 'canonical',
      href: this.$config.BASE_URL + this.$route.path,
    }
    return {
      title: this.$t('site_title'),
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        this.$i18n.locale === 'en'
          ? {
              name: 'robots',
              content: 'noindex, nofollow',
            }
          : '',
        {
          hid: 'description',
          name: 'description',
          content: this.$t('site_description'),
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: this.$t('site_title'),
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('site_title'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('site_description'),
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: 'https://umico.az/umico-og-image.jpg',
        },
      ],
      link: [!hasPage ? canonical : {}],
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'loggedInUser']),
  },
  created() {
    if (process.client && this.isAuthenticated) {
      this.getCard()
    } else {
      this.loaded = true
    }
  },
  mounted() {},
  methods: {
    ...mapActions(useLoyaltyStore, ['getCard']),
    ...mapActions(useCustomer, ['getUser']),
  },
}
