import Vue from 'vue'
import { Doughnut } from 'vue-chartjs'
import Chart from 'chart.js'

Vue.component('DoughnutChart', {
  extends: Doughnut,
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    centerLabel: {
      type: String || Number,
      default: null,
    },
  },
  mounted() {
    if (this.centerLabel) this.renderCenterLabel(this.centerLabel)
    this.renderChart(this.data, this.options)
  },
  methods: {
    renderCenterLabel(data) {
      Chart.pluginService.register({
        id: 'doughnut-label',
        beforeDraw: ({ chart }) => {
          const ctx = chart.ctx
          const width = chart.width
          const height = chart.height

          ctx.restore()

          const textX = Math.round(width / 2)
          const textY = Math.round(height / 2)
          const distanceFromCenter = Math.round(chart.height / 16)

          const fontSize = (height / 7.5).toFixed(2)
          ctx.textAlign = 'center'

          ctx.font = `400 12px Roboto`
          ctx.fillStyle = '#242B58'
          ctx.fillText(
            `${this.$t('market.total').toUpperCase()}:`,
            textX,
            textY - distanceFromCenter,
          )

          ctx.font = `600 ${fontSize}px Roboto`
          ctx.fillStyle = '#1E244D'
          ctx.fillText(data, textX, textY + distanceFromCenter + 4)

          ctx.save()
        },
      })
    },
  },
})
