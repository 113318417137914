import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  state: () => ({
    locales: ['ru', 'en'],
    locale: 'ru',
    auth: {
      cart_id: null,
      user_cart_id: null,
      loggedIn: false,
      user: null,
    },
  }),
  getters,
  actions,
  mutations,
  strict: false,
}
