import { defineStore } from 'pinia'
import { useCustomer } from '@/stores/customer'

export const useSearchHistoryStore = defineStore('search_history', {
  state: () => ({
    search_history: [],
    showSearchHistoryBlock: false,
  }),
  actions: {
    async getSearchHistory() {
      const customerStore = useCustomer()
      const loggedIn = Boolean(customerStore.isLoggedIn)
      const customer_id = this.$nuxt.$auth.$state.user_ext_id
      let session_id = this.$nuxt.$auth.$storage.getLocalStorage('customer_id')
      if (!loggedIn && !session_id) {
        this.$nuxt.$auth.$storage.setLocalStorage(
          'customer_id',
          `${Date.now()}`,
        )
      }
      session_id = String(
        this.$nuxt.$auth.$storage.getLocalStorage('customer_id'),
      )

      try {
        if (process.server) {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.NODE_CLICKSTREAM_API_URL ||
            this.$nuxt.$config.CLICKSTREAM_API_URL
        } else {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.CLICKSTREAM_API_URL
        }

        const url = loggedIn
          ? `/api/v1/events/${customer_id}/search`
          : `/api/v1/anonymous/events/${session_id}/search`
        const { data = [] } = await this.$nuxt.$axios.get(url)
        this.search_history = data
        this.showSearchHistoryBlock = !!this.search_history.length
        return data
      } catch (e) {
        console.log('GET_SEARCH_HISTORY', e)
      }
    },
    async getSearchHistoryByKey(key) {
      try {
        const data = await this.getSearchHistory()
        if (data.length) {
          this.search_history = this.search_history.filter(item => {
            const itemLowerCase = item.query.toLowerCase()
            const valLowerCase = key.toLowerCase()
            return itemLowerCase.startsWith(valLowerCase)
          })
          if (!this.search_history.length) {
            this.showSearchHistoryBlock = false
          }
        }
      } catch (e) {
        console.log('Error getSearchHistoryByKey', e)
      }
    },
    async sendSearchQuery(searchData) {
      const customerStore = useCustomer()
      const loggedIn = Boolean(customerStore.isLoggedIn)
      const customer_id = this.$nuxt.$auth.$state.user_ext_id
      const session_id = String(
        this.$nuxt.$auth.$storage.getLocalStorage('customer_id'),
      )
      try {
        if (process.server) {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.NODE_CLICKSTREAM_API_URL ||
            this.$nuxt.$config.CLICKSTREAM_API_URL
        } else {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.CLICKSTREAM_API_URL
        }
        const url = loggedIn ? '/api/v1/events' : '/api/v1/anonymous/events'
        const {
          event_type,
          event_subtype,
          query,
          marketing_name_id,
          product_id,
          category_id,
          tag_id,
        } = searchData
        const payload = {
          event_type,
          event_subtype,
          query,
          customer_locale: this.$nuxt.i18n.locale,
        }
        loggedIn
          ? (payload.customer_id = customer_id)
          : (payload.session_id = session_id)
        if (event_subtype === 'merchant')
          payload.marketing_name_id = marketing_name_id
        if (event_subtype === 'tag') payload.tag_id = tag_id
        if (event_subtype === 'product') payload.product_id = product_id
        if (event_subtype === 'category') payload.category_id = `${category_id}`

        const res = await this.$nuxt.$axios.post(url, payload)
        return res
      } catch (e) {
        console.log('sendSearchQuery', e)
      }
    },
    async deleteSearchQuery(id) {
      const customerStore = useCustomer()
      const loggedIn = Boolean(customerStore.isLoggedIn)
      const customer_id = this.$nuxt.$auth.$state.user_ext_id
      const session_id = String(
        this.$nuxt.$auth.$storage.getLocalStorage('customer_id'),
      )
      try {
        if (process.server) {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.NODE_CLICKSTREAM_API_URL ||
            this.$nuxt.$config.CLICKSTREAM_API_URL
        } else {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.CLICKSTREAM_API_URL
        }
        const url = loggedIn
          ? `/api/v1/events/${customer_id}/search/${id}`
          : `/api/v1/anonymous/events/${session_id}/search/${id}`
        const res = await this.$nuxt.$axios.delete(url)
        return res
      } catch (e) {
        console.log('Error deleteSearchQuery', e)
      }
    },
  },
})
