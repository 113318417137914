
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'
import { useFetch } from '@nuxtjs/composition-api'

export default {
  name: 'UserAgreement',
  setup() {
    const uiStore = useUiStore()
    const {
      privacyPolicyVisibility,
      publicOfferVisibility,
      userAgreementVisibility,
      termsOfUsePaymentVisibility,
    } = storeToRefs(uiStore)
    return {
      uiStore,
      privacyPolicyVisibility,
      publicOfferVisibility,
      userAgreementVisibility,
      termsOfUsePaymentVisibility,
    }
  },
  data() {
    return {
      ppComponent: null,
      poComponent: null,

      agreementText: '',
    }
  },
  computed: {
    isOpen() {
      return (
        this.privacyPolicyVisibility ||
        this.publicOfferVisibility ||
        this.userAgreementVisibility ||
        this.termsOfUsePaymentVisibility
      )
    },
  },

  watch: {
    privacyPolicyVisibility(val) {
      const lang = this.$i18n?.locale || 'ru'
      this.loadFile(`privacy_policy_${lang}.html`)
    },
    publicOfferVisibility(val) {
      const lang = this.$i18n?.locale || 'ru'
      this.loadFile(`public_offer_${lang}.html`)
    },
    userAgreementVisibility(val) {
      const lang = this.$i18n?.locale || 'ru'
      this.loadFile(`shopping-public-offer_${lang}.html`)
    },
    termsOfUsePaymentVisibility(val) {
      const lang = this.$i18n?.locale || 'az'
      this.loadFile(`terms_of_use_payment_${lang}.html`)
    },

    isOpen(val) {
      if (val) {
        document.addEventListener(
          'click',
          () => {
            this.TOGGLE(false)
          },
          { once: true },
        )
      } else this.$emit('close')
    },
  },

  methods: {
    loadFile(filename) {
      this.$axios.defaults.baseURL = ''
      this.$axios.get('/agreements/' + filename).then(resp => {
        this.agreementText = resp.data
      })
    },

    onClickClose() {
      this.TOGGLE()
    },

    TOGGLE() {
      this.uiStore.privacyPolicyVisibility = false
      this.uiStore.publicOfferVisibility = false
      this.uiStore.userAgreementVisibility = false
      this.uiStore.termsOfUsePaymentVisibility = false
    },
  },
}
