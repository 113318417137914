import { useUiStore } from '@/stores/ui'
import { useCustomer } from '@/stores/customer'

export default function ({ store, app }) {
  // TODO: REMOVE WHEN ALL COOKIES ARE DELETED
  // Clear specific cookies upon site visit

  const uiStore = useUiStore()
  const customerStore = useCustomer()
  function clearCookie(cookieName, path = '/') {
    // Get the current domain
    let domain = window.location.hostname

    // Remove 'www.' from the domain if it exists
    if (domain.startsWith('www.')) {
      domain = domain.substring(4) // Remove 'www.'
    }

    // Only add a leading dot if it's not localhost
    if (domain !== 'localhost') {
      domain = `.${domain}`
    }
    // Set the cookie with a dynamic domain and expired date
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=${path};domain=${domain};`
  }

  // List of cookies to clear
  const cookiesToClear = [
    'sbjs_current',
    'sbjs_current_add',
    'sbjs_first_add',
    'sbjs_migrations',
    'sbjs_first',
    'sbjs_session',
    'sbjs_udata',
  ]

  // Clear each cookie in the list
  cookiesToClear.forEach(cookieName => {
    clearCookie(cookieName)
  })
  uiStore.initResolution()

  if (customerStore?.isLoggedIn && typeof exponea !== 'undefined') {
    exponea.identify({
      customer_ext_id: store?.state?.auth?.user?.ext_id,
    })
  }
}
