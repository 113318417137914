import Vue from 'vue'
const mixin = {
  methods: {
    _rtb(event) {
      const isDev = process.env.ENVIRONMENT !== 'Production'
      if (isDev) {
        try {
          const prefix = ''
          const hash = 'h23ELqGEma7tSFcVBddG'
          const rtbhTags = []
          rtbhTags.push('pr_' + hash + event)
          rtbhTags.push('pr_' + hash + '_custom_language_' + this.$i18n.locale)
          const key = '__rtbhouse.lid'
          let lid = window.localStorage.getItem(key)
          if (!lid) {
            lid = ''
            const pool =
              'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            for (var i = 0; i < 20; i++)
              lid += pool.charAt(Math.floor(Math.random() * pool.length))
            window.localStorage.setItem(key, lid)
          }
          rtbhTags.push('pr_' + hash + '_lid_' + lid)
          const ifr = document.createElement('iframe')
          const sr = encodeURIComponent(
            document.referrer ? document.referrer : '',
          )
          const su = encodeURIComponent(
            document.location.href ? document.location.href : '',
          )
          let ifrSrc = 'https://' + prefix + 'creativecdn.com/tags?type=iframe'
          const tmstmp = encodeURIComponent('' + Date.now())
          for (const i in rtbhTags) {
            ifrSrc += '&id=' + encodeURIComponent(rtbhTags[i])
          }
          ifrSrc += '&su=' + su + '&sr=' + sr + '&ts=' + tmstmp
          ifr.setAttribute('src', ifrSrc)
          ifr.setAttribute('width', '1')
          ifr.setAttribute('height', '1')
          ifr.setAttribute('scrolling', 'no')
          ifr.setAttribute('frameBorder', '0')
          ifr.setAttribute('style', 'display:none')
          ifr.setAttribute('referrerpolicy', 'no-referrer-when-downgrade')
          if (document.body) {
            document.body.appendChild(ifr)
          } else {
            window.addEventListener('DOMContentLoaded', function () {
              document.body.appendChild(ifr)
            })
          }
        } catch (e) {}
      }
    },
  },
}
Vue.mixin(mixin)
