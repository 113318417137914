
import { useUiStore } from '@/stores/ui'

export default {
  name: 'TopFooter',
  props: {
    hideBottom: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  methods: {
    showPrivacyPolicy() {
      setTimeout(() => (this.uiStore.privacyPolicyVisibility = true), 30)
    },
    showPublicOffer() {
      setTimeout(() => (this.uiStore.publicOfferVisibility = true), 30)
    },
    showUserAgreement() {
      setTimeout(() => (this.uiStore.userAgreementVisibility = true), 30)
    },
  },
}
