
import { mapWritableState } from 'pinia'
import setDataLayer from '@/mixins/setDataLayer'
import { useUiStore } from '@/stores/ui'
import { useMarketStore } from '@/stores/market'
import { useDataLayerStore } from '~/stores/dataLayer'
import { rtbAnalytics, updateCartIndexInLocalStorage } from '@/utilities'
import { useBloomreachStore } from '@/stores/bloomreach'
import { mapGetters } from 'vuex'

export default {
  name: 'SuccessRateAddtoCartDialog',
  mixins: [setDataLayer],
  setup() {
    const marketStore = useMarketStore()
    const dataLayerStore = useDataLayerStore()
    const uiStore = useUiStore()
    const bloomReachStore = useBloomreachStore()
    return {
      dataLayerStore,
      marketStore,
      bloomReachStore,
      uiStore,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    ...mapWritableState(useUiStore, [
      'showRateSuccessDialog',
      'orderSuccessRateDialog',
      'rateSuccessDialogData',
      'showBulkPurchasePopup',
    ]),
    ...mapWritableState(useMarketStore, {
      product: 'rateSuccessDialogProduct',
      product_other_offers: 'product_other_offers',
    }),
    // bloomReachProduct() {
    //   return {
    //     ...this.orderSuccessRateDialog,
    //     ...{
    //       old_price:
    //     }
    //   }
    // }
  },
  methods: {
    showPopup() {
      setTimeout(() => {
        if (this.$device.isMobile) {
          this.uiStore.isPopupCartMobileVisible = true
        } else {
          this.uiStore.isPopupCartVisible = !this.uiStore.isPopupCartVisible
        }
      }, 500)
    },
    async addToCart() {
      const product = {
        product_id:
          this.product_other_offers?.product_id ||
          this.product_other_offers?.id,
        quantity:
          this.product_other_offers?.min_qty > 1
            ? this.product_other_offers?.min_qty
            : this.product_discount_quantity || 1,
        min_qty: this.product_other_offers?.min_qty,
        default_marketing_name: this.product
          ? this.product?.seller_marketing_name
          : this.product_other_offers?.default_marketing_name,
        default_merchant_rating: this.product
          ? this.product?.partner_rating
          : this.product_other_offers?.default_merchant_rating,
        seller_marketing_name: this.product?.seller_marketing_name,
        default_offer_allow_qty:
          this.product_other_offers?.default_offer_allow_qty,
        offer_id: this?.product?.uuid,
        retail_price:
          this.product?.retail_price || this.product_other_offers?.retail_price,
        product_index: this.product_other_offers?.product_index,
      }
      if (
        (this.rateSuccessDialogData &&
          this.rateSuccessDialogData?.avail_check) ||
        (!this.rateSuccessDialogData && this.product_other_offers?.avail_check)
      ) {
        this.uiStore.availableCountProduct = product
        this.showRateSuccessDialog = false
        if (this.isAuthenticated) {
          setTimeout(() => {
            this.availablePopupProduct = this.product_other_offers
            this.closePopup()
            this.uiStore.availablePopupVisible = true
            this.product && this.product.avail_check
              ? this.timeMerchantOffersPopup(this.product)
              : this.timeMerchantOffersPopup()
            this._rtb('_offlinecheck')
          }, 500)
        } else {
          this.availablePopupProduct = this.product_other_offers
          this.uiStore.setAvailableRegisterPopup({
            visible: true,
            product,
          })
        }
      } else if (this.rateSuccessDialogData?.min_qty > 1) {
        this.showRateSuccessDialog = false
        this.showBulkPurchasePopup = true
        this.bloomReachStore.addedProduct = this.rateSuccessDialogData
      } else {
        this.loading = true
        const product = this.rateSuccessDialogData
        Object.entries(this.dataLayerStore.optionsAddToCart).forEach(
          ([key, value]) => {
            product[key] = value
          },
        )
        await this.marketStore
          .addProductToCart({
            product: {
              line_items: [product],
            },
          })
          .then(() => {
            const newProductData = {
              product_id: String(product.product_id),
              product_index: String(product.product_index + 1),
              source: this.marketStore.sourceSelectItem,
            }
            // Store in Local Storage
            updateCartIndexInLocalStorage(newProductData, 'add')
            this.marketStore.addedProduct = product
            this.bloomReachStore.addedProduct = product
            this.showPopup()
          })
          .finally(() => {
            rtbAnalytics(this.$i18n.locale, {
              eventType: 'basketadd',
              offerId: `${product.product_id}@${this.$i18n.locale}`,
            })
            // this._rtb(`_basketadd_${product.product_id}@${this.$i18n.locale}`)
            setTimeout(async () => {
              this.loading = false
              await this.addToCartDataLayer(product) // set from mixin
              await this.bloomReachStore.cartUpdate(
                'add',
                this.marketStore.sourceSelectItem,
                'add',
              )
              this.showRateSuccessDialog = false
              this.rateSuccessDialogData = null
              this.rateSuccessDialogProduct = null
            }, 300)
          })
      }
    },
    async timeMerchantOffersPopup(offer) {
      if (offer) {
        if (offer.avail_check) {
          const merchant_uuid = offer.merchant_uuid
          await this.marketStore.getPartnersMerchantOffers(merchant_uuid)
        }
      } else if (
        this.product_other_offers &&
        this.product_other_offers.avail_check
      ) {
        const offersAvailCheck = this.product_other_offers.offers.filter(
          offer => {
            return offer.avail_check
          },
        )
        if (offersAvailCheck.length) {
          const merchant_uuid = offersAvailCheck?.[0]?.merchant_uuid
          await this.marketStore.getPartnersMerchantOffers(merchant_uuid)
        }
      }
    },
    closePopup() {
      this.$emit('close')
      this.offerId = null
      this.uiStore.isPopupOtherOffersVisible = false
      this.product_other_offers = null
    },
  },
}
