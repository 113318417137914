import { LOG_ERROR, normalizeCategory, serialize } from '@/utilities'
import { useCustomer } from '@/stores/customer'
const canceling = {}
export default {
  setGlobalFilter(name, value) {
    this.globalFilters[name] = value
  },
  removeGlobalFilters(name) {
    delete this.globalFilters[name]
  },
  setCurrentCategoryId(payload) {
    this.selectedCategoryId = payload
  },

  posSelect(posId) {
    if (this.selectedPosId !== posId) this.selectedPosId = String(posId)
  },
  toggleProfileDropdown(payload = !this.isProfileMenuShow) {
    this.isProfileMenuShow = payload
  },
  setFilterCatalogSortBy(payload = 'popular') {
    this.catalogSortBy = payload
  },
  setFilterRootSortBy(payload = 'alphabet') {
    this.rootSortBy = payload
  },
  async getRootCategories() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
          this.$nuxt.$config.CUSTOMER_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CUSTOMER_BASE_URL
      }
      const resp = await this.$nuxt.$axios.get('helpers/categories/root')

      this.rootCategories = resp?.data?.data
      return resp
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'GET_ROOT_CATEGORIES',
        e,
      )
    }
  },
  async getCategory(id) {
    const customerStore = useCustomer()
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
          this.$nuxt.$config.CUSTOMER_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CUSTOMER_BASE_URL
      }
      // console.warn(
      //   `GET_CATEGORY: ${
      //     this.$axios.defaults.baseURL
      //   }/helpers/categories/${id}/?city_id=${rootState.customer.geo.city_id}`,
      // )
      const start = Date.now()
      const resp = await this.$nuxt.$axios.get(
        `helpers/categories/${id}/?city_id=${customerStore.geo.cityId}`,
      )

      if (resp.data && resp.data?.self && resp.data?.self?.data?.id) {
        const id = resp.data?.self?.data?.id
        this.categories[id] = normalizeCategory(resp.data)
        this.selectedCategoryId = id
      }

      return resp.data
    } catch (e) {
      LOG_ERROR(this?.$nuxt.$rollbar, this?.$nuxt.$config, 'getCategory', e, {
        id,
      })
    }
  },

  async getCategoryItems(options) {
    try {
      if (options.sort_by === 'popular' || options.sort_by === 'beneficial')
        return this.getMarketingNames(options)
      return this.getPointOfSales(options)
    } catch (e) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'getCategoryItems', e, options)
    }
  },

  async getQuickSearch(data) {
    try {
      if (canceling.getQuickSearch) {
        canceling.getQuickSearch.cancel('Request canceling!!!!!!!')
        canceling.getQuickSearch = null
      }
      const CancelToken = this.$nuxt.$axios.CancelToken
      const source = CancelToken.source()
      canceling.getQuickSearch = {
        request: data,
        cancel: source.cancel,
      }
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_SEARCH_BASE_URL ||
          this.$nuxt.$config.SEARCH_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.SEARCH_BASE_URL
      }
      const resp = await this.$nuxt.$axios.get(
        `v2/quick-search?${serialize(data)}`,
        {
          cancelToken: source.token,
        },
      )
      if (resp.data) {
        // eslint-disable-next-line
        canceling['getQuickSearch'] = null
        return resp.data
      } else {
        return []
      }
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getQuickSearch',
        e,
        data,
      )
    }
  },

  async getMarketPlaceSearch(data) {
    try {
      if (canceling.getMarketPlaceSearch) {
        canceling.getMarketPlaceSearch.cancel('Request canceling!!!!!!!')
        canceling.getMarketPlaceSearch = null
      }
      const CancelToken = this.$nuxt.$axios.CancelToken
      const source = CancelToken.source()
      canceling.getMarketPlaceSearch = {
        request: data,
        cancel: source.cancel,
      }
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
      }

      const resp = await this.$nuxt.$axios.get(
        `/v3/market/suggests?${serialize(data)}`,
        {
          cancelToken: source.token,
        },
      )

      if (resp.data) {
        // eslint-disable-next-line
        canceling['getMarketPlaceSearch'] = null
        return resp.data
      } else {
        return []
      }
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getMarketPlaceSearch',
        e,
        data,
      )
    }
  },

  async getPointOfSale({ posId, filters }) {
    try {
      this.hoveredPosId = 0
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_SEARCH_BASE_URL ||
          this.$nuxt.$config.SEARCH_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.SEARCH_BASE_URL
      }
      try {
        const resp = await this.$nuxt.$axios.get(
          `v1/point-of-sales/${posId}?${serialize(filters)}`,
        )
        if (resp && resp.data) {
          const pos = resp.data
          const partnerIDs = pos.partner_ext_id
          if (partnerIDs) {
            if (process.server) {
              this.$nuxt.$axios.defaults.baseURL =
                this.$nuxt.$config.NODE_LOYALTY_API_URL ||
                this.$nuxt.$config.LOYALTY_API_URL
            } else {
              this.$nuxt.$axios.defaults.baseURL =
                this.$nuxt.$config.LOYALTY_API_URL
            }
            await this.$nuxt.$axios
              .get(`public/partners/${partnerIDs}/programs`)
              .then(res => {
                this.diffCashbacks = res?.data?.data?.extra
                this.cashName = res?.data?.data?.basic
              })
          }
          return pos
        }
      } catch (err) {
        return err
      }
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getPointOfSale',
        e,
        {
          posId,
          filters,
        },
      )
    }
  },

  async getPointOfSales(options) {
    const customerStore = useCustomer()
    try {
      const defaultOptions = {
        page: 1,
        per_page: 20,
        sort_by: 'nearest',
        country_id: customerStore.geo.countryId,
        city_id: customerStore.geo.cityId,
        coordinates: customerStore.selectedCoordinate,
      }

      options = {
        ...defaultOptions,
        ...options,
      }

      this.hoveredPosId = 0
      // TODO improve
      const start = Date.now()
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_SEARCH_BASE_URL ||
          this.$nuxt.$config.SEARCH_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.SEARCH_BASE_URL
      }
      const response = await this.$nuxt.$axios.get(
        `v1/point-of-sales?${serialize(options)}`,
      )
      this.marketingNames = response.data.marketing_names
      return response.data
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getPointOfSales',
        e,
        options,
      )
    }
  },

  async getMostPopularPartners(data) {
    const customerStore = useCustomer()
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_SEARCH_BASE_URL ||
        this.$nuxt.$config.SEARCH_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.SEARCH_BASE_URL
    }
    // Get Partners
    const options = {
      page: 1,
      per_page: 5,
      country_id: +customerStore.geo.countryId,
      city_id: +customerStore.geo.cityId,
      sort_by: 'popular',
      coordinates: [customerStore.selectedCoordinate],
    }

    try {
      const resp = await this.$nuxt.$axios.get(
        `v2/marketing_names?${serialize(options)}`,
      )
      if (resp && resp.data && resp.data.data) return resp.data.data
      return []
    } catch (err) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getMostPopularPartners',
        err,
        data,
      )
      return []
    }
  },

  async getMarketingNames(options) {
    const customerStore = useCustomer()
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_SEARCH_BASE_URL ||
        this.$nuxt.$config.SEARCH_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.SEARCH_BASE_URL
    }
    // Get Partners
    const defaultOptions = {
      page: 1,
      per_page: 60,
      country_id: +customerStore.geo.countryId,
      city_id: +customerStore.geo.cityId,
      sort_by: 'popular',
      coordinates: [customerStore.selectedCoordinate],
    }

    options = {
      ...defaultOptions,
      ...options,
    }
    try {
      const response = await this.$nuxt.$axios.get(
        `v2/marketing_names?${serialize(options)}`,
      )
      this.marketing_names = response?.data?.data
      this.marketingNames = response?.data?.data?.map(item => {
        return {
          name: item.name,
          id: item.id,
        }
      })
      return response?.data
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getMarketingNames',
        e,
        options,
      )
    }
  },

  async getMarketingName(data) {
    this.hoveredPosId = 0
    const customerStore = useCustomer()
    const defaultOptions = {
      country_id: customerStore.geo.countryId,
      city_id: customerStore.geo.cityId,
      coordinates: customerStore.selectedCoordinate,
    }
    const options = {
      ...defaultOptions,
      ...data?.filters,
    }
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_SEARCH_BASE_URL ||
        this.$nuxt.$config.SEARCH_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.SEARCH_BASE_URL
    }
    let resp
    try {
      resp = await this.$nuxt.$axios.get(
        `v2/marketing_names/${data.partnerId}?${serialize(options)}`,
      )
      if (!resp || !resp.data) return {}

      this.partner = resp.data

      const partnerIDs = resp.data.partner_ext_ids[0].ext_id

      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_LOYALTY_API_URL ||
          this.$nuxt.$config.LOYALTY_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.LOYALTY_API_URL
      }
      this.$nuxt.$axios
        .get(`public/partners/${partnerIDs}/programs`)
        .then(res => {
          this.diffCashbacks = res?.data?.data?.extra
          this.cashName = res?.data?.data?.basic
        })

      return resp.data
    } catch (err) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getMarketingName',
        err,
        options,
      )
      return err
    }
  },
  async getPartnersRatings(id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_SEARCH_BASE_URL ||
          this.$nuxt.$config.SEARCH_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.SEARCH_BASE_URL
      }
      const res = await this.$nuxt.$axios.get(
        `v2/marketing_names/by-ext-id/${id}`,
      )
      return res?.data?.mp_partners_ratings || {}
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getPartnersRatings',
        e,
        {
          id,
        },
      )
    }
  },
  async getSeoKeywords(id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_SEARCH_BASE_URL ||
          this.$nuxt.$config.SEARCH_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.SEARCH_BASE_URL
      }
      const resp = await this.$nuxt.$axios.get(
        `v1/search-keywords/category/${id}`,
      )
      this.seo_keywords = resp.data
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getSeoKeywords',
        e,
        {
          id,
        },
      )
    }
  },
  async getRandomKeywords() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_SEARCH_BASE_URL ||
          this.$nuxt.$config.SEARCH_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.SEARCH_BASE_URL
      }
      const resp = await this.$nuxt.$axios.get(`v1/search-keywords/`)
      this.seo_keywords = resp.data
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getRandomKeywords',
        e,
      )
    }
  },
  async getMarketingNameSeoKeyword(id) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_SEARCH_BASE_URL ||
        this.$nuxt.$config.SEARCH_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.SEARCH_BASE_URL
    }
    const resp = await this.$nuxt.$axios.get(
      `v1/search-keywords/marketing_name/${id}`,
    )
    this.seo_keywords_marketing_name = resp?.data
  },
  async getPartnersReviews(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_ASSESSMENT_API_URL ||
        this.$nuxt.$config.ASSESSMENT_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.ASSESSMENT_API_URL
    }
    const response = await this.$nuxt.$axios.get(
      `/api/v1/public/message-counts?${serialize(options)}`,
    )
    this.list_partner_reviews = response?.data
    return ''
  },
  async getProductLinkAttribute(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
    }
    const { data } = await this.$nuxt.$axios
      .get(`/v3/market/tags?${options}`)
      .catch(error => {
        console.error('getProductLinkAttribute', error)
      })
    const list_attribute_products = {}
    data?.tags?.forEach(item => {
      list_attribute_products[item?.master_field?.id] = item
    })
    this.list_attribute_products = list_attribute_products
  },
}
