export default {
  categoryById: state => {
    return id => {
      return state.categories[id]
    }
  },
  selectedCategory: state => {
    return state.categories[state.selectedCategoryId]
  },
}
