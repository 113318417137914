
//  div.HamburgerButton(@click="onClick")
export default {
  name: 'HamburgerButton',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      // TODO add animation hamburger
      this.$emit('click')
    },
  },
}
