const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['before-route-change'] = require('../middleware/before-route-change.js')
middleware['before-route-change'] = middleware['before-route-change'].default || middleware['before-route-change']

middleware['bot-detection'] = require('../middleware/bot-detection.js')
middleware['bot-detection'] = middleware['bot-detection'].default || middleware['bot-detection']

middleware['loggedin'] = require('../middleware/loggedin.js')
middleware['loggedin'] = middleware['loggedin'].default || middleware['loggedin']

export default middleware
