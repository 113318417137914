
import { mapState } from 'pinia'
import { useCheckoutCredit } from '@/stores/checkout_credit'
import { useCheckoutStore } from '@/stores/checkout'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'InSufficientCreditPopup',
  setup() {
    const uiStore = useUiStore()
    const checkoutStore = useCheckoutStore()
    return { uiStore, checkoutStore }
  },
  data() {
    return {
      width: 560,
    }
  },
  computed: {
    ...mapState(useCheckoutCredit, ['credit_user_balance']),
    ...mapState(useCheckoutStore, ['orderPackage']),
    showModal: {
      get() {
        return this.uiStore.inSufficientCreditModal
      },
      set(val) {
        this.uiStore.inSufficientCreditModal = val
      },
    },
    minCreditLimit() {
      const minLimit = this.checkoutStore.defaultSettings?.find(
        item => item.code === 'MIN_CREDIT_LIMIT',
      )
      return minLimit?.value?.number
    },
    maxCreditLimit() {
      return this.credit_user_balance?.avail_limit_max
    },
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        if (typeof dataLayer !== 'undefined') {
          if (!this.maxCreditLimit) {
            dataLayer.push({
              event: 'credit_scr_min_sum_false',
            })
          } else {
            dataLayer.push({
              event: 'credit_scr_deficiently_credit_funds',
            })
          }
        }
      }
    },
  },
  methods: {
    closeModal() {
      if (this.$route.name.includes('checkout-authentication')) {
        try {
          this.$router.push({
            path: this.localePath('cart'),
          })
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') throw error
        }
      }
      this.uiStore.inSufficientCreditModal = false
    },
  },
}
