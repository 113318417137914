
export default {
  name: 'NuxtError',
  layout: 'error-layout',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 404) {
        return 'ErrorFourHundredFour'
        // return () => import('@/components/Error/FourHundredFour.vue')
      }
      return 'ErrorFiveHundred'
      // return () => import('@/components/Error/FiveHundred.vue')
    },
  },
}
