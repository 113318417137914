export const RESOLUTIONS = {
  sm: 736,
  md: 992,
  lg: 1280,
  xl: 1312,
  xxl: 100000,
}

// TODO refactor whitelist after BIRBANK fix
export const WHITELISTED_PATHS_IOS = [
  '/',
  '/ru',
  '/az',
  '/brands/*',
  '/*/brands/*',
  '/product/*',
  '/*/product/*',
  '/categories',
  '/*/categories',
  '/categories/*',
  '/*/categories/*',
  '/search/*',
  '/*/search/*',
  '/mega-discounts/*',
  '/mega-discounts',
  '/*/mega-discounts',
  '/*/mega-discounts/*',
  '/merchant/*',
  '/*/merchant/*',
  '/account/orders',
  '/account/orders/*',
  '/*/account/orders/*',
  '/*/account/orders',
  '/sale-day/*',
  '/sale-day',
  '*/sale-day',
  '/*/sale-day/*',
  '/new-products/*',
  '/new-products',
  '/*/new-products',
  '/*/new-products/*',
  '/smart-categories/*',
  '/smart-categories',
  '/*/smart-categories',
  '/*/smart-categories/*',
  '/tags/*',
  '/tags',
  '/*/tags/*',
  '/promotion/*',
  '/promotion',
  '/*/promotion',
  '/*/promotion/*',
  '/services/*',
  '/*/services/*',
  '*/credit_line',
  '*/credit_line/*',
  '/credit_line/*',
  '/promo_page/*',
  '/*/promo_page/*',
  '/coupon/*',
  '/*/coupon/*',
  '/product-assessment/*',
  '/*/product-assessment/*',
  '/*/product-assessment',
  '/product-assessment',
  '/smart_bonus/*',
  '/smart_bonus',
  '/*/smart_bonus',
  '/sp',
  '/*/sp',
  '/*/sp/*',
  '/bonuses_main',
  '/*/bonuses_main/*',
  '/returns/*',
  '/returns',
  '/*/returns',
  '/*/returns/*',
]
export const WHITELISTED_PATHS_ANDROID = [
  // '/',
  // '/ru',
  // '/az',
  '/brands/*',
  '/*/brands/*',
  '/product/*',
  '/*/product/*',
  // '/categories',
  // '/*/categories',
  // '/categories/*',
  // '/*/categories/*',
  '/search/*',
  '/*/search/*',
  '/mega-discounts/*',
  '/mega-discounts',
  '/*/mega-discounts',
  '/*/mega-discounts/*',
  '/merchant/*',
  '/*/merchant/*',
  '/account/orders',
  '/account/orders/*',
  '/*/account/orders/*',
  '/*/account/orders',
  '/sale-day/*',
  '/sale-day',
  '*/sale-day',
  '/*/sale-day/*',
  '/new-products/*',
  '/new-products',
  '/*/new-products',
  '/*/new-products/*',
  '/smart-categories/*',
  '/smart-categories',
  '/*/smart-categories',
  '/*/smart-categories/*',
  '/tags/*',
  '/tags',
  '/*/tags/*',
  '/promotion/*',
  '/promotion',
  '/*/promotion',
  '/*/promotion/*',
  '/services/*',
  '/*/services/*',
  '*/credit_line',
  '*/credit_line/*',
  '/credit_line/*',
  '/promo_page/*',
  '/*/promo_page/*',
  '/coupon/*',
  '/*/coupon/*',
  '/product-assessment/*',
  '/*/product-assessment/*',
  '/*/product-assessment',
  '/product-assessment',
  '/smart_bonus/*',
  '/smart_bonus',
  '/*/smart_bonus',
  '/sp',
  '/*/sp',
  '/*/sp/*',
  '/bonuses_main',
  '/*/bonuses_main/*',
  '/returns/*',
  '/returns',
  '/*/returns',
  '/*/returns/*',
]
export const CATEGORY_ICON = {
  0: 'mega-discounts',
  1: 'food_drink',
  2: 'mobile',
  9: 'clothes',
  15: 'notebook',
  27: 'hobby_and_books',
  38: 'parfume',
  60: 'child',
  70: 'tv',
  82: 'home',
  88: 'electronics',
  106: 'appliances',
  112: 'recreation_entertainment',
  164: 'education',
  175: 'travel',
  180: 'auto_moto',
  204: 'photo',
  226: 'supermarkety',
  228: 'cables',
  235: 'auto_electronics',
  249: 'smart_house',
  257: 'parfume',
  288: 'cable',
  937: 'home_garden',
  779: 'mom_son',
  746: 'household_chemicals',
  516: 'sport',
  1340: 'hobby_and_books',
  1657: 'office',
  1708: 'auto_products',
  2164: 'pets',
  2266: 'accessory',
  2492: 'groceries',
  3569: 'fan',
  3003: 'clothes',
  3890: 'outlet',
}
export const CATALOG_CATEGORY_ICON = {
  0: 'mega-discounts',
  1: 'electronic_bit',
  2: 'mobile',
  15: 'notebook',
  70: 'tv',
  88: 'electronics',
  106: 'appliances',
  204: 'photo',
  228: 'cables',
  235: 'auto_electronics',
  249: 'smart_house',
  257: 'parfume',
  288: 'cable',
  937: 'home_garden',
  779: 'mom_son',
  746: 'household_chemicals',
  516: 'sport',
  1340: 'hobby_and_books',
  1657: 'office',
  1708: 'auto_products',
  2164: 'pets',
  2266: 'accessory',
  2492: 'groceries',
  3569: 'fan',
  3003: 'clothes',
  3890: 'outlet',
  4497: 'duncan',
  4641: 'hand-card',
  4835: 'gaming',
}
export const CATALOG_CATEGORY_IMAGE = {
  1: 'https://storage-dev.umico.az/cpcustomerdev01/ce35e9178a41194116c083b72a9c3281.png',
  270: 'https://storage-dev.umico.az/cpcustomerdev01/513df7460b0909eff994d5e183acd026.png',
  1094: 'https://storage-dev.umico.az/cpcustomerdev01/cb56118e5fe3a0d5fb6df4082fa7a4e5.png',
  1523: 'https://storage-dev.umico.az/cpcustomerdev01/1389bd3d9fbf321b06079a89883e625d.png',
  1873: 'https://storage-dev.umico.az/cpcustomerdev01/9f7291b4aa1be8c9e219747be83edd82.png',
  2436: 'https://storage-dev.umico.az/cpcustomerdev01/7e9ddccd050c1827590e1248864a36fc.png',
  4044: 'https://storage-dev.umico.az/cpcustomerdev01/1893511fe1048343245b8826de58dd8b.png',
  4911: 'https://storage-dev.umico.az/cpcustomerdev01/1d8bad68e43ab18bc3b2f96b550f1b21.png',
  5678: 'https://storage-dev.umico.az/cpcustomerdev01/d79132da53bc05bfd1d844d9df070fe5.png',
  5695: 'https://storage-dev.umico.az/cpcustomerdev01/27a57a652a9420008d39695a005a153f.png',
}
export const PRODUCT_AVAILABILITIES = {
  available: { color: '#53cebf' },
  no_control: { color: 'red' },
  n_a: { color: '#9497AD' },
  expected: { color: 'red' },
  service: { color: 'red' },
  preorder: { color: 'red' },
}

export const PRODUCT_FILTER_TYPES = {
  supply_type: 'supply_type_in',
  origin_country: 'origin_country_in',
  manufacturer: 'manufacturer_in',
  seller_id: 'seller_id_in',
  merchant_uuid: 'merchant_uuid_in',
  retail_price_lteq: 'retail_price_lteq',
  retail_price_gteq: 'retail_price_gteq',
  has_taxit_available: 'has_taxit_available',
  has_installment: 'has_installment',
  premium_partner: 'premium_partner',
  has_discount: 'has_discount',
  facets: 'facets',
  status: 'status',
}

export const STR_IMAGE_URL = {
  Production: 'https://strgimgr.umico.az',
  Staging: 'https://strgimgrsstage.umico.az',
  Testing: 'https://strgimgrstest.umico.az',
  Development: 'https://strgimgrsdev.umico.az',
}

export const APPINSIGHTS_INSTRUMENTATION_KEY = {
  Production: 'b94481dd-a68b-456a-9ee3-05f24ca73bf5',
  Staging: '4aabcbee-682a-4ff4-a6f8-966e7fcc9ddd',
  Testing: 'e46e4ff3-0530-48ff-ba59-9c2ae2360e5d',
  Development: 'ec02c0c1-68f5-4b9f-ab04-c4754bc7d89e',
}

export const INCLUDE_FIELDS =
  'id,old_price,retail_price,availability,preorder_available,default_offer_id,img_url_thumbnail,name,manufacturer,avail_check,status,slugged_name,discount,default_marketing_name,ratings,offers,offers.retail_price,offers.id,offers.marketing_name,offers.merchant_uuid,category_id,default_offer_allow_qty,offers.uuid,offers.partner_rating,default_merchant_rating,qty,default_stock_control_mode,default_show_stock_qty_threshold,best_installment_offer_id,offers.supplier_id,is_bulk_heavy,default_merchant_uuid,offers.seller_marketing_name'

export const INCLUDE_FIELDS_PRODUCT =
  'seo_title,seo_description,offers,offers.partner_rating,offers.retail_price,offers.marketing_name,offers.seller_id,offers.seller_marketing_name,default_offer_id,offers.id,offers.old_price,offers.uuid,offers.merchant_uuid,is_assortment,offers.avail_check,offers.comment,offers.comment_az,offers.comment_ru,product_labels,slugged_name_ru,slugged_name_az,id,old_price,retail_price,img_url_thumbnail,description,description_az,description_ru,default_offer_uuid,img_urls_secondary,img_url_original,img_url_standard,img_url_zoom,default_comment,variant_code,name,categories,default_discount_effective_end_date,default_supplier_id,default_merchant_uuid,slugged_name,price,uuid,updated_at,ratings,search_tags,default_marketing_name,default_discount_effective_start_date,default_offer_allow_qty,retail_price,availability_checked_at,activated_at,avail_check,status,similar_products,complementary_products,category_id,manufacturer,custom_fields,product_id,videos,main_video,min_qty,default_merchant_vat_payer,loyalty_cashback,is_bulk_heavy,default_merchant_rating,show_tech_description,description_outlet,qty,default_show_stock_qty_threshold,non_refundable,default_offer_with_additional_service'

export const UMICO_MARKET_PARTNER_ID = {
  Production: '239449d1-713c-4a42-8ce3-7821afcab364',
  Staging: 'b2a05c1d-a86c-45ac-880f-6d09d1639e70',
  Testing: 'de488d75-3f86-4e55-bf0c-e8e22f9b1072',
  Development: '73fc97e2-9e0e-4559-93fe-53021637fef6',
}

export const PER_PAGE = 24

export const BANNERS_MEGA_DISCOUNTS = {
  Staging: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '183f7a2c-4d29-4917-a876-bd6ef53af69a',
        ph: 'admixer_183f7a2c4d294917a876bd6ef53af69a_zone_103061_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '8a5bd9d4-c80b-43e8-b678-766dc0ce6ae2',
        ph: 'admixer_8a5bd9d4c80b43e8b678766dc0ce6ae2_zone_103062_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '74bb1b9c-6db4-4bb0-abdd-f651766831fe',
        ph: 'admixer_74bb1b9c6db44bb0abddf651766831fe_zone_103063_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '2246e098-847e-4322-8d15-cb86698cabe6',
        ph: 'admixer_2246e098847e43228d15cb86698cabe6_zone_103064_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '03a0960e-7de8-46ec-8305-9d77b806542e',
        ph: 'admixer_03a0960e7de846ec83059d77b806542e_zone_103065_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '3cd8a82f-eaee-43e1-b35d-bcd8953adac3',
        ph: 'admixer_3cd8a82feaee43e1b35dbcd8953adac3_zone_103066_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '977b20f5-a6e1-4dac-ad7f-fb0599bf0628',
        ph: 'admixer_977b20f5a6e14dacad7ffb0599bf0628_zone_103067_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'a73ecc6f-f65d-4e7d-bbcd-5a432e46db0e',
        ph: 'admixer_a73ecc6ff65d4e7dbbcd5a432e46db0e_zone_103068_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_183f7a2c4d294917a876bd6ef53af69a_zone_103061_sect_46843_site_40857',
      banner_2:
        'admixer_8a5bd9d4c80b43e8b678766dc0ce6ae2_zone_103062_sect_46843_site_40857',
      banner_3:
        'admixer_74bb1b9c6db44bb0abddf651766831fe_zone_103063_sect_46843_site_40857',
      banner_4:
        'admixer_2246e098847e43228d15cb86698cabe6_zone_103064_sect_46843_site_40857',
      banner_5:
        'admixer_03a0960e7de846ec83059d77b806542e_zone_103065_sect_46843_site_40857',
      banner_6:
        'admixer_3cd8a82feaee43e1b35dbcd8953adac3_zone_103066_sect_46843_site_40857',
      banner_7:
        'admixer_977b20f5a6e14dacad7ffb0599bf0628_zone_103067_sect_46843_site_40857',
      banner_8:
        'admixer_a73ecc6ff65d4e7dbbcd5a432e46db0e_zone_103068_sect_46843_site_40857',
    },
  },
  Development: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '183f7a2c-4d29-4917-a876-bd6ef53af69a',
        ph: 'admixer_183f7a2c4d294917a876bd6ef53af69a_zone_103061_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '8a5bd9d4-c80b-43e8-b678-766dc0ce6ae2',
        ph: 'admixer_8a5bd9d4c80b43e8b678766dc0ce6ae2_zone_103062_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '74bb1b9c-6db4-4bb0-abdd-f651766831fe',
        ph: 'admixer_74bb1b9c6db44bb0abddf651766831fe_zone_103063_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '2246e098-847e-4322-8d15-cb86698cabe6',
        ph: 'admixer_2246e098847e43228d15cb86698cabe6_zone_103064_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '03a0960e-7de8-46ec-8305-9d77b806542e',
        ph: 'admixer_03a0960e7de846ec83059d77b806542e_zone_103065_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '3cd8a82f-eaee-43e1-b35d-bcd8953adac3',
        ph: 'admixer_3cd8a82feaee43e1b35dbcd8953adac3_zone_103066_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '977b20f5-a6e1-4dac-ad7f-fb0599bf0628',
        ph: 'admixer_977b20f5a6e14dacad7ffb0599bf0628_zone_103067_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'a73ecc6f-f65d-4e7d-bbcd-5a432e46db0e',
        ph: 'admixer_a73ecc6ff65d4e7dbbcd5a432e46db0e_zone_103068_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_183f7a2c4d294917a876bd6ef53af69a_zone_103061_sect_46843_site_40857',
      banner_2:
        'admixer_8a5bd9d4c80b43e8b678766dc0ce6ae2_zone_103062_sect_46843_site_40857',
      banner_3:
        'admixer_74bb1b9c6db44bb0abddf651766831fe_zone_103063_sect_46843_site_40857',
      banner_4:
        'admixer_2246e098847e43228d15cb86698cabe6_zone_103064_sect_46843_site_40857',
      banner_5:
        'admixer_03a0960e7de846ec83059d77b806542e_zone_103065_sect_46843_site_40857',
      banner_6:
        'admixer_3cd8a82feaee43e1b35dbcd8953adac3_zone_103066_sect_46843_site_40857',
      banner_7:
        'admixer_977b20f5a6e14dacad7ffb0599bf0628_zone_103067_sect_46843_site_40857',
      banner_8:
        'admixer_a73ecc6ff65d4e7dbbcd5a432e46db0e_zone_103068_sect_46843_site_40857',
    },
  },
  Testing: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '183f7a2c-4d29-4917-a876-bd6ef53af69a',
        ph: 'admixer_183f7a2c4d294917a876bd6ef53af69a_zone_103061_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '8a5bd9d4-c80b-43e8-b678-766dc0ce6ae2',
        ph: 'admixer_8a5bd9d4c80b43e8b678766dc0ce6ae2_zone_103062_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '74bb1b9c-6db4-4bb0-abdd-f651766831fe',
        ph: 'admixer_74bb1b9c6db44bb0abddf651766831fe_zone_103063_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '2246e098-847e-4322-8d15-cb86698cabe6',
        ph: 'admixer_2246e098847e43228d15cb86698cabe6_zone_103064_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '03a0960e-7de8-46ec-8305-9d77b806542e',
        ph: 'admixer_03a0960e7de846ec83059d77b806542e_zone_103065_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '3cd8a82f-eaee-43e1-b35d-bcd8953adac3',
        ph: 'admixer_3cd8a82feaee43e1b35dbcd8953adac3_zone_103066_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '977b20f5-a6e1-4dac-ad7f-fb0599bf0628',
        ph: 'admixer_977b20f5a6e14dacad7ffb0599bf0628_zone_103067_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'a73ecc6f-f65d-4e7d-bbcd-5a432e46db0e',
        ph: 'admixer_a73ecc6ff65d4e7dbbcd5a432e46db0e_zone_103068_sect_46843_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_183f7a2c4d294917a876bd6ef53af69a_zone_103061_sect_46843_site_40857',
      banner_2:
        'admixer_8a5bd9d4c80b43e8b678766dc0ce6ae2_zone_103062_sect_46843_site_40857',
      banner_3:
        'admixer_74bb1b9c6db44bb0abddf651766831fe_zone_103063_sect_46843_site_40857',
      banner_4:
        'admixer_2246e098847e43228d15cb86698cabe6_zone_103064_sect_46843_site_40857',
      banner_5:
        'admixer_03a0960e7de846ec83059d77b806542e_zone_103065_sect_46843_site_40857',
      banner_6:
        'admixer_3cd8a82feaee43e1b35dbcd8953adac3_zone_103066_sect_46843_site_40857',
      banner_7:
        'admixer_977b20f5a6e14dacad7ffb0599bf0628_zone_103067_sect_46843_site_40857',
      banner_8:
        'admixer_a73ecc6ff65d4e7dbbcd5a432e46db0e_zone_103068_sect_46843_site_40857',
    },
  },
  Production: {
    init_banner: function () {
      globalAml.defineSlot({
        z: 'b14fac49-6ac1-4ed7-8998-e58e1d35bdff',
        ph: 'admixer_b14fac496ac14ed78998e58e1d35bdff_zone_106005_sect_49075_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '9f3c2c8c-766b-4e32-acef-bcca33065f0c',
        ph: 'admixer_9f3c2c8c766b4e32acefbcca33065f0c_zone_106006_sect_49075_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'f2bc2635-1ce7-467e-a63f-42f95f778694',
        ph: 'admixer_f2bc26351ce7467ea63f42f95f778694_zone_106007_sect_49075_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '0126494a-2649-4bef-a1fe-8dbefc9cc1f9',
        ph: 'admixer_0126494a26494befa1fe8dbefc9cc1f9_zone_106008_sect_49075_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '07c34f57-9583-452a-b34d-316feaee6c51',
        ph: 'admixer_07c34f579583452ab34d316feaee6c51_zone_106009_sect_49075_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '8646e7c8-7d2a-40a4-baf3-fa6cbc88655f',
        ph: 'admixer_8646e7c87d2a40a4baf3fa6cbc88655f_zone_106010_sect_49075_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '60f433df-6912-4df9-a8d4-9ba190d604ed',
        ph: 'admixer_60f433df69124df9a8d49ba190d604ed_zone_106011_sect_49075_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'c4414b5c-c5de-4433-945f-b4f38743b628',
        ph: 'admixer_c4414b5cc5de4433945fb4f38743b628_zone_106012_sect_49075_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_b14fac496ac14ed78998e58e1d35bdff_zone_106005_sect_49075_site_40602',
      banner_2:
        'admixer_9f3c2c8c766b4e32acefbcca33065f0c_zone_106006_sect_49075_site_40602',
      banner_3:
        'admixer_f2bc26351ce7467ea63f42f95f778694_zone_106007_sect_49075_site_40602',
      banner_4:
        'admixer_0126494a26494befa1fe8dbefc9cc1f9_zone_106008_sect_49075_site_40602',
      banner_5:
        'admixer_07c34f579583452ab34d316feaee6c51_zone_106009_sect_49075_site_40602',
      banner_6:
        'admixer_8646e7c87d2a40a4baf3fa6cbc88655f_zone_106010_sect_49075_site_40602',
      banner_7:
        'admixer_60f433df69124df9a8d49ba190d604ed_zone_106011_sect_49075_site_40602',
      banner_8:
        'admixer_c4414b5cc5de4433945fb4f38743b628_zone_106012_sect_49075_site_40602',
    },
  },
}

export const BANNERS_MANE_PAGE = {
  Production: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '239e2bd2-bdec-4f71-9352-48ed1424007b',
        ph: 'admixer_239e2bd2bdec4f71935248ed1424007b_zone_102550_sect_46471_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '59440254-857f-41a8-a150-b1024f5b4e9a',
        ph: 'admixer_59440254857f41a8a150b1024f5b4e9a_zone_102551_sect_46471_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '38ecf962-a2c7-44c4-b42c-40a6760e0bbf',
        ph: 'admixer_38ecf962a2c744c4b42c40a6760e0bbf_zone_102552_sect_46471_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_239e2bd2bdec4f71935248ed1424007b_zone_102550_sect_46471_site_40602',
      banner_2:
        'admixer_59440254857f41a8a150b1024f5b4e9a_zone_102551_sect_46471_site_40602',
      banner_3:
        'admixer_38ecf962a2c744c4b42c40a6760e0bbf_zone_102552_sect_46471_site_40602',
    },
  },
  Staging: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '7e52e72a-3b57-44ee-b6c1-8850c14859c2',
        ph: 'admixer_7e52e72a3b5744eeb6c18850c14859c2_zone_103046_sect_46841_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '27c0cd19-85e3-4249-8a3b-7bf4abec9b1b',
        ph: 'admixer_27c0cd1985e342498a3b7bf4abec9b1b_zone_103047_sect_46841_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'a42f9591-3e67-4684-9a80-3a06b3a03c0b',
        ph: 'admixer_a42f95913e6746849a803a06b3a03c0b_zone_103048_sect_46841_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },

    banners_id: {
      banner_1:
        'admixer_7e52e72a3b5744eeb6c18850c14859c2_zone_103046_sect_46841_site_40857',
      banner_2:
        'admixer_27c0cd1985e342498a3b7bf4abec9b1b_zone_103047_sect_46841_site_40857',
      banner_3:
        'admixer_a42f95913e6746849a803a06b3a03c0b_zone_103048_sect_46841_site_40857',
    },
  },
  Development: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '7e52e72a-3b57-44ee-b6c1-8850c14859c2',
        ph: 'admixer_7e52e72a3b5744eeb6c18850c14859c2_zone_103046_sect_46841_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '27c0cd19-85e3-4249-8a3b-7bf4abec9b1b',
        ph: 'admixer_27c0cd1985e342498a3b7bf4abec9b1b_zone_103047_sect_46841_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'a42f9591-3e67-4684-9a80-3a06b3a03c0b',
        ph: 'admixer_a42f95913e6746849a803a06b3a03c0b_zone_103048_sect_46841_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },

    banners_id: {
      banner_1:
        'admixer_7e52e72a3b5744eeb6c18850c14859c2_zone_103046_sect_46841_site_40857',
      banner_2:
        'admixer_27c0cd1985e342498a3b7bf4abec9b1b_zone_103047_sect_46841_site_40857',
      banner_3:
        'admixer_a42f95913e6746849a803a06b3a03c0b_zone_103048_sect_46841_site_40857',
    },
  },
  Testing: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '7e52e72a-3b57-44ee-b6c1-8850c14859c2',
        ph: 'admixer_7e52e72a3b5744eeb6c18850c14859c2_zone_103046_sect_46841_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '27c0cd19-85e3-4249-8a3b-7bf4abec9b1b',
        ph: 'admixer_27c0cd1985e342498a3b7bf4abec9b1b_zone_103047_sect_46841_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'a42f9591-3e67-4684-9a80-3a06b3a03c0b',
        ph: 'admixer_a42f95913e6746849a803a06b3a03c0b_zone_103048_sect_46841_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },

    banners_id: {
      banner_1:
        'admixer_7e52e72a3b5744eeb6c18850c14859c2_zone_103046_sect_46841_site_40857',
      banner_2:
        'admixer_27c0cd1985e342498a3b7bf4abec9b1b_zone_103047_sect_46841_site_40857',
      banner_3:
        'admixer_a42f95913e6746849a803a06b3a03c0b_zone_103048_sect_46841_site_40857',
    },
  },
}

export const BANNERS_PRODUCT = {
  Staging: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '91b6a346-fa2a-47da-9f96-5eeed06015f3',
        ph: 'admixer_91b6a346fa2a47da9f965eeed06015f3_zone_104221_sect_47576_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '7cb9bd6a-e085-4727-b74c-fd61dfacbd63',
        ph: 'admixer_7cb9bd6ae0854727b74cfd61dfacbd63_zone_104222_sect_47576_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_91b6a346fa2a47da9f965eeed06015f3_zone_104221_sect_47576_site_40857',
      banner_2:
        'admixer_7cb9bd6ae0854727b74cfd61dfacbd63_zone_104222_sect_47576_site_40857',
    },
  },
  Development: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '91b6a346-fa2a-47da-9f96-5eeed06015f3',
        ph: 'admixer_91b6a346fa2a47da9f965eeed06015f3_zone_104221_sect_47576_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '7cb9bd6a-e085-4727-b74c-fd61dfacbd63',
        ph: 'admixer_7cb9bd6ae0854727b74cfd61dfacbd63_zone_104222_sect_47576_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_91b6a346fa2a47da9f965eeed06015f3_zone_104221_sect_47576_site_40857',
      banner_2:
        'admixer_7cb9bd6ae0854727b74cfd61dfacbd63_zone_104222_sect_47576_site_40857',
    },
  },
  Testing: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '91b6a346-fa2a-47da-9f96-5eeed06015f3',
        ph: 'admixer_91b6a346fa2a47da9f965eeed06015f3_zone_104221_sect_47576_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '7cb9bd6a-e085-4727-b74c-fd61dfacbd63',
        ph: 'admixer_7cb9bd6ae0854727b74cfd61dfacbd63_zone_104222_sect_47576_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_91b6a346fa2a47da9f965eeed06015f3_zone_104221_sect_47576_site_40857',
      banner_2:
        'admixer_7cb9bd6ae0854727b74cfd61dfacbd63_zone_104222_sect_47576_site_40857',
    },
  },
  Production: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '30840ad9-d023-4c94-a672-4f40ca23b375',
        ph: 'admixer_30840ad9d0234c94a6724f40ca23b375_zone_102561_sect_46478_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '8c41995e-8fdb-46f1-8b9f-c49935a211be',
        ph: 'admixer_8c41995e8fdb46f18b9fc49935a211be_zone_106004_sect_46478_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_8c41995e8fdb46f18b9fc49935a211be_zone_106004_sect_46478_site_40602',
      banner_2:
        'admixer_30840ad9d0234c94a6724f40ca23b375_zone_102561_sect_46478_site_40602',
    },
  },
}

export const BANNERS_PRODUCTS_LIST = {
  Production: {
    init_banner: categoryId =>
      function () {
        if (categoryId) {
          globalAml.setConfig({
            ortb2: {
              site: {
                page_category: [`UMC${categoryId}`],
              },
            },
          })
        }

        globalAml.defineSlot({
          z: 'c5432fdd-d869-4c8e-908d-d3af24b21061',
          ph: 'admixer_c5432fddd8694c8e908dd3af24b21061_zone_106021_sect_49077_site_40602',
          i: 'inv-nets',
          s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
          sender: 'admixer',
        })
        globalAml.defineSlot({
          z: '19e86e24-5eb4-4deb-a5bb-2ea97e0711c9',
          ph: 'admixer_19e86e245eb44deba5bb2ea97e0711c9_zone_106022_sect_49077_site_40602',
          i: 'inv-nets',
          s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
          sender: 'admixer',
        })
        globalAml.defineSlot({
          z: 'dd9a9919-d06b-4551-86dd-e45c64908afc',
          ph: 'admixer_dd9a9919d06b455186dde45c64908afc_zone_106023_sect_49077_site_40602',
          i: 'inv-nets',
          s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
          sender: 'admixer',
        })
        globalAml.defineSlot({
          z: '38c82fb7-1f85-48d1-9b86-5a4a7ee4aac5',
          ph: 'admixer_38c82fb71f8548d19b865a4a7ee4aac5_zone_106024_sect_49077_site_40602',
          i: 'inv-nets',
          s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
          sender: 'admixer',
        })
        globalAml.defineSlot({
          z: 'feaef22c-5c2e-474c-b608-559602bc6d10',
          ph: 'admixer_feaef22c5c2e474cb608559602bc6d10_zone_106025_sect_49077_site_40602',
          i: 'inv-nets',
          s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
          sender: 'admixer',
        })
        globalAml.defineSlot({
          z: '1f8aaa52-d495-4eb0-a7f9-0da6d7b8ce7c',
          ph: 'admixer_1f8aaa52d4954eb0a7f90da6d7b8ce7c_zone_106026_sect_49077_site_40602',
          i: 'inv-nets',
          s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
          sender: 'admixer',
        })
        globalAml.defineSlot({
          z: 'b5ca67be-3a33-49bf-b5f1-53917d7076ed',
          ph: 'admixer_b5ca67be3a3349bfb5f153917d7076ed_zone_106027_sect_49077_site_40602',
          i: 'inv-nets',
          s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
          sender: 'admixer',
        })
        globalAml.defineSlot({
          z: 'fd1cb59f-03d0-48f4-a029-5e594357f13a',
          ph: 'admixer_fd1cb59f03d048f4a0295e594357f13a_zone_106028_sect_49077_site_40602',
          i: 'inv-nets',
          s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
          sender: 'admixer',
        })
        globalAml.singleRequest('admixer')
      },
    banners_id: {
      banner_1:
        'admixer_c5432fddd8694c8e908dd3af24b21061_zone_106021_sect_49077_site_40602',
      banner_2:
        'admixer_19e86e245eb44deba5bb2ea97e0711c9_zone_106022_sect_49077_site_40602',
      banner_3:
        'admixer_dd9a9919d06b455186dde45c64908afc_zone_106023_sect_49077_site_40602',
      banner_4:
        'admixer_38c82fb71f8548d19b865a4a7ee4aac5_zone_106024_sect_49077_site_40602',
      banner_5:
        'admixer_feaef22c5c2e474cb608559602bc6d10_zone_106025_sect_49077_site_40602',
      banner_6:
        'admixer_1f8aaa52d4954eb0a7f90da6d7b8ce7c_zone_106026_sect_49077_site_40602',
      banner_7:
        'admixer_b5ca67be3a3349bfb5f153917d7076ed_zone_106027_sect_49077_site_40602',
      banner_8:
        'admixer_fd1cb59f03d048f4a0295e594357f13a_zone_106028_sect_49077_site_40602',
    },
  },
  Staging: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '01202233-923f-4d2a-b1b0-ae83a06caa1e',
        ph: 'admixer_01202233923f4d2ab1b0ae83a06caa1e_zone_103069_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '0e55c800-f6cc-43da-96d9-6a8ac0dccd6e',
        ph: 'admixer_0e55c800f6cc43da96d96a8ac0dccd6e_zone_103070_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '88c8e22a-0a1c-4add-bf34-a807ce449510',
        ph: 'admixer_88c8e22a0a1c4addbf34a807ce449510_zone_104200_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'fcbfe022-6cab-45e2-9d54-d4fb27e604ca',
        ph: 'admixer_fcbfe0226cab45e29d54d4fb27e604ca_zone_104201_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '864b84cd-1318-4f77-9bc8-a39e29535156',
        ph: 'admixer_864b84cd13184f779bc8a39e29535156_zone_104202_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'be21a566-b068-4e87-ad80-7c12ca48523d',
        ph: 'admixer_be21a566b0684e87ad807c12ca48523d_zone_104203_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '2998a431-08ee-4ecd-bee0-27a8943bcff1',
        ph: 'admixer_2998a43108ee4ecdbee027a8943bcff1_zone_104204_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '0842831a-d94d-4b9f-b6ef-d5ea0c26091d',
        ph: 'admixer_0842831ad94d4b9fb6efd5ea0c26091d_zone_104205_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_01202233923f4d2ab1b0ae83a06caa1e_zone_103069_sect_46844_site_40857',
      banner_2:
        'admixer_0e55c800f6cc43da96d96a8ac0dccd6e_zone_103070_sect_46844_site_40857',
      banner_3:
        'admixer_88c8e22a0a1c4addbf34a807ce449510_zone_104200_sect_46844_site_40857',
      banner_4:
        'admixer_fcbfe0226cab45e29d54d4fb27e604ca_zone_104201_sect_46844_site_40857',
      banner_5:
        'admixer_864b84cd13184f779bc8a39e29535156_zone_104202_sect_46844_site_40857',
      banner_6:
        'admixer_be21a566b0684e87ad807c12ca48523d_zone_104203_sect_46844_site_40857',
      banner_7:
        'admixer_2998a43108ee4ecdbee027a8943bcff1_zone_104204_sect_46844_site_40857',
      banner_8:
        'admixer_0842831ad94d4b9fb6efd5ea0c26091d_zone_104205_sect_46844_site_40857',
    },
  },
  Testing: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '01202233-923f-4d2a-b1b0-ae83a06caa1e',
        ph: 'admixer_01202233923f4d2ab1b0ae83a06caa1e_zone_103069_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '0e55c800-f6cc-43da-96d9-6a8ac0dccd6e',
        ph: 'admixer_0e55c800f6cc43da96d96a8ac0dccd6e_zone_103070_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '88c8e22a-0a1c-4add-bf34-a807ce449510',
        ph: 'admixer_88c8e22a0a1c4addbf34a807ce449510_zone_104200_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'fcbfe022-6cab-45e2-9d54-d4fb27e604ca',
        ph: 'admixer_fcbfe0226cab45e29d54d4fb27e604ca_zone_104201_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '864b84cd-1318-4f77-9bc8-a39e29535156',
        ph: 'admixer_864b84cd13184f779bc8a39e29535156_zone_104202_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'be21a566-b068-4e87-ad80-7c12ca48523d',
        ph: 'admixer_be21a566b0684e87ad807c12ca48523d_zone_104203_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '2998a431-08ee-4ecd-bee0-27a8943bcff1',
        ph: 'admixer_2998a43108ee4ecdbee027a8943bcff1_zone_104204_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '0842831a-d94d-4b9f-b6ef-d5ea0c26091d',
        ph: 'admixer_0842831ad94d4b9fb6efd5ea0c26091d_zone_104205_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_01202233923f4d2ab1b0ae83a06caa1e_zone_103069_sect_46844_site_40857',
      banner_2:
        'admixer_0e55c800f6cc43da96d96a8ac0dccd6e_zone_103070_sect_46844_site_40857',
      banner_3:
        'admixer_88c8e22a0a1c4addbf34a807ce449510_zone_104200_sect_46844_site_40857',
      banner_4:
        'admixer_fcbfe0226cab45e29d54d4fb27e604ca_zone_104201_sect_46844_site_40857',
      banner_5:
        'admixer_864b84cd13184f779bc8a39e29535156_zone_104202_sect_46844_site_40857',
      banner_6:
        'admixer_be21a566b0684e87ad807c12ca48523d_zone_104203_sect_46844_site_40857',
      banner_7:
        'admixer_2998a43108ee4ecdbee027a8943bcff1_zone_104204_sect_46844_site_40857',
      banner_8:
        'admixer_0842831ad94d4b9fb6efd5ea0c26091d_zone_104205_sect_46844_site_40857',
    },
  },
  Development: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '01202233-923f-4d2a-b1b0-ae83a06caa1e',
        ph: 'admixer_01202233923f4d2ab1b0ae83a06caa1e_zone_103069_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '0e55c800-f6cc-43da-96d9-6a8ac0dccd6e',
        ph: 'admixer_0e55c800f6cc43da96d96a8ac0dccd6e_zone_103070_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '88c8e22a-0a1c-4add-bf34-a807ce449510',
        ph: 'admixer_88c8e22a0a1c4addbf34a807ce449510_zone_104200_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'fcbfe022-6cab-45e2-9d54-d4fb27e604ca',
        ph: 'admixer_fcbfe0226cab45e29d54d4fb27e604ca_zone_104201_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '864b84cd-1318-4f77-9bc8-a39e29535156',
        ph: 'admixer_864b84cd13184f779bc8a39e29535156_zone_104202_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'be21a566-b068-4e87-ad80-7c12ca48523d',
        ph: 'admixer_be21a566b0684e87ad807c12ca48523d_zone_104203_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '2998a431-08ee-4ecd-bee0-27a8943bcff1',
        ph: 'admixer_2998a43108ee4ecdbee027a8943bcff1_zone_104204_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '0842831a-d94d-4b9f-b6ef-d5ea0c26091d',
        ph: 'admixer_0842831ad94d4b9fb6efd5ea0c26091d_zone_104205_sect_46844_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_01202233923f4d2ab1b0ae83a06caa1e_zone_103069_sect_46844_site_40857',
      banner_2:
        'admixer_0e55c800f6cc43da96d96a8ac0dccd6e_zone_103070_sect_46844_site_40857',
      banner_3:
        'admixer_88c8e22a0a1c4addbf34a807ce449510_zone_104200_sect_46844_site_40857',
      banner_4:
        'admixer_fcbfe0226cab45e29d54d4fb27e604ca_zone_104201_sect_46844_site_40857',
      banner_5:
        'admixer_864b84cd13184f779bc8a39e29535156_zone_104202_sect_46844_site_40857',
      banner_6:
        'admixer_be21a566b0684e87ad807c12ca48523d_zone_104203_sect_46844_site_40857',
      banner_7:
        'admixer_2998a43108ee4ecdbee027a8943bcff1_zone_104204_sect_46844_site_40857',
      banner_8:
        'admixer_0842831ad94d4b9fb6efd5ea0c26091d_zone_104205_sect_46844_site_40857',
    },
  },
}

export const BANNERS_COMPONENTS = {
  Production: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '88675e50-7d6c-42b7-ae47-d091c61d3f30',
        ph: 'admixer_88675e507d6c42b7ae47d091c61d3f30_zone_106013_sect_49076_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '5454f9bb-005b-44e4-9352-3c3367694dda',
        ph: 'admixer_5454f9bb005b44e493523c3367694dda_zone_106014_sect_49076_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'b4623934-71c6-4d7f-baeb-8bb0173e6ee0',
        ph: 'admixer_b462393471c64d7fbaeb8bb0173e6ee0_zone_106015_sect_49076_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '7ab0636a-7968-4d59-9aef-4a0370f9a859',
        ph: 'admixer_7ab0636a79684d599aef4a0370f9a859_zone_106016_sect_49076_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '8ab599fe-7ccf-4d57-bf8d-a13db9e9a350',
        ph: 'admixer_8ab599fe7ccf4d57bf8da13db9e9a350_zone_106017_sect_49076_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '799f649e-490f-42ff-b958-902be8694da5',
        ph: 'admixer_799f649e490f42ffb958902be8694da5_zone_106018_sect_49076_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'bf412372-76c7-453f-b48c-a7d4af5ee7a5',
        ph: 'admixer_bf41237276c7453fb48ca7d4af5ee7a5_zone_106019_sect_49076_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '4e812625-581d-4bf2-894f-08b4036a7102',
        ph: 'admixer_4e812625581d4bf2894f08b4036a7102_zone_106020_sect_49076_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_88675e507d6c42b7ae47d091c61d3f30_zone_106013_sect_49076_site_40602',
      banner_2:
        'admixer_5454f9bb005b44e493523c3367694dda_zone_106014_sect_49076_site_40602',
      banner_3:
        'admixer_b462393471c64d7fbaeb8bb0173e6ee0_zone_106015_sect_49076_site_40602',
      banner_4:
        'admixer_7ab0636a79684d599aef4a0370f9a859_zone_106016_sect_49076_site_40602',
      banner_5:
        'admixer_8ab599fe7ccf4d57bf8da13db9e9a350_zone_106017_sect_49076_site_40602',
      banner_6:
        'admixer_799f649e490f42ffb958902be8694da5_zone_106018_sect_49076_site_40602',
      banner_7:
        'admixer_bf41237276c7453fb48ca7d4af5ee7a5_zone_106019_sect_49076_site_40602',
      banner_8:
        'admixer_4e812625581d4bf2894f08b4036a7102_zone_106020_sect_49076_site_40602',
    },
  },
  Staging: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '6ad8325a-3b00-4425-9224-191f8a00a225',
        ph: 'admixer_6ad8325a3b0044259224191f8a00a225_zone_104226_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '8f080d13-4154-4046-a3f4-453d1744e0d0',
        ph: 'admixer_8f080d1341544046a3f4453d1744e0d0_zone_104227_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '1a694f90-58cb-47b1-a32a-37ee2c83eead',
        ph: 'admixer_1a694f9058cb47b1a32a37ee2c83eead_zone_104228_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '3898587b-0c3e-443b-b47d-7d3ace920ffd',
        ph: 'admixer_3898587b0c3e443bb47d7d3ace920ffd_zone_104229_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '68d2aa58-c486-4110-8d5d-f314df9c9e45',
        ph: 'admixer_68d2aa58c48641108d5df314df9c9e45_zone_104230_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '01381cfb-d746-44d0-9652-7f037b57d4eb',
        ph: 'admixer_01381cfbd74644d096527f037b57d4eb_zone_104231_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '21fffa57-66ad-4288-9336-91193db58810',
        ph: 'admixer_21fffa5766ad4288933691193db58810_zone_104232_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '07e55404-ada8-45d4-85d0-7e2658329042',
        ph: 'admixer_07e55404ada845d485d07e2658329042_zone_104233_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_6ad8325a3b0044259224191f8a00a225_zone_104226_sect_47594_site_40857',
      banner_2:
        'admixer_8f080d1341544046a3f4453d1744e0d0_zone_104227_sect_47594_site_40857',
      banner_3:
        'admixer_1a694f9058cb47b1a32a37ee2c83eead_zone_104228_sect_47594_site_40857',
      banner_4:
        'admixer_3898587b0c3e443bb47d7d3ace920ffd_zone_104229_sect_47594_site_40857',
      banner_5:
        'admixer_68d2aa58c48641108d5df314df9c9e45_zone_104230_sect_47594_site_40857',
      banner_6:
        'admixer_01381cfbd74644d096527f037b57d4eb_zone_104231_sect_47594_site_40857',
      banner_7:
        'admixer_21fffa5766ad4288933691193db58810_zone_104232_sect_47594_site_40857',
      banner_8:
        'admixer_07e55404ada845d485d07e2658329042_zone_104233_sect_47594_site_40857',
    },
  },
  Development: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '6ad8325a-3b00-4425-9224-191f8a00a225',
        ph: 'admixer_6ad8325a3b0044259224191f8a00a225_zone_104226_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '8f080d13-4154-4046-a3f4-453d1744e0d0',
        ph: 'admixer_8f080d1341544046a3f4453d1744e0d0_zone_104227_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '1a694f90-58cb-47b1-a32a-37ee2c83eead',
        ph: 'admixer_1a694f9058cb47b1a32a37ee2c83eead_zone_104228_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '3898587b-0c3e-443b-b47d-7d3ace920ffd',
        ph: 'admixer_3898587b0c3e443bb47d7d3ace920ffd_zone_104229_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '68d2aa58-c486-4110-8d5d-f314df9c9e45',
        ph: 'admixer_68d2aa58c48641108d5df314df9c9e45_zone_104230_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '01381cfb-d746-44d0-9652-7f037b57d4eb',
        ph: 'admixer_01381cfbd74644d096527f037b57d4eb_zone_104231_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '21fffa57-66ad-4288-9336-91193db58810',
        ph: 'admixer_21fffa5766ad4288933691193db58810_zone_104232_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '07e55404-ada8-45d4-85d0-7e2658329042',
        ph: 'admixer_07e55404ada845d485d07e2658329042_zone_104233_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_6ad8325a3b0044259224191f8a00a225_zone_104226_sect_47594_site_40857',
      banner_2:
        'admixer_8f080d1341544046a3f4453d1744e0d0_zone_104227_sect_47594_site_40857',
      banner_3:
        'admixer_1a694f9058cb47b1a32a37ee2c83eead_zone_104228_sect_47594_site_40857',
      banner_4:
        'admixer_3898587b0c3e443bb47d7d3ace920ffd_zone_104229_sect_47594_site_40857',
      banner_5:
        'admixer_68d2aa58c48641108d5df314df9c9e45_zone_104230_sect_47594_site_40857',
      banner_6:
        'admixer_01381cfbd74644d096527f037b57d4eb_zone_104231_sect_47594_site_40857',
      banner_7:
        'admixer_21fffa5766ad4288933691193db58810_zone_104232_sect_47594_site_40857',
      banner_8:
        'admixer_07e55404ada845d485d07e2658329042_zone_104233_sect_47594_site_40857',
    },
  },
  Testing: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '6ad8325a-3b00-4425-9224-191f8a00a225',
        ph: 'admixer_6ad8325a3b0044259224191f8a00a225_zone_104226_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '8f080d13-4154-4046-a3f4-453d1744e0d0',
        ph: 'admixer_8f080d1341544046a3f4453d1744e0d0_zone_104227_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '1a694f90-58cb-47b1-a32a-37ee2c83eead',
        ph: 'admixer_1a694f9058cb47b1a32a37ee2c83eead_zone_104228_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '3898587b-0c3e-443b-b47d-7d3ace920ffd',
        ph: 'admixer_3898587b0c3e443bb47d7d3ace920ffd_zone_104229_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '68d2aa58-c486-4110-8d5d-f314df9c9e45',
        ph: 'admixer_68d2aa58c48641108d5df314df9c9e45_zone_104230_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '01381cfb-d746-44d0-9652-7f037b57d4eb',
        ph: 'admixer_01381cfbd74644d096527f037b57d4eb_zone_104231_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '21fffa57-66ad-4288-9336-91193db58810',
        ph: 'admixer_21fffa5766ad4288933691193db58810_zone_104232_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '07e55404-ada8-45d4-85d0-7e2658329042',
        ph: 'admixer_07e55404ada845d485d07e2658329042_zone_104233_sect_47594_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_6ad8325a3b0044259224191f8a00a225_zone_104226_sect_47594_site_40857',
      banner_2:
        'admixer_8f080d1341544046a3f4453d1744e0d0_zone_104227_sect_47594_site_40857',
      banner_3:
        'admixer_1a694f9058cb47b1a32a37ee2c83eead_zone_104228_sect_47594_site_40857',
      banner_4:
        'admixer_3898587b0c3e443bb47d7d3ace920ffd_zone_104229_sect_47594_site_40857',
      banner_5:
        'admixer_68d2aa58c48641108d5df314df9c9e45_zone_104230_sect_47594_site_40857',
      banner_6:
        'admixer_01381cfbd74644d096527f037b57d4eb_zone_104231_sect_47594_site_40857',
      banner_7:
        'admixer_21fffa5766ad4288933691193db58810_zone_104232_sect_47594_site_40857',
      banner_8:
        'admixer_07e55404ada845d485d07e2658329042_zone_104233_sect_47594_site_40857',
    },
  },
}

export const BANNERS_SALE_DAY = {
  Production: {
    init_banner: function () {
      globalAml.defineSlot({
        z: 'efa28675-0ea0-4717-a9df-223dc82d5c7a',
        ph: 'admixer_efa286750ea04717a9df223dc82d5c7a_zone_106075_sect_49106_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'f4fa813d-611f-4393-b0e0-87be27f01e6b',
        ph: 'admixer_f4fa813d611f4393b0e087be27f01e6b_zone_106076_sect_49106_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '25a31089-93bf-49aa-bf7f-d3847d5d99a4',
        ph: 'admixer_25a3108993bf49aabf7fd3847d5d99a4_zone_106077_sect_49106_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '147a55aa-1701-4546-a9dd-b6bc9efbf26f',
        ph: 'admixer_147a55aa17014546a9ddb6bc9efbf26f_zone_106079_sect_49106_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '72be32a5-e22e-4e75-bdc5-36bd17e9c87d',
        ph: 'admixer_72be32a5e22e4e75bdc536bd17e9c87d_zone_106080_sect_49106_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'f98be7da-194b-4361-b09e-410917a8b4c0',
        ph: 'admixer_f98be7da194b4361b09e410917a8b4c0_zone_106081_sect_49106_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '8b7915f0-c94f-4943-bc0b-fe2db78f363f',
        ph: 'admixer_8b7915f0c94f4943bc0bfe2db78f363f_zone_106082_sect_49106_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'cc062a42-1e45-4e9d-867b-9eadb2637f46',
        ph: 'admixer_cc062a421e454e9d867b9eadb2637f46_zone_106083_sect_49106_site_40602',
        i: 'inv-nets',
        s: '860dfd5a-6007-4a7d-9bab-5e8b93c73cbd',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_efa286750ea04717a9df223dc82d5c7a_zone_106075_sect_49106_site_40602',
      banner_2:
        'admixer_f4fa813d611f4393b0e087be27f01e6b_zone_106076_sect_49106_site_40602',
      banner_3:
        'admixer_25a3108993bf49aabf7fd3847d5d99a4_zone_106077_sect_49106_site_40602',
      banner_4:
        'admixer_147a55aa17014546a9ddb6bc9efbf26f_zone_106079_sect_49106_site_40602',
      banner_5:
        'admixer_72be32a5e22e4e75bdc536bd17e9c87d_zone_106080_sect_49106_site_40602',
      banner_6:
        'admixer_f98be7da194b4361b09e410917a8b4c0_zone_106081_sect_49106_site_40602',
      banner_7:
        'admixer_8b7915f0c94f4943bc0bfe2db78f363f_zone_106082_sect_49106_site_40602',
      banner_8:
        'admixer_cc062a421e454e9d867b9eadb2637f46_zone_106083_sect_49106_site_40602',
    },
  },
  Staging: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '384ef4d1-adcf-413f-a7f3-31bfdc9a3498',
        ph: 'admixer_384ef4d1adcf413fa7f331bfdc9a3498_zone_103053_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '52395090-284e-449f-9607-6fbcf3d9b830',
        ph: 'admixer_52395090284e449f96076fbcf3d9b830_zone_103054_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'c1c81fff-e939-496e-a528-2327a4510197',
        ph: 'admixer_c1c81fffe939496ea5282327a4510197_zone_103055_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '956d661e-88dd-40ef-8001-b87e56ed4adf',
        ph: 'admixer_956d661e88dd40ef8001b87e56ed4adf_zone_103056_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '9a894743-66af-47cd-ba51-27fe94fabed0',
        ph: 'admixer_9a89474366af47cdba5127fe94fabed0_zone_103057_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '90fa1f68-6081-47be-88a9-41f35a2481bc',
        ph: 'admixer_90fa1f68608147be88a941f35a2481bc_zone_103058_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'ba28c8a0-7581-42ee-a13c-3f635597d347',
        ph: 'admixer_ba28c8a0758142eea13c3f635597d347_zone_103059_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'b2e30a2b-9536-4ba6-aa3b-b4311f202fd1',
        ph: 'admixer_b2e30a2b95364ba6aa3bb4311f202fd1_zone_103060_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_384ef4d1adcf413fa7f331bfdc9a3498_zone_103053_sect_46842_site_40857',
      banner_2:
        'admixer_52395090284e449f96076fbcf3d9b830_zone_103054_sect_46842_site_40857',
      banner_3:
        'admixer_c1c81fffe939496ea5282327a4510197_zone_103055_sect_46842_site_40857',
      banner_4:
        'admixer_956d661e88dd40ef8001b87e56ed4adf_zone_103056_sect_46842_site_40857',
      banner_5:
        'admixer_9a89474366af47cdba5127fe94fabed0_zone_103057_sect_46842_site_40857',
      banner_6:
        'admixer_90fa1f68608147be88a941f35a2481bc_zone_103058_sect_46842_site_40857',
      banner_7:
        'admixer_ba28c8a0758142eea13c3f635597d347_zone_103059_sect_46842_site_40857',
      banner_8:
        'admixer_b2e30a2b95364ba6aa3bb4311f202fd1_zone_103060_sect_46842_site_40857',
    },
  },
  Testing: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '384ef4d1-adcf-413f-a7f3-31bfdc9a3498',
        ph: 'admixer_384ef4d1adcf413fa7f331bfdc9a3498_zone_103053_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '52395090-284e-449f-9607-6fbcf3d9b830',
        ph: 'admixer_52395090284e449f96076fbcf3d9b830_zone_103054_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'c1c81fff-e939-496e-a528-2327a4510197',
        ph: 'admixer_c1c81fffe939496ea5282327a4510197_zone_103055_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '956d661e-88dd-40ef-8001-b87e56ed4adf',
        ph: 'admixer_956d661e88dd40ef8001b87e56ed4adf_zone_103056_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '9a894743-66af-47cd-ba51-27fe94fabed0',
        ph: 'admixer_9a89474366af47cdba5127fe94fabed0_zone_103057_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '90fa1f68-6081-47be-88a9-41f35a2481bc',
        ph: 'admixer_90fa1f68608147be88a941f35a2481bc_zone_103058_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'ba28c8a0-7581-42ee-a13c-3f635597d347',
        ph: 'admixer_ba28c8a0758142eea13c3f635597d347_zone_103059_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'b2e30a2b-9536-4ba6-aa3b-b4311f202fd1',
        ph: 'admixer_b2e30a2b95364ba6aa3bb4311f202fd1_zone_103060_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_384ef4d1adcf413fa7f331bfdc9a3498_zone_103053_sect_46842_site_40857',
      banner_2:
        'admixer_52395090284e449f96076fbcf3d9b830_zone_103054_sect_46842_site_40857',
      banner_3:
        'admixer_c1c81fffe939496ea5282327a4510197_zone_103055_sect_46842_site_40857',
      banner_4:
        'admixer_956d661e88dd40ef8001b87e56ed4adf_zone_103056_sect_46842_site_40857',
      banner_5:
        'admixer_9a89474366af47cdba5127fe94fabed0_zone_103057_sect_46842_site_40857',
      banner_6:
        'admixer_90fa1f68608147be88a941f35a2481bc_zone_103058_sect_46842_site_40857',
      banner_7:
        'admixer_ba28c8a0758142eea13c3f635597d347_zone_103059_sect_46842_site_40857',
      banner_8:
        'admixer_b2e30a2b95364ba6aa3bb4311f202fd1_zone_103060_sect_46842_site_40857',
    },
  },
  Development: {
    init_banner: function () {
      globalAml.defineSlot({
        z: '384ef4d1-adcf-413f-a7f3-31bfdc9a3498',
        ph: 'admixer_384ef4d1adcf413fa7f331bfdc9a3498_zone_103053_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '52395090-284e-449f-9607-6fbcf3d9b830',
        ph: 'admixer_52395090284e449f96076fbcf3d9b830_zone_103054_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'c1c81fff-e939-496e-a528-2327a4510197',
        ph: 'admixer_c1c81fffe939496ea5282327a4510197_zone_103055_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '956d661e-88dd-40ef-8001-b87e56ed4adf',
        ph: 'admixer_956d661e88dd40ef8001b87e56ed4adf_zone_103056_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '9a894743-66af-47cd-ba51-27fe94fabed0',
        ph: 'admixer_9a89474366af47cdba5127fe94fabed0_zone_103057_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: '90fa1f68-6081-47be-88a9-41f35a2481bc',
        ph: 'admixer_90fa1f68608147be88a941f35a2481bc_zone_103058_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'ba28c8a0-7581-42ee-a13c-3f635597d347',
        ph: 'admixer_ba28c8a0758142eea13c3f635597d347_zone_103059_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.defineSlot({
        z: 'b2e30a2b-9536-4ba6-aa3b-b4311f202fd1',
        ph: 'admixer_b2e30a2b95364ba6aa3bb4311f202fd1_zone_103060_sect_46842_site_40857',
        i: 'inv-nets',
        s: 'b8f6d017-9383-4343-b424-5a8af0bd7a75',
        sender: 'admixer',
      })
      globalAml.singleRequest('admixer')
    },
    banners_id: {
      banner_1:
        'admixer_384ef4d1adcf413fa7f331bfdc9a3498_zone_103053_sect_46842_site_40857',
      banner_2:
        'admixer_52395090284e449f96076fbcf3d9b830_zone_103054_sect_46842_site_40857',
      banner_3:
        'admixer_c1c81fffe939496ea5282327a4510197_zone_103055_sect_46842_site_40857',
      banner_4:
        'admixer_956d661e88dd40ef8001b87e56ed4adf_zone_103056_sect_46842_site_40857',
      banner_5:
        'admixer_9a89474366af47cdba5127fe94fabed0_zone_103057_sect_46842_site_40857',
      banner_6:
        'admixer_90fa1f68608147be88a941f35a2481bc_zone_103058_sect_46842_site_40857',
      banner_7:
        'admixer_ba28c8a0758142eea13c3f635597d347_zone_103059_sect_46842_site_40857',
      banner_8:
        'admixer_b2e30a2b95364ba6aa3bb4311f202fd1_zone_103060_sect_46842_site_40857',
    },
  },
}

export const ALL_COUNTRIES = [
  ['Afghanistan (‫افغانستان‬‎)', 'af', '93'],
  ['Albania (Shqipëri)', 'al', '355'],
  ['Algeria (‫الجزائر‬‎)', 'dz', '213'],
  ['American Samoa', 'as', '1684'],
  ['Andorra', 'ad', '376'],
  ['Angola', 'ao', '244'],
  ['Anguilla', 'ai', '1264'],
  ['Antigua and Barbuda', 'ag', '1268'],
  ['Argentina', 'ar', '54'],
  ['Armenia (Հայաստան)', 'am', '374'],
  ['Aruba', 'aw', '297'],
  ['Australia', 'au', '61', 0],
  ['Austria (Österreich)', 'at', '43'],
  ['Azerbaijan (Azərbaycan)', 'az', '994'],
  ['Bahamas', 'bs', '1242'],
  ['Bahrain (‫البحرين‬‎)', 'bh', '973'],
  ['Bangladesh (বাংলাদেশ)', 'bd', '880'],
  ['Barbados', 'bb', '1246'],
  ['Belarus (Беларусь)', 'by', '375'],
  ['Belgium (België)', 'be', '32'],
  ['Belize', 'bz', '501'],
  ['Benin (Bénin)', 'bj', '229'],
  ['Bermuda', 'bm', '1441'],
  ['Bhutan (འབྲུག)', 'bt', '975'],
  ['Bolivia', 'bo', '591'],
  ['Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '387'],
  ['Botswana', 'bw', '267'],
  ['Brazil (Brasil)', 'br', '55'],
  ['British Indian Ocean Territory', 'io', '246'],
  ['British Virgin Islands', 'vg', '1284'],
  ['Brunei', 'bn', '673'],
  ['Bulgaria (България)', 'bg', '359'],
  ['Burkina Faso', 'bf', '226'],
  ['Burundi (Uburundi)', 'bi', '257'],
  ['Cambodia (កម្ពុជា)', 'kh', '855'],
  ['Cameroon (Cameroun)', 'cm', '237'],
  [
    'Canada',
    'ca',
    '1',
    1,
    [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  ],
  ['Cape Verde (Kabu Verdi)', 'cv', '238'],
  ['Caribbean Netherlands', 'bq', '599', 1],
  ['Cayman Islands', 'ky', '1345'],
  ['Central African Republic (République centrafricaine)', 'cf', '236'],
  ['Chad (Tchad)', 'td', '235'],
  ['Chile', 'cl', '56'],
  ['China (中国)', 'cn', '86'],
  ['Christmas Island', 'cx', '61', 2],
  ['Cocos (Keeling) Islands', 'cc', '61', 1],
  ['Colombia', 'co', '57'],
  ['Comoros (‫جزر القمر‬‎)', 'km', '269'],
  ['Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', 'cd', '243'],
  ['Congo (Republic) (Congo-Brazzaville)', 'cg', '242'],
  ['Cook Islands', 'ck', '682'],
  ['Costa Rica', 'cr', '506'],
  ['Côte d’Ivoire', 'ci', '225'],
  ['Croatia (Hrvatska)', 'hr', '385'],
  ['Cuba', 'cu', '53'],
  ['Curaçao', 'cw', '599', 0],
  ['Cyprus (Κύπρος)', 'cy', '357'],
  ['Czech Republic (Česká republika)', 'cz', '420'],
  ['Denmark (Danmark)', 'dk', '45'],
  ['Djibouti', 'dj', '253'],
  ['Dominica', 'dm', '1767'],
  [
    'Dominican Republic (República Dominicana)',
    'do',
    '1',
    2,
    ['809', '829', '849'],
  ],
  ['Ecuador', 'ec', '593'],
  ['Egypt (‫مصر‬‎)', 'eg', '20'],
  ['El Salvador', 'sv', '503'],
  ['Equatorial Guinea (Guinea Ecuatorial)', 'gq', '240'],
  ['Eritrea', 'er', '291'],
  ['Estonia (Eesti)', 'ee', '372'],
  ['Ethiopia', 'et', '251'],
  ['Falkland Islands (Islas Malvinas)', 'fk', '500'],
  ['Faroe Islands (Føroyar)', 'fo', '298'],
  ['Fiji', 'fj', '679'],
  ['Finland (Suomi)', 'fi', '358', 0],
  ['France', 'fr', '33'],
  ['French Guiana (Guyane française)', 'gf', '594'],
  ['French Polynesia (Polynésie française)', 'pf', '689'],
  ['Gabon', 'ga', '241'],
  ['Gambia', 'gm', '220'],
  ['Georgia (საქართველო)', 'ge', '995'],
  ['Germany (Deutschland)', 'de', '49'],
  ['Ghana (Gaana)', 'gh', '233'],
  ['Gibraltar', 'gi', '350'],
  ['Greece (Ελλάδα)', 'gr', '30'],
  ['Greenland (Kalaallit Nunaat)', 'gl', '299'],
  ['Grenada', 'gd', '1473'],
  ['Guadeloupe', 'gp', '590', 0],
  ['Guam', 'gu', '1671'],
  ['Guatemala', 'gt', '502'],
  ['Guernsey', 'gg', '44', 1],
  ['Guinea (Guinée)', 'gn', '224'],
  ['Guinea-Bissau (Guiné Bissau)', 'gw', '245'],
  ['Guyana', 'gy', '592'],
  ['Haiti', 'ht', '509'],
  ['Honduras', 'hn', '504'],
  ['Hong Kong (香港)', 'hk', '852'],
  ['Hungary (Magyarország)', 'hu', '36'],
  ['Iceland (Ísland)', 'is', '354'],
  ['India (भारत)', 'in', '91'],
  ['Indonesia', 'id', '62'],
  ['Iran (‫ایران‬‎)', 'ir', '98'],
  ['Iraq (‫العراق‬‎)', 'iq', '964'],
  ['Ireland', 'ie', '353'],
  ['Isle of Man', 'im', '44', 2],
  ['Israel (‫ישראל‬‎)', 'il', '972'],
  ['Italy (Italia)', 'it', '39', 0],
  ['Jamaica', 'jm', '1876'],
  ['Japan (日本)', 'jp', '81'],
  ['Jersey', 'je', '44', 3],
  ['Jordan (‫الأردن‬‎)', 'jo', '962'],
  ['Kazakhstan (Казахстан)', 'kz', '7', 1],
  ['Kenya', 'ke', '254'],
  ['Kiribati', 'ki', '686'],
  ['Kosovo', 'xk', '383'],
  ['Kuwait (‫الكويت‬‎)', 'kw', '965'],
  ['Kyrgyzstan (Кыргызстан)', 'kg', '996'],
  ['Laos (ລາວ)', 'la', '856'],
  ['Latvia (Latvija)', 'lv', '371'],
  ['Lebanon (‫لبنان‬‎)', 'lb', '961'],
  ['Lesotho', 'ls', '266'],
  ['Liberia', 'lr', '231'],
  ['Libya (‫ليبيا‬‎)', 'ly', '218'],
  ['Liechtenstein', 'li', '423'],
  ['Lithuania (Lietuva)', 'lt', '370'],
  ['Luxembourg', 'lu', '352'],
  ['Macau (澳門)', 'mo', '853'],
  ['Macedonia (FYROM) (Македонија)', 'mk', '389'],
  ['Madagascar (Madagasikara)', 'mg', '261'],
  ['Malawi', 'mw', '265'],
  ['Malaysia', 'my', '60'],
  ['Maldives', 'mv', '960'],
  ['Mali', 'ml', '223'],
  ['Malta', 'mt', '356'],
  ['Marshall Islands', 'mh', '692'],
  ['Martinique', 'mq', '596'],
  ['Mauritania (‫موريتانيا‬‎)', 'mr', '222'],
  ['Mauritius (Moris)', 'mu', '230'],
  ['Mayotte', 'yt', '262', 1],
  ['Mexico (México)', 'mx', '52'],
  ['Micronesia', 'fm', '691'],
  ['Moldova (Republica Moldova)', 'md', '373'],
  ['Monaco', 'mc', '377'],
  ['Mongolia (Монгол)', 'mn', '976'],
  ['Montenegro (Crna Gora)', 'me', '382'],
  ['Montserrat', 'ms', '1664'],
  ['Morocco (‫المغرب‬‎)', 'ma', '212', 0],
  ['Mozambique (Moçambique)', 'mz', '258'],
  ['Myanmar (Burma) (မြန်မာ)', 'mm', '95'],
  ['Namibia (Namibië)', 'na', '264'],
  ['Nauru', 'nr', '674'],
  ['Nepal (नेपाल)', 'np', '977'],
  ['Netherlands (Nederland)', 'nl', '31'],
  ['New Caledonia (Nouvelle-Calédonie)', 'nc', '687'],
  ['New Zealand', 'nz', '64'],
  ['Nicaragua', 'ni', '505'],
  ['Niger (Nijar)', 'ne', '227'],
  ['Nigeria', 'ng', '234'],
  ['Niue', 'nu', '683'],
  ['Norfolk Island', 'nf', '672'],
  ['North Korea (조선 민주주의 인민 공화국)', 'kp', '850'],
  ['Northern Mariana Islands', 'mp', '1670'],
  ['Norway (Norge)', 'no', '47', 0],
  ['Oman (‫عُمان‬‎)', 'om', '968'],
  ['Pakistan (‫پاکستان‬‎)', 'pk', '92'],
  ['Palau', 'pw', '680'],
  ['Palestine (‫فلسطين‬‎)', 'ps', '970'],
  ['Panama (Panamá)', 'pa', '507'],
  ['Papua New Guinea', 'pg', '675'],
  ['Paraguay', 'py', '595'],
  ['Peru (Perú)', 'pe', '51'],
  ['Philippines', 'ph', '63'],
  ['Poland (Polska)', 'pl', '48'],
  ['Portugal', 'pt', '351'],
  ['Puerto Rico', 'pr', '1', 3, ['787', '939']],
  ['Qatar (‫قطر‬‎)', 'qa', '974'],
  ['Réunion (La Réunion)', 're', '262', 0],
  ['Romania (România)', 'ro', '40'],
  ['Russia (Россия)', 'ru', '7', 0],
  ['Rwanda', 'rw', '250'],
  ['Saint Barthélemy', 'bl', '590', 1],
  ['Saint Helena', 'sh', '290'],
  ['Saint Kitts and Nevis', 'kn', '1869'],
  ['Saint Lucia', 'lc', '1758'],
  ['Saint Martin (Saint-Martin (partie française))', 'mf', '590', 2],
  ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', 'pm', '508'],
  ['Saint Vincent and the Grenadines', 'vc', '1784'],
  ['Samoa', 'ws', '685'],
  ['San Marino', 'sm', '378'],
  ['São Tomé and Príncipe (São Tomé e Príncipe)', 'st', '239'],
  ['Saudi Arabia (‫المملكة العربية السعودية‬‎)', 'sa', '966'],
  ['Senegal (Sénégal)', 'sn', '221'],
  ['Serbia (Србија)', 'rs', '381'],
  ['Seychelles', 'sc', '248'],
  ['Sierra Leone', 'sl', '232'],
  ['Singapore', 'sg', '65'],
  ['Sint Maarten', 'sx', '1721'],
  ['Slovakia (Slovensko)', 'sk', '421'],
  ['Slovenia (Slovenija)', 'si', '386'],
  ['Solomon Islands', 'sb', '677'],
  ['Somalia (Soomaaliya)', 'so', '252'],
  ['South Africa', 'za', '27'],
  ['South Korea (대한민국)', 'kr', '82'],
  ['South Sudan (‫جنوب السودان‬‎)', 'ss', '211'],
  ['Spain (España)', 'es', '34'],
  ['Sri Lanka (ශ්‍රී ලංකාව)', 'lk', '94'],
  ['Sudan (‫السودان‬‎)', 'sd', '249'],
  ['Suriname', 'sr', '597'],
  ['Svalbard and Jan Mayen', 'sj', '47', 1],
  ['Swaziland', 'sz', '268'],
  ['Sweden (Sverige)', 'se', '46'],
  ['Switzerland (Schweiz)', 'ch', '41'],
  ['Syria (‫سوريا‬‎)', 'sy', '963'],
  ['Taiwan (台灣)', 'tw', '886'],
  ['Tajikistan', 'tj', '992'],
  ['Tanzania', 'tz', '255'],
  ['Thailand (ไทย)', 'th', '66'],
  ['Timor-Leste', 'tl', '670'],
  ['Togo', 'tg', '228'],
  ['Tokelau', 'tk', '690'],
  ['Tonga', 'to', '676'],
  ['Trinidad and Tobago', 'tt', '1868'],
  ['Tunisia (‫تونس‬‎)', 'tn', '216'],
  ['Turkey (Türkiye)', 'tr', '90'],
  ['Turkmenistan', 'tm', '993'],
  ['Turks and Caicos Islands', 'tc', '1649'],
  ['Tuvalu', 'tv', '688'],
  ['U.S. Virgin Islands', 'vi', '1340'],
  ['Uganda', 'ug', '256'],
  ['Ukraine (Україна)', 'ua', '380'],
  ['United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', 'ae', '971'],
  ['United Kingdom', 'gb', '44', 0],
  ['United States', 'us', '1', 0],
  ['Uruguay', 'uy', '598'],
  ['Uzbekistan (Oʻzbekiston)', 'uz', '998'],
  ['Vanuatu', 'vu', '678'],
  ['Vatican City (Città del Vaticano)', 'va', '39', 1],
  ['Venezuela', 've', '58'],
  ['Vietnam (Việt Nam)', 'vn', '84'],
  ['Wallis and Futuna (Wallis-et-Futuna)', 'wf', '681'],
  ['Western Sahara (‫الصحراء الغربية‬‎)', 'eh', '212', 1],
  ['Yemen (‫اليمن‬‎)', 'ye', '967'],
  ['Zambia', 'zm', '260'],
  ['Zimbabwe', 'zw', '263'],
  ['Åland Islands', 'ax', '358', 1],
]

export const WHITE_LIST_MARKET_PRODUCTS_PARAMS = [
  'q[id_in]',
  'q[uuid_in]',
  'q[external_id_in]',
  'q[query_test_eq]',
  'q[full_text]',
  'q[express_operational_hours]',
  'q[category_id_in]',
  'q[variant_code_eq]',
  'q[search_variant_code_eq]',
  'q[main_category_id_eq]',
  'q[marketing_name_id_eq]',
  'q[seller_marketing_name_id_eq]',
  'q[seller_id_in]',
  'q[merchant_uuid_in]',
  'q[label_id_eq]',
  'q[promo_collection_code_eq]',
  'q[seo_tag_eq]',
  'q[search_tag_eq]',
  'q[origin_country_in]',
  'q[manufacturer_in]',
  'q[status_in]',
  'q[retail_price_lteq]',
  'q[retail_price_gteq]',
  'q[discounted_today_eq]',
  'q[has_discount]',
  'q[has_badge_hot]',
  'q[default_fake_discount_eq]',
  'q[has_installment]',
  'q[default_facets]',
  'q[facets]',
  'q[s]',
  'q[market_order]',
  'q[search_mode]',
  'q[response_mode]',
  'q[opaque_id]',
  'q[synonyms_active]',
  'q[only_variant_custom_fields]',
  'search_fields',
  'include_fields',
  'exclude_fields',
  'page',
  'per_page',
  'include_filters',
  'filters_page',
  'filters_per_page',
  'show_es_query',
  'current_operational_hour',
  'q[ret_price_min]',
  'q[ret_price_max]',
  'category_id',
  'q[status]',
  'category',
  'q[premium_partner]',
  // TODO не нужные параметры при отправки на бек возможные последствия отмены баги с ивентами (фиксить не возвратом из в )
  // 'from_search',
  // 'event',
  // 'query',
  // 'choice',
  // 'tips_type',
  // 'synonyms',
]

export const SEARCH_QUERIES = [
  'from_search',
  'event',
  'query',
  'choice',
  'tips_type',
  'search_type',
]

export const DEBUG_KEY = 'LSB1OAkcribJ'
