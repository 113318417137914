import { useCatalog } from '@/stores/catalog'

import { useCustomer } from '@/stores/customer'

export default function ({ $device, store }) {
  const customerStore = useCustomer()
  if (process.server) {
    customerStore.isBot = $device.isCrawler
  }
  // TODO: Reset action for all
}
