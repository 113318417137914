
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'
export default {
  name: 'Hamburger',
  setup() {
    const uiStore = useUiStore()
    const { isHamburgerOpen: isOpen } = storeToRefs(uiStore)
    return { uiStore, isOpen }
  },
  methods: {
    onClickButton() {
      const isMobile =
        this.resolution === 'sm' ||
        this.resolution === 'md' ||
        this.resolution === 'lg'
      if (!this.isOpen && !isMobile) {
        this.uiStore.clearFadeMain()
      }
      this.isOpen = !this.isOpen
      this.uiStore.isHeaderUserOpen = this.isOpen
    },
  },
}
