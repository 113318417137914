import { defineStore } from 'pinia'
import { generateItemListName } from '@/utilities'
import { useMarketStore } from '@/stores/market'

export const useDataLayerStore = defineStore('dataLayer', {
  state: () => ({
    optionsAddToCart: {},
    otherSourceDataToProduct: {
      item_list_name: null,
      item_list_index: null,
    },
    sourceNameToProduct: null,
    sourceIndexToProduct: null,
  }),
  actions: {
    clearOptionAddToCart() {
      this.optionsAddToCart = {}
    },
    pushOptionAddToCart(key, value) {
      this.optionsAddToCart[key] = value
    },
    clearOtherSourceDataToProduct() {
      Object.keys(this.otherSourceDataToProduct).forEach(key => {
        this.otherSourceDataToProduct[key] = null
      })
    },
    setSelectItem(product, options, route) {
      this.clearOtherSourceDataToProduct(options)
      const marketStore = useMarketStore()
      let source = 'direct'
      const location = name.split(`___${this.$nuxt.i18n.locale}`)?.[0] || ''
      if (marketStore.sourceSelectItem) {
        source = marketStore.sourceSelectItem
      }
      if (location.includes('cart')) {
        source = 'cart'
      }
      if (location.includes('index')) {
        source = 'main'
      }
      if (location.includes('categories')) {
        source = 'items_list'
      }
      if (location.includes('favorites')) {
        source = 'favorites'
      }
      if (location.includes('compare')) {
        source = 'comparison'
      }
      if (location.includes('product')) {
        source = 'item'
      }
      if (location.includes('account-orders')) {
        source = 'order_details'
      }
      if (location.includes('sale-day')) {
        source = 'flash_sale'
      }
      if (location.includes('tags-tag')) {
        source = 'tags'
      }
      if (route?.from_search) {
        source = 'search'
      }
      if (route?.query?.from_search) {
        source = 'search'
      }
      const searchData = this.getSearchData(route)
      const item_list_name = generateItemListName({
        route,
        list_name: options?.item_list_name,
        query: searchData?.query,
        choice: searchData?.choice,
      })
      this.sourceToProduct = item_list_name
      this.otherSourceDataToProduct.item_list_name = this.sourceToProduct
      this.otherSourceDataToProduct.item_list_index = options?.index
      const select_item = {
        event: 'select_item',
        source: route?.query?.from_search ? 'search' : source,
        availability:
          product?.status === 'out_of_stock' ? 'of_stock' : 'in_stock',
        ecommerce: {
          items: [
            {
              ...{
                item_name: product?.name || product?.product_name,
                item_list_name,
                item_list_id:
                  product?.category_id || product?.categories?.[0]?.id || '',
                item_category: `${product?.categories?.[0]?.name || ''}_${
                  product?.category_id
                }`,
                item_id: product.id || product?.product_id,
                price: product?.retail_price || product?.price,
                item_brand: product?.manufacturer || '',
                quantity: 1,
                index: options?.index + 1,
              },
            },
          ],
        },
      }
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push(function () {
          this.reset()
        })
        dataLayer.push(select_item)
        marketStore.sourceSelectItem = ''
      }
    },
    getSearchQuery(routeQuery) {
      return !routeQuery
        ? null
        : !routeQuery.query.from_search
        ? null
        : routeQuery.query.choice
        ? routeQuery.query.choice
        : routeQuery.query.query
    },
    getSearchData(route) {
      return {
        choice: route?.query?.choice,
        query: route?.query?.query,
      }
    },
    setFilterApplied({ filter, checkbox, route, selfCategory }) {
      if (typeof dataLayer !== 'undefined') {
        let screen_name = ''
        if (route?.query?.from_search) {
          screen_name = 'search'
        } else {
          if (route?.name?.includes('brand')) screen_name = 'brand_page'
          if (route?.name?.includes('merchant')) screen_name = 'merchant'
          if (route?.name?.includes('categories')) screen_name = 'category'
          if (route?.name?.includes('tags')) screen_name = 'tag_page'
        }
        const filterAppliedEventData = {
          event: 'filter_applied',
          filter_id: filter?.field_type === 'base' ? filter?.field : filter?.id,
          filter_name: filter?.title,
          filter_value: checkbox?.marketing_name || checkbox?.title,
          category_name: selfCategory?.data?.name,
          category_id: selfCategory?.data?.id,
          lang: this.$nuxt.i18n?.locale,
          screen_name,
        }
        dataLayer.push(filterAppliedEventData)
      }
    },
    setClick(route) {
      if (typeof dataLayer !== 'undefined') {
        let screen_name
        if (route?.query?.from_search) {
          screen_name = 'item_list_search'
        } else if (route?.name?.includes('new-products')) {
          screen_name = 'item_list_new_products'
        } else if (route?.name?.includes('sale-day')) {
          screen_name = 'item_list_flash_sale'
        } else if (route?.name?.includes('top-products')) {
          screen_name = 'item_list_top_products'
        } else if (route?.name?.includes('merchant')) {
          screen_name = 'item_list_seller'
        } else if (route?.name?.includes('tags')) {
          screen_name = 'item_list_tags'
        } else if (route?.name?.includes('brand')) {
          screen_name = 'item_list_brand'
        } else if (route?.name?.includes('mega-discounts')) {
          screen_name = 'item_list_max_discount'
        } else {
          screen_name = 'item_list_catalog'
        }
        dataLayer.push({
          event: 'click',
          space: 'market_search',
          subject: 'user',
          screen_type: 'item_list',
          screen_name,
          object: 'button',
          block_name: 'sorting_dropdown',
          button_name: 'open_sorting_options',
        })
      }
    },
  },
})
