
import { useUiStore } from '@/stores/ui'
export default {
  name: 'FooterMenu',
  props: {
    withDot: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Boolean,
      default: true,
    },
    isFooter: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  data: () => ({
    news: {
      az: 'https://umico.az/live/',
      en: 'https://umico.az/live/en/',
      ru: 'https://umico.az/live/ru/',
    },
  }),
  computed: {
    advocateForm() {
      const locales = { az: 'az', ru: 'ru', en: 'en-GB' }
      return `https://forms.office.com/pages/responsepage.aspx?id=aFCpysF8QUySX4dcIqXEybzogOad69pAsFU7zQaWYkNUMlpLWkg4MTdHTTJSODFWU09DNVE4WUZUQi4u&lang=${
        locales[this.$i18n.locale]
      }`
    },
    newsUrl() {
      return this.news[this.$i18n.locale]
    },
    OrderComplaintLink() {
      const locales = { az: 'az', ru: 'ru', en: 'en' }
      return `https://forms.office.com/Pages/ResponsePage.aspx?id=aFCpysF8QUySX4dcIqXEyeqyPHSk5jJHt4BwrQX2kXVUN0s1QVBTNkg4SllEUjRaMzBORUNaSzNaSiQlQCN0PWcu&lang=${
        locales[this.$i18n.locale]
      }`
    },
    isQuestionAnswersPage() {
      return this.$route.path.includes('/questions-answers')
    },
    startSellingOnUmicoUrl() {
      return `https://business.umico.az${
        this.$i18n.locale === 'az' ? '' : '/ru'
      }`
    },
  },
  methods: {
    sendDataLayer(event) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event,
        })
      }
    },
    showPublicOffer() {
      setTimeout(() => (this.uiStore.publicOfferVisibility = true), 30)
    },
    showUserAgreement() {
      setTimeout(() => (this.uiStore.userAgreementVisibility = true), 30)
    },
  },
}
