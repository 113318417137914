
import { useUiStore } from '@/stores/ui'
export default {
  provide() {
    return {
      isErrorLayout: true,
    }
  },
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  computed: {
    isSearchVisible() {
      return this.uiStore.isSearchVisible || !this.isSearchButtonVisible
    },
  },
}
