import { mapGetters } from 'vuex'
import { scrollTo } from '@/utilities'
import seoDataLayer from '@/mixins/setDataLayer'
import { useCheckoutStore } from '@/stores/checkout'
import { useCheckoutStoreV3 } from '@/stores/checkout-v3'
import { useUiStore } from '@/stores/ui'

export default {
  data() {
    return {
      isOpenAvailablePopup: false,
    }
  },
  mixins: [seoDataLayer],
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    listCartProductsLineItems() {
      return (
        this.marketStore?.cart?.sellers?.flatMap(seller => seller.line_items) ||
        []
      )
    },

    leastOneCheckNeedProgress() {
      let check_need = false
      this.listCartProductsLineItems.forEach(item => {
        if (
          item.availability_check_needed &&
          item.availability_check_state === 'IN_PROGRESS'
        ) {
          check_need = true
        }
      })
      return check_need
    },
    leastOneMoreAvailable() {
      let moreAvailable = false
      this.marketStore.cart.sellers?.forEach(partner => {
        partner.line_items.forEach(item => {
          if (
            item.allowed_quantity !== 0 &&
            item.quantity > item.allowed_quantity &&
            item.min_quantity < item.allowed_quantity
          ) {
            moreAvailable = true
          }
        })
      })
      return moreAvailable
    },
    leastOneAvailable() {
      let available = false
      this.listCartProductsLineItems.forEach(item => {
        if (
          item.availability_check_state === 'AVAILABLE' &&
          item.state === 'active'
        ) {
          available = true
        }
      })
      return available
    },
    everethingNotAvailable() {
      return this.marketStore.cart?.sellers.every(partner =>
        partner.line_items.every(product => product.state !== 'active'),
      )
    },
    checkHasGrosseryProducts() {
      const partners_grossery = this.marketStore.cart?.sellers
        .find(
          partner => partner?.id == this.grosseryStore.grossery_merchant_uid,
        )
        ?.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
      const ather_partners = this.marketStore.cart?.sellers.filter(
        partner => partner?.id !== this.grosseryStore.grossery_merchant_uid,
      )
      const ather_partners_products = []
      ather_partners.forEach(partner => {
        const is_product = partner.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
        if (is_product?.length) {
          ather_partners_products.push(...is_product)
        }
      })
      return (
        !['GROCERY', 'NOT_GROCERY'].includes(
          this.grosseryStore.typeGrosseryCartMode,
        ) &&
        Boolean(partners_grossery?.length && ather_partners_products?.length)
      )
    },
    setGrosseryOrNotGrossery() {
      const partners_grossery = this.marketStore.cart?.sellers
        .find(
          partner => partner?.id == this.grosseryStore.grossery_merchant_uid,
        )
        ?.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
      const ather_partners = this.marketStore.cart?.sellers.filter(
        partner => partner?.id !== this.grosseryStore.grossery_merchant_uid,
      )
      const ather_partners_products = []
      ather_partners.forEach(partner => {
        const is_product = partner.line_items.filter(product => {
          return product.is_selected_for_checkout && product.state === 'active'
        })
        if (is_product?.length) {
          ather_partners_products.push(...is_product)
        }
      })
      return ['GROCERY', 'NOT_GROCERY'].includes(
        this.grosseryStore.typeGrosseryCartMode,
      )
        ? this.grosseryStore.typeGrosseryCartMode
        : partners_grossery?.length
        ? 'GROCERY'
        : ather_partners_products?.length
        ? 'NOT_GROCERY'
        : 'ALL_PRODUCTS'
    },
    calculateCartAmount() {
      if (this.grosseryStore.typeGrosseryCartMode === 'ALL_PRODUCTS') {
        return this.marketStore.cart?.total_amount
      }
      if (this.grosseryStore.typeGrosseryCartMode === 'NOT_GROCERY') {
        let summ = 0
        this.marketStore.cart?.sellers?.map(seller => {
          if (seller.id !== this.grosseryStore.grossery_merchant_uid)
            summ += seller.amount
        })
        return summ
      }
      if (this.grosseryStore.typeGrosseryCartMode === 'GROCERY') {
        let summ = 0
        this.marketStore.cart?.sellers?.map(seller => {
          if (seller.id === this.grosseryStore.grossery_merchant_uid)
            summ += seller.amount
        })
        return summ
      }
    },
  },
  watch: {
    continueCheckoutWithoutGrossery(val) {
      if (val) {
        this.goToCheckout()
        this.continueCheckoutWithoutGrossery = false
      }
    },
  },

  methods: {
    async goToCheckout() {
      // const line_item_ids = []
      // this.marketStore.cart?.sellers.forEach(seller => {
      //   seller.line_items.forEach(product => {
      //     if (
      //       product.hasOwnProperty('delivery_possible') &&
      //       product?.delivery_possible === true &&
      //       product.is_selected_for_checkout
      //     ) {
      //       line_item_ids.push(product.id)
      //     } else {
      //       if (product.is_selected_for_checkout) {
      //         line_item_ids.push(product.id)
      //       }
      //     }
      //   })
      // })
      // await this.marketStore.setSelectForCheckout({ line_item_ids })

      const uiStore = useUiStore()
      uiStore.isPopupCartMinAmount = false

      if (this.calculateCartAmount < this.marketStore.minOrderAmount) {
        // Минимальная сумма заказа 2 ₼
        uiStore.isPopupCartVisible = false
        uiStore.isPopupCartMinAmount = true
        this.grosseryStore.typeGrosseryCartMode = 'ALL_PRODUCTS'
        return
      }

      if (!this.isAuthenticated) {
        if (
          this.$route.name?.includes('cart___') ||
          this.$route.name?.includes('index___')
        ) {
          this.$auth.$storage.setUniversal(
            'nextPage',
            `cart___${this.$i18n.locale}`,
          )
        }
        this.$router.push({
          path: this.localePath('auth-authentication'),
        })
        return
      }
      if (this.checkHasGrosseryProducts) {
        // Выберите какие товары вы хотите заказать сейчас:
        // У вас в корзине товары одновременно с обычной доставкой и возможностью экспресс доставки из Dükan
        this.grosseryStore.showPopupChooseGrosseryCheckout = true
        return
      } else {
        this.grosseryStore.updateTypeGrosseryCartMode(
          this.setGrosseryOrNotGrossery,
        )
      }
      if (!this.leastOneAvailable && !this.everethingNotAvailable) {
        // Вы сможете оформить заказ после того, как наличие товаров будет подтверждено.
        // Мы оповестим вас в приложении и отправим SMS на телефон
        uiStore.availabilityGoodsSpecified = true
        return
      }

      if (this.marketStore.customerForOrder) {
        this.marketStore.customerForOrder = null
      }
      if (this.leastOneMoreAvailable) {
        // Ограниченное предложение
        // Ранее вы добавили товары в корзину, которые сейчас доступны по акционной цене,
        // но в ограниченном количестве.
        // Пожалуйста, уменьшите количество товаров с ограниченным предложением.
        this.isOpenAvailablePopup = true
      } else if (this.leastOneCheckNeedProgress) {
        uiStore.isCheckoutWarningPopup = {
          visible: true,
          buttonFunc: this.goToCheckoutFunc,
        }
      } else if (this.everethingNotAvailable) {
        uiStore.isBasketNotAvailablePopup = true
      } else {
        this.goToCheckoutFunc()
      }
      const checkoutStore = useCheckoutStore()
      checkoutStore.warning_merge_anonymous_cart = false
    },
    goToCheckoutFunc() {
      this.continueCheckoutWithoutGrossery = false
      // // TODO remove before RELEASE from
      // const type_checkout = localStorage.getItem('type_checkout')
      // if (type_checkout === '3') {
      //   // TODO remove before RELEASE to
      //   const checkoutStoreV3 = useCheckoutStoreV3()
      //   // checkoutStoreV3.postOrdersPackages(
      //   //   this.grosseryStore.typeGrosseryCartMode,
      //   // )
      // }
      const query = {}
      if (this.grosseryStore.isTypeGrossery) {
        query.grocery = 'grocery'
      }
      try {
        this.$router.push(
          this.localePath({
            name: 'checkout',
            query,
          }),
        )
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
    goToHasError() {
      this.isOpenAvailablePopup = false
      const firstErrorQty = document.querySelector('.ProductQuantity.has-error')
      if (firstErrorQty)
        scrollTo(
          document.querySelector('.Layout'),
          firstErrorQty.offsetTop + 150,
          250,
        )
    },
  },
}
