import { defineStore } from 'pinia'
import { usePromoCodeStore } from '@/stores/promocode'

export const useDcmStore = defineStore('dcm', {
  state: () => ({
    birkart_faq: null,
    content_category: null,
    fitting_allowed_content: null,
    content_cart: null,
    content_checkout: null,
    order_package_free_amount: null,
    banner_content_product: null,
    free_delivered: null,
    smart_category: [],
    birkart_umico_info: null,
    promotions_content: null,
    init_header_slider: false,
    promo_banners: null,
    header_market_slider: null,
    popular_brands: { items: [] },
    popular_sellers: { items: [] },
    free_delivered_main: null,
    bir_bank_installment: null,
    mega_discounts_banner: null,
    cart_dukan_delivery: null,
    prom_prod_title: null,
    mainPageLandingLinkBadges: null,
    headerB2bForm: null,
    premiumSubscriptionContent: null,
    premiumSubscriptionPotentialBenefits: null,
  }),
  actions: {
    async getCartDukanDelivery() {
      try {
        if (process.server) {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.NODE_DCM_BASE_URL ||
            this.$nuxt.$config.DCM_BASE_URL
        } else {
          this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
        }
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          `api/v1/content?location_code%5B%5D=cart_dukan_delivery`,
        )
        const cart_dukan_delivery = data?.find(
          item => item?.location_code === 'cart_dukan_delivery',
        )
        this.cart_dukan_delivery = cart_dukan_delivery?.text
      } catch (e) {
        console.log('Error getCartDukanDelivery', e)
      }
    },
    async getBirkartFaq() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=birkart_faq',
        )
        const items = data.find(item => item.location_code === 'birkart_faq')
        this.birkart_faq = items
      } catch (e) {
        console.log('Error: getBirkartFaq', e)
      }
    },
    async getContentCategory() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=advertising_category_web',
        )
        this.content_category = data
      } catch (e) {
        console.log('Error: getContentCategory', e)
      }
    },
    async getContentFittingAllowed() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=fitting_allowed_product_card',
        )
        this.fitting_allowed_content = data
      } catch (e) {
        console.log('Error: getContentFittingAllowed', e)
      }
    },
    async getContentCart() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=advertising_cart_web',
        )
        this.content_cart = data
      } catch (e) {
        console.log('Error: getContentCart', e)
      }
    },
    async getContentCheckout() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=advertising_checkout_web',
        )
        this.content_checkout = data
      } catch (e) {
        console.log('Error: getContentCheckout', e)
      }
    },
    async getOrderPackageFreeAmount() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=order_package_free_amount',
        )
        this.order_package_free_amount = +data[0]?.text
      } catch (e) {
        console.log('Error: getOrderPackageFreeAmount', e)
      }
    },
    async getBannerContentProduct() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=advertising_product_web',
        )
        const items = data.find(
          item => item.location_code === 'advertising_product_web',
        )
        this.banner_content_product = items
      } catch (e) {
        console.log('Error: getBannerContentProduct', e)
      }
    },
    async getContentFreeDelivered() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=free_delivered',
        )
        const items = data.find(item => item.location_code === 'free_delivered')
        this.free_delivered = items
      } catch (e) {
        console.log('Error: getContentFreeDelivered', e)
      }
    },
    async getSmartCategory() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const params = ['smart_category_body', 'prom_prod_title']

        const queries = new URLSearchParams('')
        params.forEach(param => queries.append('location_code[]', param))
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(`api/v1/content?${queries.toString()}`)

        this.smart_category = data

        this.prom_prod_title = data?.find(
          item => item.location_code === 'prom_prod_title',
        ) || { items: [] }
      } catch (e) {
        console.log('Error: getSmartCategory', e)
      }
    },
    async getBirkartUmicoInfo() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=birkart_umico',
        )
        const birkart_umico = data.find(
          item => item.location_code === 'birkart_umico',
        )
        this.birkart_umico_info = birkart_umico
      } catch (e) {
        console.log('Error: getBirkartUmicoInfo', e)
      }
    },
    async getPromotionsContent(id) {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(`api/v1/promotion/${id}`)
        this.promotions_content = data
        return data
      } catch (e) {
        console.log('Error: getPromotionsContent', e)
      }
    },
    async getPopularBrands() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const query = new URLSearchParams('')
        query.append('location_code[]', 'web_brandlist_market_01')
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(`api/v1/content?${query.toString()}`)

        this.popular_brands = data.find(
          item => item.location_code === 'web_brandlist_market_01',
        ) || { items: [] }
      } catch (error) {
        console.error('Error fetching web_brandlist_market_01 content:', error)
      }
    },
    async getPopularSellers() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const query = new URLSearchParams('')
        query.append('location_code[]', 'merchant_list_market_main_web')
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(`api/v1/content?${query.toString()}`)

        this.popular_sellers = data.find(
          item => item.location_code === 'merchant_list_market_main_web',
        ) || { items: [] }
      } catch (error) {
        console.error(
          'Error fetching merchant_list_market_main_web content:',
          error,
        )
      }
    },
    async getMainPromoBanners() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const query = new URLSearchParams('')
        query.append('location_code[]', 'promo_banner_main_web')
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(`api/v1/content?${query.toString()}`)

        this.promo_banners = data.find(
          item => item.location_code === 'promo_banner_main_web',
        ) || { items: [] }
      } catch (error) {
        console.error('Error fetching promo_banner_main_web content:', error)
      }
    },
    async getHeaderMarketInfo() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const params = [
          'header_slider_market_web',
          'smart_category_body',
          'free_delivered_main',
          'bir_bank_installment',
          'banners_3_main_web',
        ]

        const queries = new URLSearchParams('')
        params.forEach(param => queries.append('location_code[]', param))
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(`api/v1/content?${queries.toString()}`)

        this.prom_prod_title = data?.find(
          item => item.location_code === 'prom_prod_title',
        ) || { items: [] }

        this.init_header_slider = true
        const items = data.find(
          item => item.location_code === 'header_slider_market_web',
        )
        this.header_market_slider = items?.items || []

        this.promo_banners =
          data.find(item => item.location_code === 'promo_banner_main_web') ||
          []
        this.bir_bank_installment =
          data.find(item => item.location_code === 'bir_bank_installment') || []
        this.free_delivered_main = data.find(
          item => item.location_code === 'free_delivered_main',
        )
        this.mainPageLandingLinkBadges =
          data.find(item => item.location_code === 'banners_3_main_web') || {}
      } catch (e) {
        console.log('Error: getHeaderMarketInfo', e)
      }
    },
    async getMegaDiscountBanner() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=mega_discounts_banner_web',
        )
        this.mega_discounts_banner = data.find(
          item => item.location_code === 'mega_discounts_banner_web',
        )
      } catch (e) {
        console.log('Error: getMegaDiscountBanner', e)
      }
    },
    async getHeaderB2bForm() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=header_b2b_form',
        )
        this.headerB2bForm =
          data.find(item => item.location_code === 'header_b2b_form') || null
      } catch (e) {
        console.log('Error: getHeaderB2bForm', e)
      }
    },
    async getPremiumSubscriptionContent() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const promocodeStore = usePromoCodeStore()
        const params = [
          'web_profile_premium_benefits_market',
          'web_profile_premium_benefits_bonus',
        ]
        if (promocodeStore.premiumSubscription.active) {
          params.push(
            'web_profile_premium_main_active',
            'web_profile_premium_cost',
          )
        } else {
          params.push('web_profile_premium_main_inactive')
        }
        const queries = new URLSearchParams()
        params.forEach(param => queries.append('location_code[]', param))
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(`api/v1/content?${queries.toString()}`)
        this.premiumSubscriptionContent = data
      } catch (e) {
        console.log('Error: getPremiumSubscriptionContent', e)
      }
    },
    async getPremiumSubscriptionPotentialBenefits() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=web_profile_premium_potential_benefits',
        )
        this.premiumSubscriptionPotentialBenefits =
          data.find(
            item =>
              item.location_code === 'web_profile_premium_potential_benefits',
          ) || null
      } catch (e) {
        console.log('Error: getSubscriptionPotentialBenefits', e)
      }
    },
    async getCreditLineBannerImage() {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_DCM_BASE_URL ||
          this.$nuxt.$config.DCM_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.DCM_BASE_URL
      }
      try {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(
          'api/v1/content?location_code%5B%5D=credit_line_preapproved_web',
        )
        this.premiumSubscriptionPotentialBenefits =
          data.find(
            item => item.location_code === 'credit_line_preapproved_web',
          ) || null
      } catch (e) {
        console.log('Error: getCreditLineBannerImage', e)
      }
    },
  },
})
