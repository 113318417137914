export default () => ({
  personal_promo_code_compliment: {},
  personal_promo_code_subscription: {},
  subscribe_premium_promo_cod: {},
  personal_promo_codes: [],

  has_subscription_promo_code: false,
  has_subscription_promo_code_data_layer: false,
  personal_promo_code_subscription_data_layer: {},
  personal_promo_code_data_layer: false,
  has_compliment_promo_cod: false,
  has_personal_promo_code: false,

  is_first_purchase_promo_code: false,
  first_purchase_promo_code: {},
  cashback_promo_code_amount10: 0,

  premiumPromoCodeCashbackAmount: 0,
  premiumSubscription: {
    active: false,
    finished_at: null,
  },
  premiumSubscriptionBenefits: null,
})
